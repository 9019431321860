/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';

interface FormValues {
  cancelType?: string;
  cancelReason?: string;
  cancelPeriod?: string;
  subCancelReason?: string;
  financingRejectReason?: string;
}

export function useResetFieldsRequestCancellationTask(
  { cancelType, cancelReason, cancelPeriod, subCancelReason }: FormValues,
  setValue: UseFormSetValue<FormValues>,
  isDirty: boolean
) {
  useEffect(() => {
    if (isDirty) {
      setValue('cancelReason', null);
      setValue('subCancelReason', null);
      setValue('financingRejectReason', null);
    }
  }, [cancelType, cancelPeriod]);

  useEffect(() => {
    if (isDirty) {
      setValue('subCancelReason', null);
      setValue('financingRejectReason', null);
    }
  }, [cancelReason]);

  useEffect(() => {
    if (isDirty) {
      setValue('financingRejectReason', null);
    }
  }, [subCancelReason]);
}
