import { useApolloClient } from '@apollo/client';
import { createFilterPayload, Filter, Filters, Sorts } from '@retail/gql-utils';
import { useCheckPermissions } from '@retail/backoffice-ui/src/Permission';
import { COUNTRIES } from '@retail/i18n/constants';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ACCOUNT_DETAILS_UPLOAD_SOURCE,
  CREATE_NEW_ACCOUNT_DETAILS
} from './contants';

import { useQueryBaseOptions } from '~hooks/useQueryBaseOptions';
import {
  LoadOrderPaymentsQuery,
  LoadOrderPaymentsQueryVariables,
  useLoadOrderPaymentsQuery,
  LoadOrderPaymentsDocument,
  AccountDetailsForPaymentQuery,
  AccountDetailsForPaymentQueryVariables,
  useAccountDetailsForPaymentQuery,
  AccountDetailsForPaymentDocument,
  TypedAccountDetailsForPaymentQuery,
  TypedAccountDetailsForPaymentQueryVariables,
  useTypedAccountDetailsForPaymentQuery,
  TypedAccountDetailsForPaymentDocument
} from '~apollo/gql-types';
import { DOMAINS, PERMISSIONS } from '~constants/permissions';

const PAYMENT_PURPOSE_OVERPAYMENT = 'OVERPAYMENT';

const PAYMENT_TYPE_BANK_FINANCE = 'WIRE_TRANSFER_BANK_FINANCE';

type RegularDetails =
  AccountDetailsForPaymentQuery['details']['accounts'][number];

type RegularAccount = RegularDetails['retailAccount'];

type TypedDetails =
  TypedAccountDetailsForPaymentQuery['details']['accounts'][number];

type TypedAccount = TypedDetails['retailAccount'];

const formatAccountDetails = (acc: RegularAccount | TypedAccount) => {
  switch (acc.__typename) {
    case 'BankTransferBankAccountDetailsProjection':
      return `${acc.holder}, ${acc.bankName}, ${acc.clearingNumber}, ${acc.accountNumber}`;
    case 'SEPlusgiroBankAccountDetailsProjection':
      return `${acc.holder}, ${acc.number}`;
    default:
      return `${acc.holder}, ${acc.iban}, ${acc.bic}`;
  }
};

const prepareAccountsData = (
  queryData: AccountDetailsForPaymentQuery | TypedAccountDetailsForPaymentQuery
) => {
  const accountDetails: (RegularDetails | TypedDetails)[] =
    queryData.details?.accounts ?? [];

  const dataIds = new Set<string>();

  return accountDetails
    .filter(
      (details) =>
        details.uploadSource === ACCOUNT_DETAILS_UPLOAD_SOURCE.BO_TASK_MGMT ||
        details.payments?.some(
          (it) => it.paymentType === PAYMENT_TYPE_BANK_FINANCE
        )
    )
    .filter((it) => {
      const isDuplicate = dataIds.has(it.retailAccount?.accountDataId);
      dataIds.add(it.retailAccount?.accountDataId);
      return !isDuplicate;
    });
};

const createPaymentsSearch = (orderId: string) =>
  createFilterPayload({
    filter: Filters.and(
      Filters.equal('orderId', orderId),
      Filters.equal('type', PAYMENT_TYPE_BANK_FINANCE)
    ) as Filter,
    sorts: [Sorts.desk('createdOn')]
  });

const context = { headers: { 'access-domain': DOMAINS.ORDER_MANAGEMENT } };

export const useGetAccountDetails = (
  args: {
    orderId: string;
    country: string;
  } | null
) => {
  const baseQueryOptions = useQueryBaseOptions();
  const apolloClient = useApolloClient();

  const loadAccountDetails = useCallback(
    async (orderId: string, country: string) => {
      const { data: paymentsData } = await apolloClient.query<
        LoadOrderPaymentsQuery,
        LoadOrderPaymentsQueryVariables
      >({
        ...baseQueryOptions,
        query: LoadOrderPaymentsDocument,
        variables: {
          search: createPaymentsSearch(orderId)
        },
        context,
        fetchPolicy: 'cache-first'
      });

      const queryOptions = {
        ...baseQueryOptions,
        variables: {
          country,
          orderId,
          paymentType: PAYMENT_PURPOSE_OVERPAYMENT,
          paymentId: paymentsData.payments.entities[0]?.id
        },
        context,
        fetchPolicy: 'network-only'
      } as const;

      if (country === COUNTRIES.SE) {
        const { data } = await apolloClient.query<
          TypedAccountDetailsForPaymentQuery,
          TypedAccountDetailsForPaymentQueryVariables
        >({
          query: TypedAccountDetailsForPaymentDocument,
          ...queryOptions
        });

        return prepareAccountsData(data);
      }

      const { data } = await apolloClient.query<
        AccountDetailsForPaymentQuery,
        AccountDetailsForPaymentQueryVariables
      >({
        query: AccountDetailsForPaymentDocument,
        ...queryOptions
      });

      return prepareAccountsData(data);
    },
    [apolloClient, baseQueryOptions]
  );

  const paymentsQuery = useLoadOrderPaymentsQuery({
    ...baseQueryOptions,
    skip: !args,
    variables: {
      search: args ? createPaymentsSearch(args.orderId) : null
    },
    context,
    fetchPolicy: 'cache-first'
  });

  const variables =
    args && paymentsQuery?.data
      ? {
          country: args.country,
          orderId: args.orderId,
          paymentType: PAYMENT_PURPOSE_OVERPAYMENT,
          paymentId: paymentsQuery.data.payments.entities[0]?.id
        }
      : undefined;

  const queryOptions = {
    ...baseQueryOptions,
    variables,
    context,
    fetchPolicy: 'cache-first'
  } as const;

  const shouldUseTypedQuery = args?.country === COUNTRIES.SE;

  const regularDetailsQuery = useAccountDetailsForPaymentQuery({
    ...queryOptions,
    skip: !variables || shouldUseTypedQuery
  });

  const typedDetailsQuery = useTypedAccountDetailsForPaymentQuery({
    ...queryOptions,
    skip: !variables || !shouldUseTypedQuery
  });

  const accountDetails = useMemo(() => {
    const data = shouldUseTypedQuery
      ? typedDetailsQuery.data
      : regularDetailsQuery.data;
    return data ? prepareAccountsData(data) : [];
  }, [shouldUseTypedQuery, typedDetailsQuery.data, regularDetailsQuery.data]);

  const accountDetailsLoading = shouldUseTypedQuery
    ? typedDetailsQuery.loading
    : regularDetailsQuery.loading;

  return { accountDetails, accountDetailsLoading, loadAccountDetails };
};

export const useGetAccountDetailsOptions = (
  orderId: string,
  country: string
) => {
  const { t } = useTranslation();
  const userCanRewriteAccountDetails = useCheckPermissions({
    allow: PERMISSIONS.MUTATION_CREATE_REFUND_OVERWRITE_DETAILS,
    domain: DOMAINS.ORDER_MANAGEMENT
  });

  const { accountDetails, accountDetailsLoading } = useGetAccountDetails({
    orderId,
    country
  });

  const options = useMemo(() => {
    const options = accountDetails.map((it) => ({
      label: formatAccountDetails(it.retailAccount),
      value: it.retailAccount.accountDataId
    }));

    if (userCanRewriteAccountDetails.isAllowed) {
      options.unshift({
        value: CREATE_NEW_ACCOUNT_DETAILS,
        label: t('bo.taskManagement.paymentTypes.CREATE_NEW_DETAILS')
      });
    }

    return options;
  }, [t, accountDetails, userCanRewriteAccountDetails.isAllowed]);

  return [
    options,
    accountDetailsLoading || userCanRewriteAccountDetails.isLoading
  ] as const;
};
