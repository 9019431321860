import { Table, TableColumnsType, Typography } from 'antd';
import { isEmpty, pick } from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import cn from '../styles.less';

import { ClaimCostWrapperProjection } from '~apollo/gql-types';
import { formatDateTime } from '~helpers/date';
import { i18nDetectLng } from '~i18n/utils';
import * as ROUTES from '~routes';
import { useFormatCurrency } from '~utils/i18n';
import { getFullName } from '~utils/user';
import { TaskEntityOfType } from '~pages/TaskManagement/types';

const { Link } = Typography;

interface Entity extends TaskEntityOfType<'BankDetailsPendingTaskProjection'> {
  claimCostNumber: string;
  claimCostAmount: string;
  ahApproval: Pick<
    ClaimCostWrapperProjection,
    'approvalLevel' | 'approvalLevelSetBy' | 'approvalLevelSetOn'
  >;
  customerDecision: Pick<
    ClaimCostWrapperProjection,
    'customerDecision' | 'customerDecisionSetBy' | 'customerDecisionSetOn'
  >;
}

interface Props {
  data: TaskEntityOfType<'BankDetailsPendingTaskProjection'>;
}

export function BankDetailsDefaultSubRow({ data }: Props) {
  const { t } = useTranslation();
  const { cost } = data;

  const dataSource = [
    {
      ...data,
      claimCostNumber: cost?.number,
      claimCostAmount: useFormatCurrency({
        amountMinorUnits: cost?.costMinorUnits,
        currency: cost?.costCurrencyCode
      }),
      ahApproval: pick(
        ['approvalLevel', 'approvalLevelSetBy', 'approvalLevelSetOn'],
        cost
      ),
      customerDecision: pick(
        ['customerDecision', 'customerDecisionSetBy', 'customerDecisionSetOn'],
        cost
      )
    }
  ];

  const columns: TableColumnsType<Entity> = [
    {
      title: t('bo.taskManagement.bankDetailsPendingTask.reason'),
      dataIndex: 'reason',
      key: 'reason',
      render: (value) => <span data-qa-selector="reason">{value || '-'}</span>
    },
    {
      title: t('bo.taskManagement.bankDetailsPendingTask.claimNumber'),
      dataIndex: 'claim',
      key: 'claimNumber',
      render: (claim, { order }) => (
        <Link
          href={ROUTES.ORDER_CLAIMS_DETAILS.LINK({
            language: i18nDetectLng(),
            orderId: order?.id,
            claimId: claim?.id
          })}
        >
          {claim?.number || '-'}
        </Link>
      )
    },
    {
      title: t('bo.taskManagement.bankDetailsPendingTask.claimCostNumber'),
      dataIndex: 'claimCostNumber',
      key: 'claimCostNumber',
      render: (value) => (
        <span data-qa-selector="claimCostNumber">{value || '-'}</span>
      )
    },
    {
      title: t('bo.taskManagement.bankDetailsPendingTask.claimCostAmount'),
      dataIndex: 'claimCostAmount',
      key: 'claimCostAmount',
      render: (value) => (
        <span data-qa-selector="claimCostAmount">{value || '-'}</span>
      )
    },
    {
      title: t('bo.taskManagement.bankDetailsPendingTask.ahApproval'),
      dataIndex: 'ahApproval',
      key: 'ahApproval',
      render: (value) => (
        <span data-qa-selector="ahApproval">
          {isEmpty(value) ? (
            '-'
          ) : (
            <>
              <div>
                {t(
                  `bo.orderClaims.processing.approvalStatus.${value?.approvalLevel?.toLowerCase()}`
                )}
              </div>
              <div>{getFullName(value?.approvalLevelSetBy)}</div>
              {formatDateTime(value?.approvalLevelSetOn)}
            </>
          )}
        </span>
      )
    },
    {
      title: t(
        'bo.taskManagement.bankDetailsPendingTask.customerDesicionApprovalDate'
      ),
      dataIndex: 'customerDecision',
      key: 'customerDecision',
      render: (value) => (
        <span data-qa-selector="customerDecision">
          {isEmpty(value) ? (
            '-'
          ) : (
            <>
              <div>
                {t(
                  `bo.taskManagement.damageCompensationTable.customerDecision.${value?.customerDecision}`
                )}
              </div>
              <div>{getFullName(value?.customerDecisionSetBy)}</div>
              {formatDateTime(value?.customerDecisionSetOn)}
            </>
          )}
        </span>
      )
    }
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      pagination={false}
      dataSource={dataSource}
      bordered
      className={cn.table}
      style={{ width: '100%', border: '1px solid #616161' }}
    />
  );
}
