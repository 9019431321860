import { Table, TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';

import cn from './styles.less';

import { formatDateTime } from '~helpers/date';
import { getIsV3 } from '~helpers/order/isV3';
import { TaskEntityOfType } from '~pages/TaskManagement/types';

const FALLBACK = '-';

interface Props {
  data: TaskEntityOfType<'FinancingInternalApprovalTaskProjection'>;
}

export function FinancingInternalApprovalSubRow({ data }: Props) {
  const { t } = useTranslation();
  const dataSource = [data];
  const isV3 = getIsV3(data.order);

  const columns: TableColumnsType<
    TaskEntityOfType<'FinancingInternalApprovalTaskProjection'>
  > = [
    isV3
      ? {
          title: t(
            'bo.taskManagement.orderReadyToVerificationTable.pendingVerificationOn'
          ),
          dataIndex: 'pendingVerificationOn',
          key: 'pendingVerificationOn',
          render: (value) => (
            <span data-qa-selector="pendingVerificationOn">
              {value ? formatDateTime(value) : FALLBACK}
            </span>
          )
        }
      : {
          title: t('bo.taskManagement.happinessTable.orderContractSignedOn'),
          dataIndex: 'orderContractSignedOn',
          key: 'orderContractSignedOn',
          render: (value) => (
            <span data-qa-selector="orderContractSignedOn">
              {value ? formatDateTime(value) : FALLBACK}
            </span>
          )
        },
    {
      title: t('bo.taskManagement.emailReplyTask.paymentType'),
      dataIndex: 'paymentType',
      key: 'customerEmail',
      render: (value) => (
        <span data-qa-selector="email">{value || FALLBACK}</span>
      )
    },
    {
      title: t('bo.taskManagement.financingInternalApproval.financingStatus'),
      dataIndex: 'financingStatus',
      key: 'financingStatus',
      render: (value) => (
        <span data-qa-selector="financingStatus">{value || FALLBACK}</span>
      )
    },
    {
      title: t('bo.taskManagement.emailReplyTask.paymentStatus'),
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      render: (value) => (
        <span data-qa-selector="paymentStatus">{value || FALLBACK}</span>
      )
    }
  ];

  return (
    <Table
      columns={columns}
      pagination={false}
      rowKey="id"
      dataSource={dataSource}
      bordered
      className={cn.table}
      style={{ width: '100%', border: '1px solid #616161' }}
    />
  );
}
