import { useCallback } from 'react';

import { useAssignTaskManagerTaskMutation } from '~apollo/gql-types';
import { useQueryBaseOptions } from '~hooks/useQueryBaseOptions';

interface Props {
  taskId: string;
}

export function useAssign({ taskId }: Props) {
  const queryBaseOptions = useQueryBaseOptions();

  const [assign, { loading }] = useAssignTaskManagerTaskMutation({
    ...queryBaseOptions,
    refetchQueries: ['SearchTaskManagerTasks']
  });
  return [
    useCallback(
      async (userId) => {
        if (userId) {
          await assign({
            variables: {
              taskId,
              userId
            }
          });
        }
      },
      [assign, taskId]
    ),
    loading
  ] as const;
}
