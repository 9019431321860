import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PaymentTypeOption } from './contants';

export const usePaymentTypeOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        label: t('bo.taskManagement.paymentTypes.BANKGIRO_PLUS_GIRO'),
        value: PaymentTypeOption.SePlusgiro
      },
      {
        label: t('bo.taskManagement.paymentTypes.BANK_TRANSFER'),
        value: PaymentTypeOption.SeBankTransfer
      },
      {
        label: t('bo.taskManagement.paymentTypes.INTERNATIONAL_TRANSFER'),
        value: PaymentTypeOption.Iban
      }
    ],
    [t]
  );
};
