import { TableCell } from '@retail/backoffice-ui';
import { TableColumnsType } from 'antd';
import { Dispatch, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TaskType } from '../Table/TaskType';
import { TASK_NAME } from '../constants';
import { AssignedTo } from '../Table/AssignedTo';
import { DaysInStatus } from '../Table/DaysInStatus';
import { OrderNumber } from '../Table/OrderNumber';
import { TaskActions } from '../Table/TaskActions';
import { TaskEntity } from '../types';

import { useColorCodingIconCallback } from './useColorCodingIconCallback';
import useColorCodingIconCallbackCn from './useColorCodingIconCallback/styles.less';

import { UserProjection } from '~apollo/gql-types';
import { formatDateTime } from '~helpers/date';
import { Assignee } from '~types/Assignee';
import { AvailableActionsModel } from '~types/AvailableActions';

export interface UseTableColumnsProps {
  assignees: Assignee[];
  optionsLoading: boolean;
  taskNameToAvailableActions: Record<TASK_NAME, AvailableActionsModel>;
  allowedToAssignTasks: string[];
  allowedToSelfAssignTasks: string[];
  allowedToUnAssignTasks: string[];
  onCommentsIndicatorClick: Dispatch<string>;
}

export const useTableColumns = ({
  assignees,
  optionsLoading,
  taskNameToAvailableActions,
  allowedToAssignTasks,
  allowedToSelfAssignTasks,
  allowedToUnAssignTasks,
  onCommentsIndicatorClick
}: UseTableColumnsProps) => {
  const { t } = useTranslation();
  const colorCodingIconCallback = useColorCodingIconCallback();

  return useMemo<TableColumnsType<TaskEntity>>(
    () => [
      {
        title: (
          <span data-qa-selector="taskTypeTitle">
            {t('bo.taskManagement.table.taskType')}
          </span>
        ),
        dataIndex: 'taskType',
        key: 'taskDefinitionKey',
        sorter: true,
        render: (_, task) => <TaskType task={task} />
      },
      {
        title: (
          <span data-qa-selector="orderNumberTitle">
            {t('bo.taskManagement.table.orderNumber')}
          </span>
        ),
        dataIndex: 'orderNumber',
        key: 'orderNumber',
        sorter: true,
        render: (orderNumber, { orderId, taskType, order }) => (
          <OrderNumber
            orderId={orderId}
            taskType={taskType as TASK_NAME}
            flowVersion={order?.flowVersion}
            qaSelector="orderNumber"
            orderNumber={orderNumber}
          />
        )
      },
      {
        title: (
          <span data-qa-selector="orderStatusTitle">
            {t('bo.taskManagement.table.orderStatus')}
          </span>
        ),
        dataIndex: 'orderState',
        key: 'orderState',
        sorter: true,
        render: (value) => (
          <TableCell data-qa-selector="orderState">
            {value ? t(`bo.taskManagement.orderStates.${value}`) : null}
          </TableCell>
        )
      },
      {
        title: (
          <span data-qa-selector="assigneeTitle">
            {t('bo.taskManagement.table.assignee')}
          </span>
        ),
        dataIndex: 'assignee',
        key: 'assignee',
        render: (_, { id, taskStatus, order, taskType, assignee }) => (
          <AssignedTo
            taskId={id}
            taskType={taskType}
            assignees={assignees}
            assignee={assignee as UserProjection}
            taskStatus={taskStatus}
            retailCountry={order?.retailCountry}
            isLoading={optionsLoading}
            allowedToAssignTasks={allowedToAssignTasks}
            allowedToSelfAssignTasks={allowedToSelfAssignTasks}
            allowedToUnAssignTasks={allowedToUnAssignTasks}
            qaSelector="assignedTo"
          />
        )
      },
      {
        title: (
          <span data-qa-selector="taskActionTitle">
            {t('bo.taskManagement.table.taskAction')}
          </span>
        ),
        dataIndex: 'action',
        key: 'state',
        render: (value) => (
          <TableCell data-qa-selector="taskAction">
            {value
              ? t(`bo.taskManagement.completeTaskForm.action.${value}`)
              : null}
          </TableCell>
        )
      },
      {
        title: (
          <span data-qa-selector="taskStatusTitle">
            {t('bo.taskManagement.table.taskStatus')}
          </span>
        ),
        dataIndex: 'taskStatus',
        key: 'taskStatus',
        sorter: false,
        render: (value, record) => (
          <TableCell
            data-qa-selector="taskStatus"
            className={useColorCodingIconCallbackCn.cell}
          >
            {colorCodingIconCallback(record)}
            {value ? t(`bo.taskManagement.type.${value}`) : null}
          </TableCell>
        )
      },
      {
        title: (
          <span data-qa-selector="inTaskStatusTitle">
            {t('bo.taskManagement.table.inTaskStatus')}
          </span>
        ),
        dataIndex: 'resolutionTime',
        key: 'resolutionTime',
        sorter: true,
        width: 120,
        render: (resolutionTime) => (
          <DaysInStatus
            resolutionTime={resolutionTime}
            data-qa-selector="daysInStatus"
          />
        )
      },
      {
        title: (
          <span data-qa-selector="createdOnTitle">
            {t('bo.taskManagement.search.label.createdOn')}
          </span>
        ),
        dataIndex: 'createdOn',
        key: 'createdOn',
        sorter: true,
        render: (value) => (
          <TableCell data-qa-selector="createdOn">
            {value ? formatDateTime(value) : null}
          </TableCell>
        )
      },
      {
        title: (
          <span data-qa-selector="lastUpdatedTaskTitle">
            {t('bo.taskManagement.table.lastUpdatedTask')}
          </span>
        ),
        dataIndex: 'taskUpdatedAt',
        key: 'taskUpdatedAt',
        sorter: true,
        render: (value) => (
          <TableCell data-qa-selector="lastUpdatedTask">
            {value ? formatDateTime(value) : null}
          </TableCell>
        )
      },
      {
        title: (
          <span data-qa-selector="completedOnTitle">
            {t('bo.taskManagement.search.label.completedOn')}
          </span>
        ),
        dataIndex: 'completedOn',
        key: 'completedOn',
        sorter: true,
        render: (value) => (
          <TableCell data-qa-selector="completedOn">
            {value ? formatDateTime(value) : null}
          </TableCell>
        )
      },
      {
        title: <span data-qa-selector="tableCommentsQuantityTitle" />,
        key: 'taskActions',
        render: (
          _,
          {
            id,
            taskType,
            taskStatus,
            assignee,
            order,
            commentsCount,
            ...propsToCompleteModal
          }
        ) => (
          <TaskActions
            {...propsToCompleteModal}
            id={id}
            order={order}
            taskType={taskType as TASK_NAME}
            assignee={assignee}
            taskStatus={taskStatus}
            commentsCount={commentsCount}
            onCommentsIndicatorClick={onCommentsIndicatorClick}
            qaSelector="taskActions"
            taskNameToAvailableActions={taskNameToAvailableActions}
          />
        )
      }
    ],
    [
      allowedToAssignTasks,
      allowedToSelfAssignTasks,
      allowedToUnAssignTasks,
      assignees,
      colorCodingIconCallback,
      onCommentsIndicatorClick,
      optionsLoading,
      t,
      taskNameToAvailableActions
    ]
  );
};
