import { FileTextTwoTone } from '@ant-design/icons';
import { Button, Table, TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';

import { TaskEntityOfType } from '../../types';

import cn from './styles.less';

type FinancingSepaMandateVerificationTask =
  TaskEntityOfType<'FinancingSepaMandateVerificationTaskProjection'>;

interface Props {
  data: FinancingSepaMandateVerificationTask;
}

export function FinancingSepaMandateVerificationSubRow({ data }: Props) {
  const { t } = useTranslation();
  const columns: TableColumnsType<FinancingSepaMandateVerificationTask> = [
    {
      key: 'iban',
      title: t('bo.taskManagement.financingSepaMandateVerificationTask.iban'),
      render: (_, { sepaMandate }) => (
        <span data-qa-selector="iban">{sepaMandate?.iban}</span>
      )
    },
    {
      key: 'accountHolder',
      title: t(
        'bo.taskManagement.financingSepaMandateVerificationTask.accountHolder'
      ),
      render: (_, { sepaMandate }) => (
        <span data-qa-selector="accountHolder">
          {sepaMandate?.accountHolder}
        </span>
      )
    },
    {
      key: 'documents',
      title: t(
        'bo.taskManagement.financingSepaMandateVerificationTask.documents'
      ),
      render: (_, { documentsToVerify }) => (
        <div data-qa-selector="documentsToVerify">
          {documentsToVerify.map((doc) => (
            <Button
              key={doc.id}
              block
              type="link"
              icon={<FileTextTwoTone />}
              href={doc.fullUrl}
              target="_blank"
              style={{ textAlign: 'start' }}
            >
              {doc.fileName}
            </Button>
          ))}
        </div>
      )
    }
  ];

  return (
    <Table
      columns={columns}
      pagination={false}
      rowKey="id"
      dataSource={[data]}
      bordered
      className={cn.table}
      style={{ maxWidth: '1200px' }}
    />
  );
}
