import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useUnAssignTaskManagerTaskMutation } from '~apollo/gql-types';
import { useQueryBaseOptions } from '~hooks/useQueryBaseOptions';

interface Props {
  taskId: string;
}

export function useUnassign({ taskId }: Props) {
  const { t } = useTranslation();
  const queryBaseOptions = useQueryBaseOptions();

  const [unAssign, { loading }] = useUnAssignTaskManagerTaskMutation({
    ...queryBaseOptions,
    refetchQueries: ['SearchTaskManagerTasks']
  });

  return [
    useCallback(async () => {
      if (global.confirm(t('bo.taskManagement.unassign.confirmation'))) {
        await unAssign({
          variables: {
            taskId
          }
        });
      }
    }, [t, taskId, unAssign]),
    loading
  ] as const;
}
