// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1uSHxMWzI63A85V76FHTtw\\=\\= {\n  color: #00ff00 !important;\n}\n.sG9BQx04sOI1C55mgmTx5g\\=\\= {\n  color: #ffff00 !important;\n}\n._4b0laMQS\\+\\+IGJ0Q97IoTwQ\\=\\= {\n  color: #ff0000 !important;\n}\n.ud\\+kQP6KVhtiayvYPEtKHQ\\=\\= {\n  color: #808080 !important;\n}\n.plT8SPHRX\\+YV8DAVWkeEYA\\=\\= {\n  fill: currentColor;\n  font-size: 24px;\n}\n.bi78PmsMv38sx\\+MUbvgNaA\\=\\= {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  gap: 6px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/TaskManagement/hooks/useColorCodingIconCallback/styles.less"],"names":[],"mappings":"AAEA;EACE,yBAAA;AADF;AAIA;EACE,yBAAA;AAFF;AAKA;EACE,yBAAA;AAHF;AAMA;EACE,yBAAA;AAJF;AAOA;EACE,kBAAA;EACA,eAAA;AALF;AAQA;EACE,aAAA;EACA,6BAAA;EACA,mBAAA;EACA,QAAA;AANF","sourcesContent":["@opacity: 1;\n\n.greenRow {\n  color: rgba(0, 255, 0, @opacity) !important;\n}\n\n.yellowRow {\n  color: rgba(255, 255, 0, @opacity) !important;\n}\n\n.redRow {\n  color: rgba(255, 0, 0, @opacity) !important;\n}\n\n.grayRow {\n  color: rgba(128, 128, 128, @opacity) !important;\n}\n\n.icon {\n  fill: currentColor;\n  font-size: 24px;\n}\n\n.cell {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  gap: 6px;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"greenRow": "_1uSHxMWzI63A85V76FHTtw==",
	"yellowRow": "sG9BQx04sOI1C55mgmTx5g==",
	"redRow": "_4b0laMQS++IGJ0Q97IoTwQ==",
	"grayRow": "ud+kQP6KVhtiayvYPEtKHQ==",
	"icon": "plT8SPHRX+YV8DAVWkeEYA==",
	"cell": "bi78PmsMv38sx+MUbvgNaA=="
};
export default ___CSS_LOADER_EXPORT___;
