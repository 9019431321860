import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Typography } from 'antd';

import { CustomModalComponentProps } from '../types';

export function HiddenControl({
  optionName: fieldName,
  defaultValue,
  label
}: CustomModalComponentProps) {
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue(fieldName, defaultValue);
  }, [fieldName, defaultValue, setValue]);

  return (
    <>
      {label ? <Typography.Paragraph>{label}</Typography.Paragraph> : null}
      <Controller
        defaultValue={defaultValue}
        render={() => null}
        name={fieldName}
      />
    </>
  );
}
