import { EditOutlined } from '@ant-design/icons';
import { Button, ButtonProps, Dropdown, Menu } from 'antd';
import { DropdownButtonProps } from 'antd/lib/dropdown';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { cloneElement, useCallback, useMemo } from 'react';

import { TASK_NAME } from '../../constants';
import { showModal } from '../../TaskModal';
import { useCompleteTaskDataByType } from '../../TaskModal/hooks/useCompleteTaskDataByType';

import { formatTime } from '~helpers/date';
import { TaskEntityModal } from '~pages/TaskManagement/types';
import { SelectData } from '~pages/TaskManagement/TaskModal/types';
import { AvailableActionsModel } from '~types/AvailableActions';

function BaseDropdownButton(props: DropdownButtonProps) {
  return (
    <Dropdown.Button
      {...props}
      buttonsRender={([leftButton, rightButton]) => [
        cloneElement(leftButton as React.ReactElement, {
          icon: <EditOutlined />
        }),
        rightButton
      ]}
    />
  );
}

function BaseButton(props: ButtonProps) {
  return <Button {...props} icon={<EditOutlined />} />;
}

export type ActionsButtonProps = TaskEntityModal & {
  repeatAt?: string;
  taskNameToAvailableActions: Record<TASK_NAME, AvailableActionsModel>;
};

export function ActionsButton({
  id,
  taskType,
  repeatAt,
  order,
  taskNameToAvailableActions,
  ...props
}: ActionsButtonProps) {
  const taskData = useCompleteTaskDataByType({
    taskType: taskType as TASK_NAME,
    taskNameToAvailableActions
  });
  const options = useMemo(
    () => (taskData?.action as SelectData)?.options ?? [],
    [taskData?.action]
  );

  const handleActionsButtonClick = useCallback(
    (option) =>
      showModal({
        ...(props as TaskEntityModal),
        id,
        taskType,
        order,
        orderId: order?.id,
        action: option?.key
      }),
    [id, order, props, taskType]
  );

  const menu = useMemo(
    () => (
      <Menu
        data-qa-selector="actions-dropdown-options"
        onClick={handleActionsButtonClick}
        items={options.map(
          ({ value, label }) =>
            ({
              label: <span data-qa-selector={value}>{label}</span>,
              key: value
            } as ItemType)
        )}
      />
    ),
    [handleActionsButtonClick, options]
  );

  const text = repeatAt ? formatTime(repeatAt) : null;
  const As = options.length ? BaseDropdownButton : BaseButton;

  return (
    <As
      type="primary"
      data-qa-selector="actions-dropdown"
      overlay={options.length ? menu : null}
      onClick={handleActionsButtonClick}
    >
      {text}
    </As>
  );
}
