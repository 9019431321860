import { Table, TableColumnsType, Typography } from 'antd';
import { flow, get, head, last } from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import cn from './styles.less';

import {
  ClaimProofPendingTaskProjection,
  OrderProjection,
  RetailClaimProjection,
  RetailSubClaimCommentProjection,
  RetailSubClaimProjection
} from '~apollo/gql-types';
import { formatDate } from '~helpers/date';
import { i18nDetectLng } from '~i18n/utils';
import * as ROUTES from '~routes';

const { Link } = Typography;

interface Entity {
  name: string;
  order: OrderProjection;
  claim: RetailClaimProjection;
  proofLastUpdatedOn: string;
}

interface Props {
  data: ClaimProofPendingTaskProjection;
}

export function ClaimsProofSubRow({ data }: Props) {
  const { t } = useTranslation();
  const { claim, claimGroup, order } = data;
  const { name } = claimGroup;

  const dataSource = [
    {
      name: t(name),
      order,
      claim,
      proofLastUpdatedOn: flow<
        [RetailClaimProjection],
        RetailSubClaimProjection[],
        RetailSubClaimProjection,
        RetailSubClaimCommentProjection[],
        RetailSubClaimCommentProjection,
        string
      >(
        get(['subClaims']),
        head,
        get(['comments']),
        last,
        get('createdOn')
      )(claim)
    }
  ];

  const columns: TableColumnsType<Entity> = [
    {
      title: t('bo.taskManagement.claimsProofTable.claimGroup'),
      dataIndex: 'name',
      key: 'name',
      render: (name) => <span data-qa-selector="claimGroup">{name || '-'}</span>
    },
    {
      title: t('bo.taskManagement.claimsProofTable.claimNumber'),
      dataIndex: 'claim',
      key: 'claimNumber',
      render: (claim, { order }) => (
        <Link
          href={ROUTES.ORDER_CLAIMS_DETAILS.LINK({
            language: i18nDetectLng(),
            orderId: order.id,
            claimId: claim.claim
          })}
        >
          {claim?.number || '-'}
        </Link>
      )
    },
    {
      title: t('bo.taskManagement.claimsProofTable.claimStatus'),
      dataIndex: 'claim',
      key: 'claim',
      render: (claim) => (
        <span data-qa-selector="claimStatus">
          {t(`bo.orderClaims.claim.status.${claim?.state}`)}
        </span>
      )
    },
    {
      title: t('bo.taskManagement.claimsProofTable.proofLastUpdated'),
      dataIndex: 'proofLastUpdatedOn',
      key: 'proofLastUpdatedOn',
      render: (proofLastUpdatedOn) => (
        <span data-qa-selector="refundAmount">
          {formatDate(proofLastUpdatedOn)}
        </span>
      )
    }
  ];

  return (
    <Table
      columns={columns}
      pagination={false}
      rowKey="cashOutReason"
      dataSource={dataSource}
      bordered
      className={cn.table}
      style={{ width: '100%', border: '1px solid #616161' }}
    />
  );
}
