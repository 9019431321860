import { useCallback } from 'react';

import { useLoadOrderDetailsLazyQuery } from '~apollo/gql-types';
import { DOMAINS } from '~constants/permissions';

export const useLoadOrder = () => {
  const [loadOrderDetailsQuery] = useLoadOrderDetailsLazyQuery({
    context: { headers: { 'access-domain': DOMAINS.ORDER_MANAGEMENT } }
  });

  return useCallback(
    async (orderId: string) =>
      orderId &&
      (await loadOrderDetailsQuery({ variables: { orderId } }))?.data?.order,
    [loadOrderDetailsQuery]
  );
};
