import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

import {
  LoadOrderDetailsDocument,
  LoadOrderDetailsQuery,
  LoadOrderDetailsQueryVariables
} from '~apollo/gql-types';
import { DOMAINS } from '~constants/permissions';
import { useQueryBaseOptions } from '~hooks/useQueryBaseOptions';

const context = { headers: { 'access-domain': DOMAINS.ORDER_MANAGEMENT } };

export const useFetchOrderDetails = () => {
  const baseQueryOptions = useQueryBaseOptions();
  const apolloClient = useApolloClient();

  return useCallback(
    (orderId: string) =>
      apolloClient.query<LoadOrderDetailsQuery, LoadOrderDetailsQueryVariables>(
        {
          query: LoadOrderDetailsDocument,
          ...baseQueryOptions,
          variables: {
            orderId
          },
          context,
          fetchPolicy: 'network-only'
        }
      ),
    [apolloClient, baseQueryOptions]
  );
};
