import { TableCell } from '@retail/backoffice-ui';
import { useTranslation } from 'react-i18next';

import { formatDateTime } from '~helpers/date';

interface CancellationAndReturnDateArguments {
  [key: string]: string;
}

const useCancellationAndReturnDate = (
  dates: CancellationAndReturnDateArguments
) =>
  Object.entries(dates).reduce<{ name?: string; value?: string }>(
    (acc, [name, value]) => {
      if (value) {
        acc.name = name;
        acc.value = value;
      }

      return acc;
    },
    {}
  );

export const useCustomDateColumn = ({
  ...dates
}: CancellationAndReturnDateArguments) => {
  const { t } = useTranslation();
  const { name, value } = useCancellationAndReturnDate(dates);

  if (!value) {
    return null;
  }

  const isAllDatesEmpty = value === '-';

  return {
    title: t(`bo.taskManagement.cancellationsAndReturnsSubRow.${name}`),
    dataIndex: name,
    key: name,
    render: () => (
      <TableCell data-qa-selector="customDate">
        {value && !isAllDatesEmpty ? formatDateTime(value) : null}
      </TableCell>
    )
  };
};
