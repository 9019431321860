import { OptionModel } from '@retail/backoffice-ui';
import { TFunction } from 'react-i18next';

import { CompleteTaskDataByType, TextareaData } from '../../types';

import { RetailOrderBasedTaskAction } from '~apollo/gql-types';

export function getDescriptionFieldConfig(
  data?: Omit<TextareaData, 'type'>
): CompleteTaskDataByType {
  return {
    description: {
      ...data,
      type: 'Textarea'
    }
  };
}

interface Props {
  t: TFunction<'translation', undefined>;
  options: OptionModel[];
}

export function getHappinessCallTaskFormConfig({
  t,
  options
}: Props): CompleteTaskDataByType {
  return {
    taskType: {
      getInitialValue: () => 'outbound_phone',
      type: 'Select',
      disabled: true,
      required: true,
      options: [
        {
          label: t('bo.taskManagement.completeTaskForm.type.outboundPhone'),
          value: 'outbound_phone'
        }
      ]
    },
    action: {
      type: 'Select',
      required: true,
      getAdditionalFields(action: RetailOrderBasedTaskAction) {
        if (action === RetailOrderBasedTaskAction.Reopen) {
          return {
            repeatAt: {
              required: true,
              type: 'Date'
            }
          };
        }
      },
      getInitialValue: (modalData) => modalData?.action,
      options
    },
    ...getDescriptionFieldConfig({ required: true })
  };
}
