import { format, isValid } from 'date-fns';

import { createParseDate } from './parseDate';

import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from '~constants/date';

export const parseDate = createParseDate();

const customFormatDate = (dateFormat: string) => (rawDate: string) => {
  const date = parseDate(rawDate);
  return isValid(date) ? format(date, dateFormat) : '';
};

export const formatDate = customFormatDate(DATE_FORMAT);
export const formatTime = customFormatDate(TIME_FORMAT);
export const formatDateTime = customFormatDate(DATE_TIME_FORMAT);
