import { UserContextProvider } from '@retail/backoffice-ui/src/UserContext';
import { PropsWithChildren } from 'react';

import {
  AccessParametersFragmentFragment,
  useUserInfoQuery
} from '~apollo/gql-types';
import { useQueryBaseOptions } from '~hooks/useQueryBaseOptions';

export interface IUserContext {
  isLoaded: boolean;
  permissions: string[] | null;
  accessParameters: AccessParametersFragmentFragment[] | null;
  id: string;
  country: string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function UserProvider({ children }: PropsWithChildren<{}>) {
  const queryBaseOptions = useQueryBaseOptions();
  const { data } = useUserInfoQuery(queryBaseOptions);
  const user = data?.me;
  const isLoaded = user?.id ?? false;

  if (!isLoaded) {
    return null;
  }

  return (
    <UserContextProvider<IUserContext>
      value={{
        id: user?.id,
        isLoaded,
        permissions: user?.effectivePermissions,
        accessParameters: user?.accessParameters,
        country: user?.country
      }}
    >
      {children}
    </UserContextProvider>
  );
}
