import { TableColumnType } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TaskManagerCommentProjection } from '~apollo/gql-types';
import { formatDateTime } from '~helpers/date';
import { getFullName } from '~utils/user';

export const useCommentsTableColumns = () => {
  const { t } = useTranslation();

  return useMemo(
    (): TableColumnType<TaskManagerCommentProjection>[] => [
      {
        title: (
          <strong data-qa-selector="tableTitleCommentCreatedOn">
            {t('bo.taskManagement.comments.table.createdOn')}
          </strong>
        ),
        dataIndex: 'createdOn',
        key: 'createdOn',
        width: 200,
        render: (createdOn) => (
          <span data-qa-selector="createdOn">{formatDateTime(createdOn)}</span>
        )
      },
      {
        title: (
          <strong data-qa-selector="tableTitleCommentCreatedBy">
            {t('bo.taskManagement.comments.table.createdBy')}
          </strong>
        ),
        dataIndex: 'createdBy',
        key: 'createdBy',
        width: 300,
        render: (createdBy) => (
          <span data-qa-selector="createdBy">
            {getFullName(createdBy) ||
              t('bo.taskManagement.comments.table.createdBy.system')}
          </span>
        )
      },
      {
        title: (
          <strong data-qa-selector="tableTitleCommentContent">
            {t('bo.taskManagement.comments.table.comment')}
          </strong>
        ),
        dataIndex: 'comment',
        key: 'comment',
        render: (comment) => <span data-qa-selector="comment">{comment}</span>
      }
    ],
    [t]
  );
};
