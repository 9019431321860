import { SelectControlled } from '@retail/backoffice-ui';
import { RejectFinancingStatusesMessage } from '@retail/backoffice-ui/src/RejectFinancingStatusesMessage';
import { useTranslation } from 'react-i18next';

import { CustomModalComponentProps } from '../../types';

export const SelectWithRejectFinancingStatusesMessage = ({
  data,
  optionName,
  control,
  modalData
}: CustomModalComponentProps) => {
  const { financingStatus } = modalData;
  const { t } = useTranslation();
  const [options, loading] = data.useOptions?.(modalData) ?? [
    data.options,
    false
  ];

  return (
    <>
      <SelectControlled
        key={optionName}
        disabled={data.disabled || !options?.length}
        options={options}
        getOptionValue={(item) => item?.value ?? null}
        label={t(`bo.taskManagement.form.label.${optionName}`)}
        placeholder={t(`bo.taskManagement.form.placeholder.${optionName}`)}
        required={data.required}
        loading={data.loading || loading}
        controllerProps={{
          control,
          name: optionName,
          rules: {
            required: {
              value: data.required,
              message: t('bo.taskManagement.form.validation.canNotBeEmpty')
            }
          }
        }}
      />
      <RejectFinancingStatusesMessage financingStatus={financingStatus} />
    </>
  );
};
