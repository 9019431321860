/* eslint-disable jsx-a11y/no-static-element-interactions */
import { CommentOutlined } from '@ant-design/icons';
import { Dispatch } from 'react';

import cn from './styles.less';

import { useCommentsTotalCount } from '~pages/TaskManagement/hooks/useCommentsTotalCount';

interface Props {
  commentsCount: number;
  qaSelector: string;
  taskId: string;
  onCommentsIndicatorClick: Dispatch<string>;
}

export const CommentsIndicator = ({
  commentsCount,
  qaSelector,
  taskId,
  onCommentsIndicatorClick
}: Props) => {
  const total = useCommentsTotalCount(taskId);
  const hasComments = commentsCount || total;

  return (
    <div
      data-qa-selector={qaSelector}
      className={cn.commentsWrapper}
      onClick={() => onCommentsIndicatorClick(taskId)}
    >
      <div className={cn.comments}>
        <CommentOutlined />
        {hasComments && (
          <span className={cn.count}>{total || commentsCount}</span>
        )}
      </div>
    </div>
  );
};
