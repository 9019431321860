import localizedCountries from 'localized-countries/data/en_GB.json';

// local dependencies
export { useFormatCurrency } from './hooks';

export const toLocalizedCountry = (countryCode: string) =>
  localizedCountries[countryCode as keyof typeof localizedCountries];

export const pluralize = (value: number, unit: string) => {
  if (value === 1) {
    return unit;
  } else if (unit.endsWith('s') || unit.endsWith('sh') || unit.endsWith('ch')) {
    return `${unit}es`;
  }

  return `${value} ${unit}s`;
};
