import { MutationHookOptions } from '@apollo/client';
import { useUserContext } from '@retail/backoffice-ui/src/UserContext';

import { useCompleteExternalFinancingBankDetailsTask } from './useCompleteExternalFinancingBankDetailsTask';

import {
  RetailOrderBasedTaskAction,
  useCompleteTaskManagerTaskMutation,
  useCompleteTaskManagerTaskV2Mutation,
  useUpdateTaskManagerTaskActionMutation
} from '~apollo/gql-types';
import { FEATURE } from '~constants/feature';
import { useFeatures } from '~hooks/useFeatures';
import { TASK_NAME } from '~pages/TaskManagement/constants';
import { TaskEntity } from '~pages/TaskManagement/types';
import { IUserContext } from '~providers/UserProvider';
import { AvailableActionsModel } from '~types/AvailableActions';

interface Props {
  taskName: TASK_NAME;
  taskData: TaskEntity;
  mutationOptions: Pick<
    MutationHookOptions,
    'refetchQueries' | 'onError' | 'onCompleted'
  >;
  action: RetailOrderBasedTaskAction;
  availableActions?: AvailableActionsModel;
  initialValues: unknown;
}

export function useCompleteTaskManagerTaskAsync({
  taskName,
  taskData,
  mutationOptions,
  action,
  availableActions,
  initialValues
}: Props) {
  const { country } = useUserContext<IUserContext>();
  const { isFeatureEnabled } = useFeatures({
    country,
    names: [FEATURE.COMPLETE_TASK_MANAGER_TASK_API_MIGRATION]
  });
  const [v1Complete, { loading: v1Loading }] =
    useCompleteTaskManagerTaskMutation(mutationOptions);
  const [v2Complete, { loading: v2Loading }] =
    useCompleteTaskManagerTaskV2Mutation(mutationOptions);
  const [update, { loading: updateLoading }] =
    useUpdateTaskManagerTaskActionMutation(mutationOptions);

  const [completeExtFinBankDetailsTask, isExternalFinBankDetailsTaskLoading] =
    useCompleteExternalFinancingBankDetailsTask({
      taskData:
        taskData?.__typename ===
        'RetailExternalFinancingBankDetailsPendingTaskProjection'
          ? taskData
          : null,
      mutationOptions,
      initialValues
    });

  if (taskName === TASK_NAME.EXTERNAL_FINANCING_BANK_DETAILS_PENDING) {
    return {
      submitTask: completeExtFinBankDetailsTask,
      loading: isExternalFinBankDetailsTaskLoading
    };
  }

  const isV2Flow = isFeatureEnabled(
    FEATURE.COMPLETE_TASK_MANAGER_TASK_API_MIGRATION
  );

  if (availableActions?.updateActions?.includes(action)) {
    return {
      loading: updateLoading,
      submitTask: update
    };
  }

  return {
    loading: isV2Flow ? v2Loading : v1Loading,
    submitTask: isV2Flow ? v2Complete : v1Complete
  };
}
