import { OptionModel } from '@retail/backoffice-ui';
import { ADDRESS_COUNTRIES_VALUES } from '@retail/i18n/constants';
import { flow, sortBy, values } from 'lodash/fp';

import prepareFormattedOptions from '../prepareFormattedOptions';

import { toLocalizedCountry } from '~utils/i18n';

export const prepareCountryOptions = flow(
  prepareFormattedOptions(toLocalizedCountry),
  sortBy<OptionModel>('label')
);

export const countryAddressOptions = flow(
  values,
  prepareCountryOptions
)(ADDRESS_COUNTRIES_VALUES);
