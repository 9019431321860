import { makeVar, useReactiveVar } from '@apollo/client';

const totalCountByTaskId = makeVar({});

export const useCommentsTotalCount = (taskId: string) =>
  useReactiveVar<Record<string, number>>(totalCountByTaskId)?.[taskId];

export const updateTotalCountByTaskId = (taskId: string, total: number) => {
  totalCountByTaskId({
    ...totalCountByTaskId(),
    [taskId]: total
  });
};
