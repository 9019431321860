import { OrderQuery } from '~apollo/gql-types';

export type Order = OrderQuery['order'];

export type OrderItem =
  | Order['adItems'][0]
  | Order['productItems'][0]
  | Order['warrantyItems'][0]
  | Order['adSecondaryWheelsItems'][0]
  | Order['carRegistrationItems'][0]
  | Order['vehiclePreparationItems'][0]
  | (Order['deliveryItems'][0] & Omit<Order['deliveryDetails'], '__typename'>);

export const getOrderItems = (order: Order): OrderItem[] => {
  const res: OrderItem[] = [
    ...order.adItems,
    ...order.productItems,
    ...order.warrantyItems,
    ...order.adSecondaryWheelsItems,
    ...order.carRegistrationItems,
    ...order.vehiclePreparationItems
  ];
  if (order.deliveryItems?.[0]) {
    res.push({
      deliveryLocationType: order.deliveryDetails.deliveryLocationType,
      ...order.deliveryItems[0]
    });
  }

  return res;
};
