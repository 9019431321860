import { formatCurrency } from '@retail/backoffice-ui/src/utils/formatCurrency';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { HiddenControl } from '../../Inputs/HiddenControl';
import { CompleteTaskDataByType } from '../../types';

import { useCancellationRequestedTaskConfig } from './useCancellationRequestedTaskConfig';
import { useDefaultTaskConfig } from './useDefaultTaskConfig';
import { useExternalFinancingBankDetailsPendingTaskConfig } from './useExternalFinancingBankDetailsPendingTaskConfig';
import { useOverpaidRefundApprovalTaskConfig } from './useOverpaidRefundTasksConfig';
import { useLoadLicensePlateByOrderId } from './useLoadLicensePlateByOrderId';
import { useLoadTaxBpmByOrderId } from './useLoadTaxBpmByOrderId';
import { useOrderShippingDetailsUpdateTaskConfig } from './useOrderShippingDetailsUpdateTaskConfig/useOrderShippingDetailsUpdateTaskConfig';
import {
  getDescriptionFieldConfig,
  getHappinessCallTaskFormConfig
} from './utils';

import {
  FinancingApplicationPendingBankDecisionTaskProjection,
  FinancingApplicationValidationTaskProjection,
  RetailOrderBasedTaskAction,
  RetailOrderFinanceRejectReason
} from '~apollo/gql-types';
import { ReturnDeliveryType, TASK_NAME } from '~pages/TaskManagement/constants';
import { TaskEntityOfType } from '~pages/TaskManagement/types';
import { AvailableActionsModel } from '~types/AvailableActions';

interface Props {
  taskType: TASK_NAME;
  taskNameToAvailableActions: Record<TASK_NAME, AvailableActionsModel>;
}

export function useCompleteTaskDataByType({
  taskType,
  taskNameToAvailableActions
}: Props): CompleteTaskDataByType {
  const { t } = useTranslation();
  const [loadLicensePlateByOrderId, loadLicensePlateByOrderIdIsLoading] =
    useLoadLicensePlateByOrderId();
  const loadTaxBpmByOrderId = useLoadTaxBpmByOrderId();
  const defaultConfig = useDefaultTaskConfig();
  const orderShippingDetailsUpdateTaskConfig =
    useOrderShippingDetailsUpdateTaskConfig();

  const externalFinancingBankDetailsPendingTaskConfig =
    useExternalFinancingBankDetailsPendingTaskConfig(
      taskNameToAvailableActions[
        TASK_NAME.EXTERNAL_FINANCING_BANK_DETAILS_PENDING
      ]
    );

  const overpaidRefundApprovalTaskConfig =
    useOverpaidRefundApprovalTaskConfig();

  const cancellationRequestedTaskConfig = useCancellationRequestedTaskConfig(
    taskNameToAvailableActions[TASK_NAME.CANCELLATION_REQUESTED]
  );
  return useMemo(() => {
    const configByTaskName: Record<TASK_NAME, CompleteTaskDataByType> = {
      [TASK_NAME.REFUND_PENDING_TASK]: defaultConfig,
      [TASK_NAME.OVERPAYMENT_REFUND_APPROVAL]: overpaidRefundApprovalTaskConfig,
      [TASK_NAME.OVERPAYMENT_REFUND_PENDING]: {
        action: {
          type: 'Select',
          required: true,
          getInitialValue: (modalData) => modalData?.action,
          options: [
            {
              label: t('bo.taskManagement.completeTaskForm.action.COMPLETED'),
              value: RetailOrderBasedTaskAction.Completed
            }
          ]
        },
        ...getDescriptionFieldConfig()
      },
      [TASK_NAME.ACCOUNTING_DATE_PENDING_TASK]: defaultConfig,
      [TASK_NAME.CREDIT_NOTE_PENDING_TASK]: defaultConfig,
      [TASK_NAME.CREDIT_NOTE_NUMBER_PENDING_TASK]: defaultConfig,
      [TASK_NAME.EXTERNAL_FINANCING_BANK_DETAILS_PENDING]:
        externalFinancingBankDetailsPendingTaskConfig,
      [TASK_NAME.FINANCING_SEPA_MANDATE_VERIFICATION_TASK]: {
        action: {
          type: 'Select',
          required: true,
          getInitialValue: (modalData) => modalData?.action,
          options:
            taskNameToAvailableActions[
              TASK_NAME.FINANCING_SEPA_MANDATE_VERIFICATION_TASK
            ]?.options
        }
      },
      [TASK_NAME.FINANCING_BILLING_ADDRESS_VERIFICATION_TASK]: {
        action: {
          type: 'Select',
          required: true,
          getInitialValue: (modalData) => modalData?.action,
          options:
            taskNameToAvailableActions[
              TASK_NAME.FINANCING_BILLING_ADDRESS_VERIFICATION_TASK
            ]?.options
        }
      },
      [TASK_NAME.ORDER_SHIPPING_DETAILS_UPDATE_TASK]:
        orderShippingDetailsUpdateTaskConfig,
      [TASK_NAME.FINANCING_MONTHLY_PAYMENT_DAY_UPDATE_TASK]: {
        action: {
          type: 'Select',
          required: true,
          getInitialValue: (modalData) => modalData?.action,
          options:
            taskNameToAvailableActions[
              TASK_NAME.FINANCING_MONTHLY_PAYMENT_DAY_UPDATE_TASK
            ]?.options
        }
      },
      [TASK_NAME.CAR_RETURN_HANDOVER_APPOINTMENT_TASK]: {
        cancelType: {
          getInitialValue: () => 'CUSTOMER_CANCELLATION',
          type: 'Select',
          disabled: true,
          required: true,
          options: [
            {
              label: t(
                'bo.taskManagement.completeTaskForm.cancellation.customerCancellation'
              ),
              value: 'CUSTOMER_CANCELLATION'
            }
          ]
        },
        returnDeliveryType: {
          getInitialValue: (
            modalData: TaskEntityOfType<'CarReturnHandoverAppointmentTaskProjection'>
          ) => modalData.returnDeliveryType,
          required: true,
          type: 'Select',
          options: [
            {
              label: t(
                'bo.taskManagement.completeTaskForm.cancellation.customerDropOf'
              ),
              value: ReturnDeliveryType.CUSTOMER_DROP_OF
            },
            {
              label: t(
                'bo.taskManagement.completeTaskForm.cancellation.ahPickUp'
              ),
              value: ReturnDeliveryType.AH_PICK_UP
            }
          ]
        },
        carReturnDate: {
          type: 'Date'
        },
        ...getDescriptionFieldConfig()
      },
      [TASK_NAME.CANCELLATION_REQUESTED]: cancellationRequestedTaskConfig,
      [TASK_NAME.DOCUMENTS_RETURNED_DEREGISTRATION_TASK]: {
        taskType: {
          getInitialValue: () => 'task',
          type: 'Select',
          disabled: true,
          required: true,
          options: [
            {
              label: t('bo.taskManagement.completeTaskForm.type.cancellation'),
              value: 'task'
            }
          ]
        },
        action: {
          type: 'Select',
          required: true,
          getInitialValue: (modalData) => modalData?.action,
          options:
            taskNameToAvailableActions[
              TASK_NAME.DOCUMENTS_RETURNED_DEREGISTRATION_TASK
            ]?.options
        },
        ...getDescriptionFieldConfig()
      },
      [TASK_NAME.DOCUMENTS_RETURNED_TASK]: {
        taskType: {
          getInitialValue: () => 'task',
          type: 'Select',
          disabled: true,
          required: true,
          options: [
            {
              label: t('bo.taskManagement.completeTaskForm.type.cancellation'),
              value: 'task'
            }
          ]
        },
        action: {
          type: 'Select',
          required: true,
          getInitialValue: (modalData) => modalData?.action,
          options:
            taskNameToAvailableActions[TASK_NAME.DOCUMENTS_RETURNED_TASK]
              ?.options
        },
        ...getDescriptionFieldConfig()
      },
      [TASK_NAME.CAR_DEREGISTRATION_TASK]: {
        taskType: {
          getInitialValue: () => 'task',
          type: 'Select',
          disabled: true,
          required: true,
          options: [
            {
              label: t('bo.taskManagement.completeTaskForm.type.cancellation'),
              value: 'task'
            }
          ]
        },
        action: {
          type: 'Select',
          required: true,
          getInitialValue: (modalData) => modalData?.action,
          options:
            taskNameToAvailableActions[TASK_NAME.CAR_DEREGISTRATION_TASK]
              ?.options
        },
        ...getDescriptionFieldConfig()
      },
      [TASK_NAME.HAPPINESS_CALL_TASK]: getHappinessCallTaskFormConfig({
        t,
        options:
          taskNameToAvailableActions[TASK_NAME.HAPPINESS_CALL_TASK]?.options ??
          []
      }),
      [TASK_NAME.REPEAT_HAPPINESS_CALL_TASK]: getHappinessCallTaskFormConfig({
        t,
        options:
          taskNameToAvailableActions[TASK_NAME.REPEAT_HAPPINESS_CALL_TASK]
            ?.options
      }),
      [TASK_NAME.TAX_BPM_UPDATE_PENDING_TASK]: {
        bpmAmount: {
          type: 'Amount',
          required: true,
          getInitialValue: (row) =>
            typeof row?.orderId === 'string'
              ? loadTaxBpmByOrderId(row.orderId)
              : null
        },
        action: {
          type: 'Select',
          required: true,
          getInitialValue: () => RetailOrderBasedTaskAction.Success,
          options: [
            {
              label: t(
                'bo.taskManagement.completeTaskForm.action.successClose'
              ),
              value: RetailOrderBasedTaskAction.Success
            }
          ]
        },
        comment: {
          type: 'Textarea'
        }
      },
      [TASK_NAME.LICENSE_PLATE_UPDATE_PENDING_TASK]: {
        licensePlate: {
          type: 'Input',
          required: true,
          disabled: true,
          loading: loadLicensePlateByOrderIdIsLoading,
          getInitialValue: (row) =>
            row?.orderId ? loadLicensePlateByOrderId(row.orderId) : null
        },
        action: {
          type: 'Select',
          required: true,
          getInitialValue: () => RetailOrderBasedTaskAction.Success,
          options: [
            {
              label: t(
                'bo.taskManagement.completeTaskForm.action.successClose'
              ),
              value: RetailOrderBasedTaskAction.Success
            }
          ]
        },
        comment: {
          type: 'Textarea'
        }
      },
      [TASK_NAME.CARE_CALL_TASK]: {
        taskType: {
          getInitialValue: () => 'outbound_phone',
          type: 'Select',
          disabled: true,
          required: true,
          options: [
            {
              label: t('bo.taskManagement.completeTaskForm.type.outboundPhone'),
              value: 'outbound_phone'
            }
          ]
        },
        action: {
          type: 'Select',
          required: true,
          getAdditionalFields(action: RetailOrderBasedTaskAction) {
            if (action === RetailOrderBasedTaskAction.Reopen) {
              return {
                repeatAt: {
                  required: true,
                  type: 'Date'
                }
              };
            }
          },
          getInitialValue: (modalData) => modalData?.action,
          options: taskNameToAvailableActions[TASK_NAME.CARE_CALL_TASK]?.options
        },
        ...getDescriptionFieldConfig()
      },
      [TASK_NAME.CAR_RETURNED_TASK]: {
        taskType: {
          getInitialValue: () => 'task',
          type: 'Select',
          disabled: true,
          required: true,
          options: [
            {
              label: t('bo.taskManagement.completeTaskForm.type.cancellation'),
              value: 'task'
            }
          ]
        },
        action: {
          getInitialValue: (
            modalData: TaskEntityOfType<'CarReturnedTaskProjection'>
          ) => {
            if (
              modalData?.returnDeliveryType === ReturnDeliveryType.AH_PICK_UP
            ) {
              return RetailOrderBasedTaskAction.CarPickedUpByAh;
            }

            if (modalData?.action) {
              return modalData.action;
            }

            return RetailOrderBasedTaskAction.CarDroppedOffByCustomer;
          },
          type: 'Select',
          required: true,
          options:
            taskNameToAvailableActions[TASK_NAME.CAR_RETURNED_TASK]?.options ??
            []
        },
        ...getDescriptionFieldConfig()
      },
      [TASK_NAME.CASH_OUT_REFUND_APPROVAL_PENDING_TASK]: {
        taskType: {
          getInitialValue: () => 'approved_status',
          type: 'Select',
          disabled: true,
          required: true,
          options: [
            {
              label: t(
                'bo.taskManagement.completeTaskForm.type.approvedStatus'
              ),
              value: 'approved_status'
            }
          ]
        },
        action: {
          type: 'Select',
          required: true,
          getInitialValue: (modalData) => modalData?.action,
          options:
            taskNameToAvailableActions[
              TASK_NAME.CASH_OUT_REFUND_APPROVAL_PENDING_TASK
            ]?.options
        },
        ...getDescriptionFieldConfig()
      },
      [TASK_NAME.CLAIM_PROOF_SUBMITTED_TASK]: {
        taskType: {
          getInitialValue: () => 'claims',
          type: 'Select',
          disabled: true,
          required: true,
          options: [
            {
              label: t('bo.taskManagement.completeTaskForm.type.claims'),
              value: 'claims'
            }
          ]
        },
        action: {
          type: 'Select',
          required: true,
          getInitialValue: (modalData) => modalData?.action,
          options:
            taskNameToAvailableActions[TASK_NAME.CLAIM_PROOF_SUBMITTED_TASK]
              ?.options
        },
        ...getDescriptionFieldConfig()
      },
      [TASK_NAME.CLAIM_PROOF_PENDING_TASK]: {
        taskType: {
          getInitialValue: () => 'claims',
          type: 'Select',
          disabled: true,
          required: true,
          options: [
            {
              label: t('bo.taskManagement.completeTaskForm.type.claims'),
              value: 'claims'
            }
          ]
        },
        action: {
          type: 'Select',
          required: true,
          getInitialValue: (modalData) => modalData?.action,
          options:
            taskNameToAvailableActions[TASK_NAME.CLAIM_PROOF_PENDING_TASK]
              ?.options
        },
        ...getDescriptionFieldConfig()
      },
      [TASK_NAME.ORDER_READY_FOR_VERIFICATION_TASK]: {
        taskType: {
          getInitialValue: () => 'task',
          type: 'Select',
          disabled: true,
          required: true,
          options: [
            {
              label: t('bo.taskManagement.completeTaskForm.type.task'),
              value: 'task'
            }
          ]
        },
        action: {
          type: 'Select',
          required: true,
          getInitialValue: (modalData) => modalData?.action,
          options:
            taskNameToAvailableActions[
              TASK_NAME.ORDER_READY_FOR_VERIFICATION_TASK
            ]?.options,
          getAdditionalFields(action) {
            return getDescriptionFieldConfig({
              required: [
                RetailOrderBasedTaskAction.RequestDocs,
                RetailOrderBasedTaskAction.DocsSubmitted
              ].includes(action as RetailOrderBasedTaskAction)
            });
          }
        }
      },
      [TASK_NAME.BANK_DETAILS_PENDING_TASK]: {
        taskType: {
          getInitialValue: () => 'bankDetails',
          type: 'Select',
          disabled: true,
          required: true,
          options: [
            {
              label: t(
                'bo.taskManagement.bankDetailsPendingTask.type.bankDetails'
              ),
              value: 'bankDetails'
            }
          ]
        },
        action: {
          type: 'Select',
          required: true,
          getInitialValue: (modalData) => modalData?.action,
          options:
            taskNameToAvailableActions[TASK_NAME.BANK_DETAILS_PENDING_TASK]
              ?.options
        },
        ...getDescriptionFieldConfig()
      },
      [TASK_NAME.DAMAGE_COMPENSATION_PENDING_TASK]: {
        taskType: {
          getInitialValue: () => 'damageCompensationPendingTask',
          type: 'Select',
          disabled: true,
          required: true,
          options: [
            {
              label: t(
                'bo.taskManagement.bankDetailsPendingTask.type.damageCompensationPendingTask'
              ),
              value: 'damageCompensationPendingTask'
            }
          ]
        },
        action: {
          type: 'Select',
          required: true,
          getInitialValue: (modalData) => modalData?.action,
          options:
            taskNameToAvailableActions[
              TASK_NAME.DAMAGE_COMPENSATION_PENDING_TASK
            ]?.options
        },
        ...getDescriptionFieldConfig()
      },
      [TASK_NAME.CLAIM_PARTIAL_REFUND_PENDING_TASK]: {
        taskType: {
          getInitialValue: () => 'partialRefundPendingTask',
          type: 'Select',
          disabled: true,
          required: true,
          options: [
            {
              label: t(
                'bo.taskManagement.claimPartialRefundPendingTask.type.claimPartialRefund'
              ),
              value: 'partialRefundPendingTask'
            }
          ]
        },
        action: {
          type: 'Select',
          required: true,
          getInitialValue: (modalData) => modalData?.action,
          options:
            taskNameToAvailableActions[
              TASK_NAME.CLAIM_PARTIAL_REFUND_PENDING_TASK
            ]?.options
        },
        ...getDescriptionFieldConfig()
      },
      [TASK_NAME.EMAIL_REPLY_PENDING_TASK]: {
        taskType: {
          getInitialValue: () => 'task',
          type: 'Select',
          disabled: true,
          required: true,
          options: [
            {
              label: t('bo.taskManagement.completeTaskForm.type.task'),
              value: 'task'
            }
          ]
        },
        action: {
          type: 'Select',
          required: true,
          getInitialValue: (modalData) => modalData?.action,
          options:
            taskNameToAvailableActions[TASK_NAME.EMAIL_REPLY_PENDING_TASK]
              ?.options
        },
        ...getDescriptionFieldConfig()
      },
      [TASK_NAME.EMAIL_REPLY_REVIEW_TASK]: {
        taskType: {
          getInitialValue: () => 'task',
          type: 'Select',
          disabled: true,
          required: true,
          options: [
            {
              label: t('bo.taskManagement.completeTaskForm.type.task'),
              value: 'task'
            }
          ]
        },
        action: {
          type: 'Select',
          required: true,
          getInitialValue: (modalData) => modalData?.action,
          options:
            taskNameToAvailableActions[TASK_NAME.EMAIL_REPLY_REVIEW_TASK]
              ?.options
        },
        ...getDescriptionFieldConfig()
      },
      [TASK_NAME.VERIFICATION_CALL_TASK]: {
        taskType: {
          getInitialValue: () => 'task',
          type: 'Select',
          disabled: true,
          required: true,
          options: [
            {
              label: t('bo.taskManagement.completeTaskForm.type.task'),
              value: 'task'
            }
          ]
        },
        action: {
          type: 'Select',
          required: true,
          getInitialValue: (modalData) => modalData?.action,
          options:
            taskNameToAvailableActions[TASK_NAME.VERIFICATION_CALL_TASK]
              ?.options
        },
        ...getDescriptionFieldConfig()
      },
      [TASK_NAME.FINANCING_INTERNAL_APPROVAL_TASK]: {
        taskType: {
          getInitialValue: () => 'task',
          type: 'Select',
          disabled: true,
          required: true,
          options: [
            {
              label: t('bo.taskManagement.completeTaskForm.type.task'),
              value: 'task'
            }
          ]
        },
        action: {
          type: 'Select',
          required: true,
          getInitialValue: (modalData) => modalData?.action,
          options:
            taskNameToAvailableActions[
              TASK_NAME.FINANCING_INTERNAL_APPROVAL_TASK
            ]?.options,
          getAdditionalFields(action) {
            return getDescriptionFieldConfig({
              required: [
                RetailOrderBasedTaskAction.RequestDocs,
                RetailOrderBasedTaskAction.DocsSubmitted
              ].includes(action as RetailOrderBasedTaskAction)
            });
          }
        }
      },
      [TASK_NAME.FINANCING_APPLICATION_VALIDATION_TASK]: {
        action: {
          type: 'Select',
          required: true,
          getInitialValue: (modalData) => modalData?.action,
          getAdditionalFields: (
            action: RetailOrderBasedTaskAction,
            {
              orderFinanceCancelReasons,
              orderFinanceRejectReasons,
              externalFinancingOptionsValues
            }: Pick<
              FinancingApplicationValidationTaskProjection,
              | 'orderFinanceCancelReasons'
              | 'orderFinanceRejectReasons'
              | 'externalFinancingOptionsValues'
            >
          ) => {
            let fields = getDescriptionFieldConfig({
              required: [
                RetailOrderBasedTaskAction.RequestDocs,
                RetailOrderBasedTaskAction.DocsSubmitted
              ].includes(action)
            });

            if (
              action === RetailOrderBasedTaskAction.ApplicationSentToTheBank
            ) {
              fields = {
                ...fields,
                financingBankSubmission: {
                  required: true,
                  type: 'CheckboxGroup',
                  options: externalFinancingOptionsValues.map(
                    (externalFinancing) => ({
                      label: externalFinancing,
                      value: externalFinancing,
                      disabled: externalFinancingOptionsValues.length === 1
                    })
                  ),
                  getInitialValue: () =>
                    externalFinancingOptionsValues.length === 1
                      ? [externalFinancingOptionsValues[0]]
                      : []
                }
              };
            }

            if (action === RetailOrderBasedTaskAction.ApplicationCancelled) {
              fields = {
                ...fields,
                financingCancelReason: {
                  required: true,
                  type: 'Select',
                  options: orderFinanceCancelReasons.map(
                    ({ key, description }) => ({
                      label: description,
                      value: key
                    })
                  )
                }
              };
            }

            if (action === RetailOrderBasedTaskAction.ApplicationRejected) {
              fields = {
                ...fields,
                financingRejectReason: {
                  required: true,
                  type: 'Select',
                  options: orderFinanceRejectReasons.map(
                    ({ key, description }) => ({
                      label: description,
                      value: key
                    })
                  )
                }
              };
            }

            return fields;
          },
          options:
            taskNameToAvailableActions[
              TASK_NAME.FINANCING_APPLICATION_VALIDATION_TASK
            ]?.options
        }
      },
      [TASK_NAME.RETAIL_BANK_FINANCE_AMOUNT_VERIFICATION_TASK]: {
        financingAmount: {
          type: 'Input',
          disabled: true,
          label: () => t('bo.taskManagement.form.label.financingAmount'),
          getInitialValue: ({
            financingAmount,
            order
          }: TaskEntityOfType<'RetailFinancingAmountVerificationTaskProjection'>) =>
            formatCurrency({
              amountMinorUnits: financingAmount,
              currencyCode: order?.totalPriceGross?.currencyCode
            })
        },
        action: {
          type: 'Select',
          required: true,
          getInitialValue: (modalData) => modalData?.action,
          options:
            taskNameToAvailableActions[
              TASK_NAME.RETAIL_BANK_FINANCE_AMOUNT_VERIFICATION_TASK
            ]?.options
        },
        ...getDescriptionFieldConfig()
      },
      [TASK_NAME.TRADE_IN_PURCHASE_PENDING_TASK]: {
        stockNumber: {
          type: 'Custom',
          label: (data) =>
            t('bo.taskManagement.form.label.stockNumberWithValue', {
              stockNumber: data?.tradeInStockNumber
            }),
          component: HiddenControl
        },
        action: {
          type: 'Select',
          required: true,
          getInitialValue: (modalData) => modalData?.action,
          options: [
            {
              label: t(
                'bo.taskManagement.completeTaskForm.action.completedClose'
              ),
              value: RetailOrderBasedTaskAction.Completed
            },
            {
              label: t(
                'bo.taskManagement.completeTaskForm.action.canceledClose'
              ),
              value: RetailOrderBasedTaskAction.Canceled
            }
          ]
        },
        ...getDescriptionFieldConfig()
      },
      [TASK_NAME.SEPA_MANDATE_UPDATE_INFORM_HMCS_TASK]: {
        action: {
          type: 'Select',
          required: true,
          getInitialValue: (modalData) => modalData?.action,
          options: [
            {
              label: t(
                'bo.taskManagement.completeTaskForm.action.completedClose'
              ),
              value: RetailOrderBasedTaskAction.Completed
            }
          ]
        },
        ...getDescriptionFieldConfig()
      },
      [TASK_NAME.FINANCING_APPLICATION_PENDING_BANK_DECISION_TASK]: {
        action: {
          type: 'Select',
          required: true,
          getInitialValue: (modalData) => modalData?.action,
          getAdditionalFields(
            action: RetailOrderBasedTaskAction,
            {
              orderFinanceCancelReasons
            }: Pick<
              FinancingApplicationPendingBankDecisionTaskProjection,
              'orderFinanceCancelReasons'
            >
          ) {
            let fields = getDescriptionFieldConfig({
              required: [
                RetailOrderBasedTaskAction.RequestDocs,
                RetailOrderBasedTaskAction.DocsSubmitted
              ].includes(action)
            });

            if (action === RetailOrderBasedTaskAction.ApplicationCancelled) {
              fields = {
                ...fields,
                financingCancelReason: {
                  required: true,
                  type: 'Select',
                  options: orderFinanceCancelReasons.map(
                    ({
                      key,
                      description
                    }: {
                      key: string;
                      description: string;
                    }) => ({
                      label: description,
                      value: key
                    })
                  )
                }
              };
            } else if (
              action === RetailOrderBasedTaskAction.ApplicationRejected
            ) {
              fields = {
                ...fields,
                financingRejectReason: {
                  type: 'Custom',
                  component: HiddenControl,
                  showLabel: false,
                  getInitialValue: () =>
                    RetailOrderFinanceRejectReason.RejectedByBank
                }
              };
            }

            return fields;
          },
          options:
            taskNameToAvailableActions[
              TASK_NAME.FINANCING_APPLICATION_PENDING_BANK_DECISION_TASK
            ]?.options
        }
      }
    };

    return configByTaskName[taskType] ?? defaultConfig;
    // eslint-disable-next-line max-len
  }, [
    defaultConfig,
    externalFinancingBankDetailsPendingTaskConfig,
    overpaidRefundApprovalTaskConfig,
    taskNameToAvailableActions,
    orderShippingDetailsUpdateTaskConfig,
    t,
    cancellationRequestedTaskConfig,
    loadLicensePlateByOrderIdIsLoading,
    taskType,
    loadTaxBpmByOrderId,
    loadLicensePlateByOrderId
  ]);
}
