import { TextareaControlled } from '@retail/backoffice-ui';
import { Col } from 'antd';
import { Control, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TaskEntityModal } from '../types';

import { SelectField } from './SelectField';
import { Field } from './types';

import { CheckboxGroup } from '~components/form/checkbox-group';
import { RadioGroup } from '~components/form/RadioGroup';
import { DatePickerControlled } from '~components/form/datepicker';
import { InputControlled } from '~components/form/input';

const DEFAULT_MAX_LENGTH = 300;

export const ModalField = ({
  fieldConfig,
  optionName,
  control,
  modalData
}: {
  fieldConfig: Field;
  optionName: string;
  control: Control<FieldValues, object>;
  modalData: TaskEntityModal;
}) => {
  const { t } = useTranslation();

  const colSpan =
    (typeof fieldConfig.colSpan === 'function'
      ? fieldConfig.colSpan(modalData)
      : fieldConfig.colSpan) || 24;
  const showLabel =
    typeof fieldConfig.showLabel === 'function'
      ? fieldConfig.showLabel(modalData)
      : fieldConfig.showLabel ?? true;
  const label = showLabel
    ? typeof fieldConfig.label === 'function'
      ? fieldConfig.label(modalData)
      : t(`bo.taskManagement.form.label.${optionName}`)
    : null;

  const placeholder =
    typeof fieldConfig.placeholder === 'function'
      ? fieldConfig.placeholder(modalData)
      : t(`bo.taskManagement.form.placeholder.${optionName}`);

  switch (fieldConfig.type) {
    case 'Date':
      return (
        <Col key={optionName} span={colSpan}>
          <DatePickerControlled
            dateFormat="YYYY-MM-DD HH:mm"
            data-qa-selector={optionName}
            showTime={{
              minuteStep: 30
            }}
            label={label}
            placeholder={placeholder}
            required
            controllerProps={{
              name: optionName,
              control,
              rules: {
                required: {
                  value: true,
                  message: t('bo.taskManagement.form.validation.canNotBeEmpty')
                }
              }
            }}
          />
        </Col>
      );

    case 'Checkbox':
      return (
        <InputControlled
          key={optionName}
          type="checkbox"
          data-qa-selector="checkbox"
          checkboxLabel={label}
          controllerProps={{
            name: optionName,
            control
          }}
        />
      );

    case 'CheckboxGroup':
      const { getInitialValue } = fieldConfig;

      return (
        <Col key={optionName} span={colSpan}>
          <CheckboxGroup
            disabled={fieldConfig.disabled || !fieldConfig.options?.length}
            options={fieldConfig.options}
            label={label}
            placeholder={placeholder}
            required={fieldConfig.required}
            loading={fieldConfig.loading}
            controllerProps={{
              control,
              name: optionName,
              rules: {
                required: {
                  value: fieldConfig.required,
                  message: t('bo.taskManagement.form.validation.canNotBeEmpty')
                }
              },
              defaultValue: getInitialValue?.(modalData)
            }}
          />
        </Col>
      );

    case 'RadioGroup':
      return (
        <Col key={optionName} span={colSpan}>
          <RadioGroup
            disabled={fieldConfig.disabled || !fieldConfig.options?.length}
            options={fieldConfig.options}
            label={label}
            required={fieldConfig.required}
            controllerProps={{
              control,
              name: optionName,
              rules: {
                required: {
                  value: fieldConfig.required,
                  message: t('bo.taskManagement.form.validation.canNotBeEmpty')
                }
              },
              defaultValue: getInitialValue?.(modalData)
            }}
          />
        </Col>
      );

    case 'Custom': {
      const { component: CustomField, getInitialValue } = fieldConfig;

      return (
        <Col key={optionName} span={colSpan}>
          <CustomField
            label={label}
            data={fieldConfig}
            modalData={modalData}
            optionName={optionName}
            control={control}
            defaultValue={getInitialValue?.(modalData)}
          />
        </Col>
      );
    }

    case 'Textarea':
      return (
        <Col key={optionName} span={colSpan}>
          <TextareaControlled
            rows={5}
            maxLength={fieldConfig.maxLength ?? DEFAULT_MAX_LENGTH}
            minLength={fieldConfig.minLength}
            label={label}
            placeholder={placeholder}
            required={fieldConfig.required}
            controllerProps={{
              name: optionName,
              control,
              rules: {
                required: {
                  value: fieldConfig.required,
                  message: t('bo.taskManagement.form.validation.canNotBeEmpty')
                },
                minLength: {
                  value: fieldConfig.minLength,
                  message: t('bo.common.minLength', {
                    minLength: fieldConfig.minLength
                  })
                }
              }
            }}
          />
        </Col>
      );
    case 'Amount':
      return (
        <Col key={optionName} span={colSpan}>
          <InputControlled
            key={optionName}
            style={{ width: '100%' }}
            disabled={fieldConfig.disabled || fieldConfig.loading}
            required={fieldConfig.required}
            min={0}
            type="number"
            label={label}
            placeholder={fieldConfig.loading ? 'Loading...' : placeholder}
            controllerProps={{
              name: optionName,
              control,
              rules: {
                required: {
                  value: fieldConfig.required,
                  message: t('bo.taskManagement.form.validation.canNotBeEmpty')
                }
              }
            }}
          />
        </Col>
      );
    case 'Input':
      return (
        <Col key={optionName} span={colSpan}>
          <InputControlled
            key={optionName}
            disabled={fieldConfig.disabled || fieldConfig.loading}
            required={fieldConfig.required}
            label={label}
            placeholder={fieldConfig.loading ? 'Loading...' : placeholder}
            parse={fieldConfig.parse}
            format={fieldConfig.format}
            controllerProps={{
              name: optionName,
              control,
              rules: {
                required: {
                  value: fieldConfig.required,
                  message: t('bo.taskManagement.form.validation.canNotBeEmpty')
                },
                validate: fieldConfig.validate
              }
            }}
          />
        </Col>
      );
    case 'Select':
    default:
      return (
        <Col key={optionName} span={colSpan}>
          <SelectField
            mode={fieldConfig.mode}
            label={label}
            control={control}
            optionName={optionName}
            config={fieldConfig}
            taskData={modalData}
          />
        </Col>
      );
  }
};
