import { formatCurrency } from '@retail/backoffice-ui/src/utils/formatCurrency';
import { Table, TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';

import cn from './styles.less';

import { FinancingApplicationPendingBankDecisionTaskProjection } from '~apollo/gql-types';
import { formatDate } from '~helpers/date';
import { pluralize } from '~utils/i18n';

interface Props {
  data: FinancingApplicationPendingBankDecisionTaskProjection;
}

export function FinancingApplicationPendingBankDecisionSubRow({ data }: Props) {
  const { t } = useTranslation();
  const dataSource = [data];

  const columns: TableColumnsType<FinancingApplicationPendingBankDecisionTaskProjection> =
    [
      {
        title: t('bo.taskManagement.financingApplicationValidation.cashAmount'),
        dataIndex: 'cashAmount',
        key: 'cashAmount',
        render: (value) => (
          <span data-qa-selector="cashAmount">
            {value ? formatCurrency(value) : '-'}
          </span>
        )
      },
      {
        title: t(
          'bo.taskManagement.financingApplicationValidation.creditAmount'
        ),
        dataIndex: 'creditAmount',
        key: 'creditAmount',
        render: (value) => (
          <span data-qa-selector="creditAmount">
            {value ? formatCurrency(value) : '-'}
          </span>
        )
      },
      {
        title: t(
          'bo.taskManagement.financingApplicationValidation.creditPeriod'
        ),
        dataIndex: 'creditPeriod',
        key: 'creditPeriod',
        render: (creditPeriod) => (
          <span data-qa-selector="creditPeriod">
            {creditPeriod ? pluralize(creditPeriod, 'month') : '-'}
          </span>
        )
      },
      {
        title: t(
          'bo.taskManagement.financingApplicationValidation.dateOfApplicationSubmition'
        ),
        dataIndex: 'dateOfApplicationSubmition',
        key: 'dateOfApplicationSubmition',
        render: (value) => (
          <span data-qa-selector="dateOfApplicationSubmition">
            {value ? formatDate(value) : '-'}
          </span>
        )
      }
    ];

  return (
    <Table
      columns={columns}
      pagination={false}
      rowKey="financingApplicationPendingBankDecisionSubRow"
      dataSource={dataSource}
      bordered
      className={cn.table}
      style={{ width: '100%', border: '1px solid #616161' }}
    />
  );
}
