import { OptionModel } from '@retail/backoffice-ui';
import { map } from 'lodash/fp';

export default (formatLabel: (value: string) => string) => {
  return map<string, OptionModel>((item) => {
    const value = item;

    return {
      value,
      label: formatLabel(value)
    };
  });
};
