import { TableCell } from '@retail/backoffice-ui';
import { useExpectationTime } from '@retail/backoffice-ui/src/ExpectationTime/useExpectationTime';
import React from 'react';

const MS_IN_MINUTE = 60000;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  resolutionTime?: number;
}

export function DaysInStatus({ resolutionTime, ...props }: Props) {
  const value = useExpectationTime({
    dateRight: Number.isFinite(resolutionTime)
      ? new Date(Date.now() - resolutionTime * MS_IN_MINUTE).toString()
      : null
  });

  return <TableCell {...props}>{value}</TableCell>;
}
