import { TASK_NAME } from '../../constants';

import { BankDetailsSubRow } from './BankDetailsSubRow';
import { CancellationRequestedSubRow } from './CancellationRequestedSubRow';
import { CareCallSubRow } from './CareCallSubRow';
import { CarReturnedSubRow } from './CarReturnedSubRow';
import { CarReturnHandoverAppointmentSubRow } from './CarReturnHandoverAppointmentSubRow';
import { ClaimsProofSubRow } from './ClaimsProofSubRow';
import { DamageCompensationSubRow } from './DamageCompensationSubRow';
import { DocumentsReturnedDeregistrationSubRow } from './DocumentsReturnedDeregistrationSubRow';
import { EmailReplySubRow } from './EmailReplySubRow';
import { FinancingApplicationPendingBankDecisionSubRow } from './FinancingApplicationPendingBankDecisionSubRow';
import { FinancingApplicationValidationSubRow } from './FinancingApplicationValidationSubRow';
import { FinancingBillingAddressVerificationSubRow } from './FinancingBillingAddressVerificationSubRow';
import { FinancingInternalApprovalSubRow } from './FinancingInternalApprovalSubRow';
import { FinancingMonthlyPaymentDayUpdateSubRow } from './FinancingMonthlyPaymentDayUpdateSubRow';
import { FinancingSepaMandateVerificationSubRow } from './FinancingSepaMandateVerificationSubRow';
import { HappinessCallSubRow } from './HappinessCallSubRow';
import { OrderReadyForVerificationSubRow } from './OrderReadyForVerificationSubRow';
import { OverpaymentRefundApprovalSubRow } from './OverpaymentRefundApprovalSubRow';
import { OverpaymentRefundPendingSubRow } from './OverpaymentRefundPendingSubRow';
import { RefundSubRow } from './RefundSubRow';
import { SepaMandateUpdateInformHmcsSubRow } from './SepaMandateUpdateInformHmcsSubRow';
import { VerificationCallSubRow } from './VerificationCallSubRow';

import {
  CancellationRequestedTaskProjection,
  CareCallTaskProjection,
  CarReturnedTaskProjection,
  CarReturnHandoverAppointmentTaskProjection,
  ClaimProofPendingTaskProjection,
  DocumentsReturnedDeregistrationTaskProjection,
  EmailReplyPendingTaskProjection,
  FinancingApplicationPendingBankDecisionTaskProjection,
  FinancingApplicationValidationTaskProjection,
  HappinessCallTaskManagementProjection,
  LoadTaskOrderDetailsQuery,
  OrderProjection,
  OrderReadyForVerificationTaskProjection
} from '~apollo/gql-types';
import {
  isTaskOfType,
  TaskEntity,
  TaskEntityOfType
} from '~pages/TaskManagement/types';

interface Props {
  data: TaskEntity;
  qaSelector: string;
  detailsData: LoadTaskOrderDetailsQuery;
}

export function SubRow({ data, qaSelector, detailsData }: Props) {
  if (
    isTaskOfType(
      data,
      TASK_NAME.FINANCING_APPLICATION_PENDING_BANK_DECISION_TASK
    )
  ) {
    return (
      <div data-qa-selector={qaSelector}>
        <FinancingApplicationPendingBankDecisionSubRow
          data={data as FinancingApplicationPendingBankDecisionTaskProjection}
        />
      </div>
    );
  }

  if (isTaskOfType(data, TASK_NAME.FINANCING_APPLICATION_VALIDATION_TASK)) {
    return (
      <div data-qa-selector={qaSelector}>
        <FinancingApplicationValidationSubRow
          data={data as FinancingApplicationValidationTaskProjection}
        />
      </div>
    );
  }

  if (isTaskOfType(data, TASK_NAME.DOCUMENTS_RETURNED_DEREGISTRATION_TASK)) {
    return (
      <div data-qa-selector={qaSelector}>
        <DocumentsReturnedDeregistrationSubRow
          data={data as DocumentsReturnedDeregistrationTaskProjection}
        />
      </div>
    );
  }

  if (isTaskOfType(data, TASK_NAME.CARE_CALL_TASK)) {
    return (
      <div data-qa-selector={qaSelector}>
        <CareCallSubRow data={data as CareCallTaskProjection} />
      </div>
    );
  }

  if (isTaskOfType(data, TASK_NAME.CANCELLATION_REQUESTED)) {
    return (
      <div data-qa-selector={qaSelector}>
        <CancellationRequestedSubRow
          data={data as CancellationRequestedTaskProjection}
        />
      </div>
    );
  }

  if (isTaskOfType(data, TASK_NAME.CAR_RETURNED_TASK)) {
    return (
      <div data-qa-selector={qaSelector}>
        <CarReturnedSubRow data={data as CarReturnedTaskProjection} />
      </div>
    );
  }

  if (isTaskOfType(data, TASK_NAME.CAR_RETURN_HANDOVER_APPOINTMENT_TASK)) {
    return (
      <div data-qa-selector={qaSelector}>
        <CarReturnHandoverAppointmentSubRow
          data={data as CarReturnHandoverAppointmentTaskProjection}
        />
      </div>
    );
  }

  if (isTaskOfType(data, TASK_NAME.ORDER_READY_FOR_VERIFICATION_TASK)) {
    return (
      <div data-qa-selector={qaSelector}>
        <OrderReadyForVerificationSubRow
          data={data as OrderReadyForVerificationTaskProjection}
        />
      </div>
    );
  }

  if (
    [
      TASK_NAME.HAPPINESS_CALL_TASK,
      TASK_NAME.REPEAT_HAPPINESS_CALL_TASK
    ].includes(data?.taskType)
  ) {
    return (
      <div data-qa-selector={qaSelector}>
        <HappinessCallSubRow
          data={data as HappinessCallTaskManagementProjection}
          detailsData={detailsData}
        />
      </div>
    );
  }

  if (
    [
      TASK_NAME.CLAIM_PROOF_SUBMITTED_TASK,
      TASK_NAME.CLAIM_PROOF_PENDING_TASK
    ].includes(data?.taskType)
  ) {
    return (
      <div data-qa-selector={qaSelector}>
        <ClaimsProofSubRow data={data as ClaimProofPendingTaskProjection} />
      </div>
    );
  }

  if (isTaskOfType(data, TASK_NAME.BANK_DETAILS_PENDING_TASK)) {
    return (
      <div data-qa-selector={qaSelector}>
        <BankDetailsSubRow data={data} />
      </div>
    );
  }

  if (
    [
      TASK_NAME.DAMAGE_COMPENSATION_PENDING_TASK,
      TASK_NAME.CLAIM_PARTIAL_REFUND_PENDING_TASK
    ].includes(data?.taskType)
  ) {
    return (
      <div data-qa-selector={qaSelector}>
        <DamageCompensationSubRow
          data={data}
          order={detailsData?.order as OrderProjection}
        />
      </div>
    );
  }

  if (
    [
      TASK_NAME.EMAIL_REPLY_PENDING_TASK,
      TASK_NAME.EMAIL_REPLY_REVIEW_TASK
    ].includes(data?.taskType)
  ) {
    return (
      <div data-qa-selector={qaSelector}>
        <EmailReplySubRow data={data as EmailReplyPendingTaskProjection} />
      </div>
    );
  }

  if (isTaskOfType(data, TASK_NAME.VERIFICATION_CALL_TASK)) {
    return (
      <div data-qa-selector={qaSelector}>
        <VerificationCallSubRow data={data} />
      </div>
    );
  }

  if (isTaskOfType(data, TASK_NAME.FINANCING_INTERNAL_APPROVAL_TASK)) {
    return (
      <div data-qa-selector={qaSelector}>
        <FinancingInternalApprovalSubRow data={data} />
      </div>
    );
  }
  if (
    TASK_NAME.LICENSE_PLATE_UPDATE_PENDING_TASK === data?.taskType ||
    TASK_NAME.TAX_BPM_UPDATE_PENDING_TASK === data?.taskType
  ) {
    return null;
  }

  if (isTaskOfType(data, TASK_NAME.FINANCING_SEPA_MANDATE_VERIFICATION_TASK)) {
    return (
      <div data-qa-selector={qaSelector}>
        <FinancingSepaMandateVerificationSubRow data={data} />
      </div>
    );
  }

  if (
    isTaskOfType(data, TASK_NAME.FINANCING_BILLING_ADDRESS_VERIFICATION_TASK)
  ) {
    return (
      <div data-qa-selector={qaSelector}>
        <FinancingBillingAddressVerificationSubRow data={data} />
      </div>
    );
  }

  if (isTaskOfType(data, TASK_NAME.SEPA_MANDATE_UPDATE_INFORM_HMCS_TASK)) {
    return (
      <div data-qa-selector={qaSelector}>
        <SepaMandateUpdateInformHmcsSubRow data={data} />
      </div>
    );
  }

  if (isTaskOfType(data, TASK_NAME.FINANCING_MONTHLY_PAYMENT_DAY_UPDATE_TASK)) {
    return (
      <div data-qa-selector={qaSelector}>
        <FinancingMonthlyPaymentDayUpdateSubRow data={data} />
      </div>
    );
  }

  if (isTaskOfType(data, TASK_NAME.OVERPAYMENT_REFUND_APPROVAL)) {
    return (
      <div data-qa-selector={qaSelector}>
        <OverpaymentRefundApprovalSubRow data={data} />
      </div>
    );
  }

  if (isTaskOfType(data, TASK_NAME.OVERPAYMENT_REFUND_PENDING)) {
    return (
      <div data-qa-selector={qaSelector}>
        <OverpaymentRefundPendingSubRow data={data} />
      </div>
    );
  }

  return (
    <div data-qa-selector={qaSelector}>
      <RefundSubRow
        data={data as TaskEntityOfType<'RefundPendingTaskProjection'>}
      />
    </div>
  );
}
