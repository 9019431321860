import { unitsFromMinorToMajor } from '@retail/currency';
import { useCallback } from 'react';

import { useFetchOrderDetails } from './useFetchOrderDetails';

export const useLoadTaxBpmByOrderId = () => {
  const fetchOrderDetails = useFetchOrderDetails();
  return useCallback(async (orderId: string) => {
    if (orderId) {
      const { data: res } = await fetchOrderDetails(orderId);

      const taxBpmAmountMinorUnits =
        res?.order?.taxBPM?.amount?.amountMinorUnits;

      return taxBpmAmountMinorUnits
        ? unitsFromMinorToMajor(taxBpmAmountMinorUnits)
        : 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
