import { DELIVERY_BRANCH_TYPES } from '@retail/order-constants';

export const branchTypeOptions = [
  {
    value: DELIVERY_BRANCH_TYPES.DEFAULT.toString(),
    label: 'WKDA Branch'
  },
  {
    value: DELIVERY_BRANCH_TYPES.RETAIL.toString(),
    label: 'Retail Branch'
  }
];
