import { ApolloError } from '@apollo/client';
import { notification } from 'antd';
import { get } from 'lodash/fp';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useQueryBaseOptions() {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      onError: (error: ApolloError) => {
        const message = get('0.message')(error?.graphQLErrors);
        const description = t(message) || message;

        notification.error({
          message: 'Error',
          description
        });
      }
    }),
    [t]
  );
}
