import { FileTextTwoTone } from '@ant-design/icons';
import { Button, Table, TableColumnsType, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import cn from './styles.less';

import { TaskEntityOfType } from '~pages/TaskManagement/types';

type FinancingBillingAddressVerificationTask =
  TaskEntityOfType<'FinancingBillingAddressVerificationTaskProjection'>;

interface Props {
  data: FinancingBillingAddressVerificationTask;
}

export function FinancingBillingAddressVerificationSubRow({ data }: Props) {
  const { t } = useTranslation();

  const columns: TableColumnsType<FinancingBillingAddressVerificationTask> = [
    {
      key: 'name',
      title: t(
        'bo.taskManagement.financingBillingAddressVerificationTask.name'
      ),
      render: (_, { customerName }) => (
        <Typography.Text copyable>{customerName}</Typography.Text>
      )
    },
    {
      key: 'address',
      title: t(
        'bo.taskManagement.financingBillingAddressVerificationTask.address'
      ),
      render: (_, { financingBillingAddress: address }) => (
        <Typography.Text copyable data-qa-selector="address">
          {[
            address.street,
            address.houseNumber,
            address.zipcode,
            address.city
          ].join(' ')}
        </Typography.Text>
      )
    },

    {
      key: 'documents',
      title: t(
        'bo.taskManagement.financingBillingAddressVerificationTask.documents'
      ),
      render: (_, { documentsToVerify }) => (
        <div data-qa-selector="documentsToVerify">
          {documentsToVerify.map((doc) => (
            <Button
              key={doc.id}
              block
              type="link"
              icon={<FileTextTwoTone />}
              href={doc.fullUrl}
              target="_blank"
              style={{ textAlign: 'start' }}
            >
              {doc.fileName}
            </Button>
          ))}
        </div>
      )
    }
  ];

  return (
    <Table
      columns={columns}
      pagination={false}
      rowKey="id"
      dataSource={[data]}
      bordered
      className={cn.table}
      style={{ maxWidth: '1200px' }}
    />
  );
}
