import { ReactNode } from 'react';
import { generatePath } from 'react-router-dom';

import { TaskManagement } from './pages/TaskManagement';

interface RootPageModel {
  language: string;
}
interface OrderPageModel extends RootPageModel {
  orderId: string;
}
interface ClaimPageModel extends OrderPageModel {
  claimId: string;
}

export const ROOT = {
  ROUTE: '/:language',
  LINK: ({ language }: RootPageModel) => `/${language}`
};

export const ORDERS = {
  ROUTE: `${ROOT.ROUTE}/orders`,
  LINK: ({ language }: RootPageModel) => `${ROOT.LINK({ language })}/orders`
};

export const ORDER = {
  ROUTE: `${ORDERS.ROUTE}/:orderId`,
  LINK: ({ language, orderId }: OrderPageModel) =>
    `${ORDERS.LINK({ language })}/${orderId}`
};

export const ORDER_VERIFICATION_SUBTAB = {
  ROUTE: `${ORDER.ROUTE}/verification-subtab`,
  LINK: ({ language, orderId }: OrderPageModel) =>
    generatePath(ORDER_VERIFICATION_SUBTAB.ROUTE, { language, orderId })
};

export const ORDER_CLAIMS = {
  ROUTE: 'claims',
  ROUTE_RELATIVE: `${ORDER.ROUTE}/claims`,
  ROUTE_RELATIVE_DEEP: `${ORDER.ROUTE}/claims/*`,
  LINK: ({ language, orderId }: OrderPageModel) =>
    `${ORDER.LINK({ language, orderId })}/claims`
};

export const ORDER_CLAIMS_DETAILS = {
  ROUTE: ':claimId',
  ROUTE_RELATIVE: `${ORDER_CLAIMS.ROUTE}/:claimId`,
  ROUTE_RELATIVE_DEEP: `${ORDER_CLAIMS.ROUTE}/:claimId/*`,
  LINK: ({ language, orderId, claimId }: ClaimPageModel) =>
    `${ORDER_CLAIMS.LINK({ language, orderId })}/${claimId}`
};

export const ORDER_INVOICES_PAYMENTS = {
  ROUTE: `${ORDER.ROUTE}/invoices-payments`,
  LINK: ({ language, orderId }: OrderPageModel) =>
    `${ORDER.LINK({ language, orderId })}/invoices-payments`
};

export const ORDER_CONSUMER_FINANCING = {
  ROUTE: `${ORDER.ROUTE}/:orderId/consumer-financing`,
  LINK: ({ language, orderId }: OrderPageModel) =>
    `${ORDER.LINK({ language, orderId })}/consumer-financing`
};

export const TASK_MANAGEMENT = {
  ROUTE: `${ROOT.ROUTE}/task-management`,
  ROUTE_RELATIVE: `${ROOT.ROUTE}/task-management`,
  ROUTE_RELATIVE_DEEP: `${ROOT.ROUTE}/task-management/*`,
  LINK: ({ language }: RootPageModel) =>
    `${ROOT.LINK({ language })}/task-management`
};

// PREPARE ROUTES
interface Route {
  path: string;
  element: ReactNode | null;
}

const routes: Array<Route> = [
  {
    path: TASK_MANAGEMENT.ROUTE_RELATIVE_DEEP,
    element: <TaskManagement />
  }
];

export default routes;
