import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  PlayCircleOutlined
} from '@ant-design/icons';
import { useUserContext } from '@retail/backoffice-ui/src/UserContext';
import classNames from 'classnames';
import { cloneElement, useCallback } from 'react';

import cn from './styles.less';

import { TaskStatusColorCoding } from '~apollo/gql-types';
import { FEATURE } from '~constants/feature';
import { useFeatures } from '~hooks/useFeatures';
import { TaskEntity } from '~pages/TaskManagement/types';
import { TASK_STATUS } from '~pages/TaskManagement/constants';
import { IUserContext } from '~providers/UserProvider';

const COLOR_CODING_TO_ICON = {
  [TaskStatusColorCoding.Green]: <PlayCircleOutlined />,
  [TaskStatusColorCoding.Yellow]: <ClockCircleOutlined />,
  [TaskStatusColorCoding.Red]: <ExclamationCircleOutlined />
};

const COLOR_CODING_TO_CLASS_NAME: Record<TaskStatusColorCoding, string> = {
  [TaskStatusColorCoding.Green]: cn.greenRow,
  [TaskStatusColorCoding.Yellow]: cn.yellowRow,
  [TaskStatusColorCoding.Red]: cn.redRow,
  [TaskStatusColorCoding.Grey]: cn.grayRow
};

const TASK_STATUS_TO_ICON = {
  [TASK_STATUS.COMPLETE]: <CheckCircleOutlined />,
  [TASK_STATUS.INCOMPLETE]: <CloseCircleOutlined />
};

export function useColorCodingIconCallback() {
  const { country } = useUserContext<IUserContext>();
  const { isFeatureEnabled } = useFeatures({
    country,
    names: [FEATURE.SHOW_STATUS_ICONS_TASK_MANAGEMENT]
  });

  return useCallback(
    ({ statusColorCoding: { colorCoding }, taskStatus }: TaskEntity) => {
      const icon =
        COLOR_CODING_TO_ICON[
          colorCoding as keyof typeof COLOR_CODING_TO_ICON
        ] ??
        /**
         * @description in case it's {@link TaskStatusColorCoding.Grey} check {@link taskStatus}
         */
        (colorCoding === TaskStatusColorCoding.Grey
          ? TASK_STATUS_TO_ICON[taskStatus as TASK_STATUS]
          : null);
      const className = COLOR_CODING_TO_CLASS_NAME[colorCoding];

      return icon && isFeatureEnabled(FEATURE.SHOW_STATUS_ICONS_TASK_MANAGEMENT)
        ? cloneElement(icon, { className: classNames(cn.icon, className) })
        : null;
    },
    [isFeatureEnabled]
  );
}
