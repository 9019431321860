// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".X-T3lpbedCouONpXvguxHg\\=\\= {\n  display: flex;\n  align-items: center;\n  grid-gap: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/TaskManagement/Table/TaskActions/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,cAAA;AACF","sourcesContent":[".taskActionsWrap {\n  display: flex;\n  align-items: center;\n  grid-gap: 16px;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"taskActionsWrap": "X-T3lpbedCouONpXvguxHg=="
};
export default ___CSS_LOADER_EXPORT___;
