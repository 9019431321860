import { TableCell } from '@retail/backoffice-ui';

import { withAssignPermissions } from './withAssignPermissions';
import { withTaskStatusComplete } from './withTaskStatusComplete';

import { UserProjection } from '~apollo/gql-types';
import { getFullName } from '~utils/user';

interface Props {
  assignee: UserProjection;
  qaSelector: string;
}

export const AssignedTo = withTaskStatusComplete(
  withAssignPermissions<Props>(({ assignee, qaSelector }) => (
    <TableCell data-qa-selector={qaSelector}>{getFullName(assignee)}</TableCell>
  ))
);
