import { Sorts } from '@retail/gql-utils';

export const DEFAULT_PAGE = 1;
export const PAGE_SIZE = 20;
export const DEFAULT_PAGE_OPTIONS = ['10', '20'];

export const defaultCountry = 'de';

export const UNASSIGNED = 'unassigned';

export const DEFAULT_SORTING = Sorts.ask('createdOn');
