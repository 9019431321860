import { useCallback, useMemo } from 'react';

import { getOrderItems, Order, OrderItem } from './getOrderItems';

const getOrderItemName = (orderItem: OrderItem) => {
  if (orderItem.__typename === 'AdItemProjection') {
    return [
      orderItem.ad.vehicle.manufacturer,
      orderItem.ad.vehicle.model,
      orderItem.ad.vehicle.subType,
      orderItem.ad.vehicle.subTypeExtra
    ]
      .filter(Boolean)
      .join(' ');
  }
  if (orderItem.__typename === 'ProductItemProjection') {
    return orderItem.product.title;
  }
  if (orderItem.__typename === 'WarrantyItemProjection') {
    return orderItem.warranty.internalReferenceName;
  }
  if (orderItem.__typename === 'SecondaryWheelsItemProjection') {
    return orderItem.retailAdSecondaryWheels.internalRefName;
  }
  if (orderItem.__typename === 'CarRegistrationItemProjection') {
    return orderItem.carRegistration.internalReferenceName;
  }
  if (orderItem.__typename === 'VehiclePreparationItemProjection') {
    return orderItem.vehiclePreparationDTO.internalReferenceName;
  }
  return orderItem.deliveryLocationType;
};

export const useGetOrderItemName = (order?: Order) => {
  const orderItems = useMemo(
    () => (order ? getOrderItems(order) : []),
    [order]
  );

  return useCallback(
    (itemId: string) =>
      orderItems
        .filter((item) => item.id === itemId)
        .map(getOrderItemName)
        .at(0) ?? '',
    [orderItems]
  );
};
