import { OptionModel } from '@retail/backoffice-ui';
import { notification } from 'antd';
import { flow, get, map, sortBy } from 'lodash/fp';
import { Dispatch, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TASK_NAME } from '../constants';

import { UseTableColumnsProps, useTableColumns } from './useTableColumns';

import { useTasksManagerSearchOptionsQuery } from '~apollo/gql-types';
import { prepareCountryOptions } from '~helpers/fieldOptions/country';
import { AvailableActionsModel } from '~types/AvailableActions';

const DEFAULT_AVAILABLE_ACTIONS = {} as Record<
  TASK_NAME,
  AvailableActionsModel
>;

interface Props {
  expandedRowKeys: React.Key[];
  setExpandedRowKeys: Dispatch<React.Key[]>;
}

export function useTasksManagerSearchOptionsQueryAsync({
  expandedRowKeys,
  setExpandedRowKeys
}: Props) {
  const { t } = useTranslation();
  const { data, loading: optionsLoading } = useTasksManagerSearchOptionsQuery({
    onError: (error) => {
      notification.error({
        message: 'Error',
        description: get('0.message')(error?.graphQLErrors)
      });
    }
  });
  const {
    allowedToAssignTasks,
    allowedToSelfAssignTasks,
    allowedToUnAssignTasks
  } = data?.tasksManagerSearchOptions ?? {};

  const orderStatuses = useMemo(
    () =>
      data?.tasksManagerSearchOptions?.orderStates?.map(
        (value): OptionModel => ({
          value,
          label: t(`bo.taskManagement.orderStates.${value}`)
        })
      ),
    [t, data?.tasksManagerSearchOptions]
  );

  const orderRetailCountries = useMemo(
    () =>
      prepareCountryOptions(
        data?.tasksManagerSearchOptions?.orderRetailCountries
      ),
    [data?.tasksManagerSearchOptions]
  );

  const taskNames = useMemo(
    () =>
      sortBy<OptionModel>(['label'])(
        data?.tasksManagerSearchOptions?.taskNames?.map((value) => ({
          value,
          label: t(`bo.taskManagement.taskNames.${value}`)
        }))
      ),
    [t, data?.tasksManagerSearchOptions]
  );

  const assignees = useMemo(
    () =>
      flow(
        get(['taskManagerAssignees']),
        map(
          ({
            userId,
            userName,
            taskNames,
            countryAccess,
            enabled
          }): UseTableColumnsProps['assignees'][number] => ({
            value: userId,
            label: `${userName} ${
              enabled ? '' : t('bo.taskManagement.search.label.inactive')
            }`,
            taskNames,
            countryAccess
          })
        )
      )(data?.tasksManagerSearchOptions),
    [t, data?.tasksManagerSearchOptions]
  );

  const taskNameToAvailableActions = useMemo(
    () =>
      data?.tasksManagerSearchOptions?.taskSpecificActions?.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.taskDefinitionKey]: {
            ...curr,
            options: curr.updateActions.concat(curr.completeActions).map(
              (value: string) =>
                ({
                  value,
                  label: t(
                    `bo.taskManagement.completeTaskForm.action.option.${value}`
                  )
                } as OptionModel)
            )
          }
        }),
        {} as Record<TASK_NAME, AvailableActionsModel>
      ) ?? DEFAULT_AVAILABLE_ACTIONS,
    [data?.tasksManagerSearchOptions?.taskSpecificActions, t]
  );

  const {
    paymentTypes,
    paymentStatuses,
    financingStatuses,
    allowedToCommentTasks,
    taskActions = [],
    deliveryOptions
  } = data?.tasksManagerSearchOptions ?? {};

  const deliveryOptionsValues = useMemo(
    () =>
      deliveryOptions?.map((value) => ({
        value,
        label: t(`bo.orderOverview.deliveryOptions.${value}`)
      })),
    [deliveryOptions, t]
  );

  const isCommentAllowedByTaskTypeMap = useMemo(() => {
    return allowedToCommentTasks?.reduce<Record<string, boolean>>(
      (acc, taskType) => {
        acc[taskType] = true;
        return acc;
      },
      {}
    );
  }, [allowedToCommentTasks]);

  const paymentTypesOptions = paymentTypes?.map((value) => ({
    value,
    label: t(`bo.taskManagement.paymentTypes.${value}`)
  }));
  const financingStatusesOptions = financingStatuses?.map((value) => ({
    value,
    label: t(`bo.taskManagement.financingStatuses.${value}`)
  }));
  const paymentStatusesOptions = paymentStatuses?.map((value) => ({
    value,
    label: t(`bo.taskManagement.paymentStatuses.${value}`)
  }));
  const taskActionsOptions = taskActions?.map(
    (value: string) =>
      ({
        value,
        label: t(`bo.taskManagement.completeTaskForm.action.${value}`)
      } as OptionModel)
  );

  const onCommentsIndicatorClick = useCallback(
    (taskId: string) => {
      if (expandedRowKeys.includes(taskId)) {
        return setExpandedRowKeys(
          expandedRowKeys.filter((key) => key !== taskId)
        );
      }

      return setExpandedRowKeys([...expandedRowKeys, taskId]);
    },
    [expandedRowKeys, setExpandedRowKeys]
  );

  const columns = useTableColumns({
    assignees,
    optionsLoading,
    taskNameToAvailableActions,
    allowedToAssignTasks,
    allowedToSelfAssignTasks,
    allowedToUnAssignTasks,
    onCommentsIndicatorClick
  });

  return useMemo(
    () =>
      ({
        columns,
        orderStatuses,
        orderRetailCountries,
        taskNames,
        assignees,
        optionsLoading,
        paymentStatuses: paymentStatusesOptions,
        financingStatuses: financingStatusesOptions,
        paymentTypes: paymentTypesOptions,
        taskActions: taskActionsOptions,
        isCommentAllowedByTaskTypeMap,
        taskNameToAvailableActions,
        deliveryOptions: deliveryOptionsValues
      } as const),
    [
      assignees,
      columns,
      orderRetailCountries,
      orderStatuses,
      taskNames,
      optionsLoading,
      paymentTypesOptions,
      paymentStatusesOptions,
      financingStatusesOptions,
      taskActionsOptions,
      isCommentAllowedByTaskTypeMap,
      taskNameToAvailableActions,
      deliveryOptionsValues
    ]
  );
}
