import { Col, ColProps } from 'antd';
import { PropsWithChildren } from 'react';

export function FieldCol({ children, ...props }: PropsWithChildren<ColProps>) {
  return (
    <Col xs={24} sm={12} lg={6} {...props}>
      {children}
    </Col>
  );
}
