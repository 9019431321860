import { Row } from 'antd';
import { memo, Fragment } from 'react';
import { Control, FieldValues, useWatch } from 'react-hook-form';

import { TaskEntityModal } from '../types';

import { ModalField } from './ModalField';
import { CompleteTaskDataByType } from './types';

const FormFields = ({
  fieldsConfig,
  control,
  modalData
}: {
  fieldsConfig: CompleteTaskDataByType;
  modalData: TaskEntityModal;
  control: Control<FieldValues>;
}) => {
  const watchedValues = useWatch({ control });
  return (
    <>
      {Object.entries(fieldsConfig ?? {}).map(([fieldName, fieldConfig]) => {
        const { getIsVisible = () => true } = fieldConfig;
        const isVisible = getIsVisible(modalData);

        if (!isVisible) {
          return null;
        }

        const additionalFields =
          fieldConfig.getAdditionalFields?.(
            watchedValues[fieldName],
            modalData
          ) || {};

        const fields = Object.entries(additionalFields).map(
          ([name, fieldConfig]) => {
            const additionalFields =
              fieldConfig.getAdditionalFields?.(
                watchedValues[name],
                modalData
              ) ?? {};
            const hasAdditionalFields =
              Object.keys(additionalFields).length > 0;

            return (
              <Fragment key={name}>
                <ModalField
                  fieldConfig={fieldConfig}
                  optionName={name}
                  control={control}
                  modalData={modalData}
                />
                {hasAdditionalFields ? (
                  <FormFields
                    fieldsConfig={additionalFields}
                    modalData={modalData}
                    control={control}
                  />
                ) : null}
              </Fragment>
            );
          }
        );

        return (
          <Fragment key={fieldName}>
            <ModalField
              fieldConfig={fieldConfig}
              optionName={fieldName}
              control={control}
              modalData={modalData}
            />
            {fields}
          </Fragment>
        );
      })}
    </>
  );
};

interface TaskProps {
  taskData: CompleteTaskDataByType;
  control: Control<FieldValues>;
  modalData?: TaskEntityModal;
}

export const TaskForm = memo<TaskProps>(({ control, taskData, modalData }) => {
  return (
    <div>
      <Row gutter={16}>
        <FormFields
          fieldsConfig={taskData}
          control={control}
          modalData={modalData}
        />
      </Row>
    </div>
  );
});
