import { formatCurrency } from '@retail/backoffice-ui/src/utils/formatCurrency';
import { Table, TableColumnsType } from 'antd';
import { isEqual, isNil } from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import cn from './styles.less';

import { formatDateTime } from '~helpers/date';
import { TaskEntityOfType } from '~pages/TaskManagement/types';

type TaskEntity = TaskEntityOfType<'RefundPendingTaskProjection'>;

interface Props {
  data: TaskEntity;
}

export function RefundSubRow({ data }: Props) {
  const { t } = useTranslation();

  const columns: TableColumnsType<TaskEntity> = [
    {
      title: t('bo.taskManagement.refundTable.carHandoverOnDate'),
      dataIndex: 'cashOutReason',
      key: 'cashOutReason',
      render: (value) => (
        <span data-qa-selector="cashOutReason">
          {isEqual(value, 'null - null') || isNil(value) ? '-' : value}
        </span>
      )
    },
    {
      title: t('bo.taskManagement.refundTable.cancellationReasonComment'),
      dataIndex: 'cancellationReasonComment',
      key: 'cancellationReasonComment',
      render: (value) => (
        <span data-qa-selector="cancellationReasonComment">{value || '-'}</span>
      )
    },
    {
      title: t('bo.taskManagement.refundTable.cashOutRequestDate'),
      dataIndex: 'cashOutRequestDate',
      key: 'cashOutRequestDate',
      render: (value) => (
        <span data-qa-selector="cashOutRequestDate">
          {value ? formatDateTime(value) : '-'}
        </span>
      )
    },
    {
      title: t('bo.taskManagement.refundTable.refundAmountOnTaskCreation'),
      dataIndex: 'refundAmountOnTaskCreation',
      key: 'refundAmountOnTaskCreation',
      render: (value) => {
        const formattedCurrency = value && formatCurrency(value);
        return (
          <span data-qa-selector="refundAmountOnTaskCreation">
            {formattedCurrency || '-'}
          </span>
        );
      }
    },
    {
      title: t('bo.taskManagement.refundTable.refundAmount'),
      dataIndex: 'refundAmount',
      key: 'refundAmount',
      render: (value) => {
        const formattedCurrency = value && formatCurrency(value);
        return (
          <span data-qa-selector="refundAmount">
            {formattedCurrency || '-'}
          </span>
        );
      }
    },
    {
      title: t('bo.taskManagement.refundTable.isOracle'),
      dataIndex: 'isOracle',
      key: 'isOracle',
      render: (_, { order }) => (
        <span data-qa-selector="isOracle">
          {order?.isOracle ? 'yes' : 'no'}
        </span>
      )
    }
  ];

  return (
    <Table
      columns={columns}
      pagination={false}
      rowKey="cashOutReason"
      dataSource={[data]}
      bordered
      className={cn.table}
      style={{ width: '100%', border: '1px solid #616161' }}
    />
  );
}
