import { Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { TaskEntityOfType } from '~pages/TaskManagement/types';

type TaskType =
  TaskEntityOfType<'FinancingMonthlyPaymentDayUpdateTaskProjection'>;

const valueLabels = {
  1: 'bo.taskManagement.financingMonthlyPaymentDayUpdateTask.day.value.1',
  15: 'bo.taskManagement.financingMonthlyPaymentDayUpdateTask.day.value.15'
};

export const FinancingMonthlyPaymentDayUpdateSubRow = ({
  data
}: {
  data: TaskType;
}) => {
  const { t } = useTranslation();
  const day = data.monthlyPaymentDay;

  const label = t(
    'bo.taskManagement.financingMonthlyPaymentDayUpdateTask.day.label'
  );
  return (
    <Space
      style={{
        gap: '8px',
        padding: '12px',
        background: '#ffff',
        fontWeight: 'bold'
      }}
    >
      {`${label}:`}
      {day in valueLabels
        ? t(valueLabels[day as keyof typeof valueLabels])
        : day}
    </Space>
  );
};
