// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iu3W2DsYkdktjTzJrs7l4g\\=\\= {\n  display: flex;\n  cursor: pointer;\n  transition: opacity 0.3s;\n}\n.iu3W2DsYkdktjTzJrs7l4g\\=\\=:hover,\n.iu3W2DsYkdktjTzJrs7l4g\\=\\=:focus {\n  opacity: 0.7;\n}\n.iu3W2DsYkdktjTzJrs7l4g\\=\\= ._7qhlPValkVSU4TMua4ZgbA\\=\\= {\n  width: 100%;\n  min-width: 35px;\n  min-height: 25px;\n  margin: auto;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border: 1px solid #616161;\n  padding: 0 3px;\n  color: #616161;\n  border-radius: 100px;\n}\n.iu3W2DsYkdktjTzJrs7l4g\\=\\= ._7qhlPValkVSU4TMua4ZgbA\\=\\= .Yfvu4N2R-Tsud8BmRhuSGg\\=\\= {\n  margin-left: 2px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/TaskManagement/Table/TaskActions/CommentsIndicator/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,wBAAA;AACF;AACE;;EAEE,YAAA;AACJ;AARA;EAWI,WAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,yBAAA;EACA,cAAA;EACA,cAAA;EACA,oBAAA;AAAJ;AArBA;EAwBM,gBAAA;AAAN","sourcesContent":[".commentsWrapper {\n  display: flex;\n  cursor: pointer;\n  transition: opacity 0.3s;\n\n  &:hover,\n  &:focus {\n    opacity: 0.7;\n  }\n\n  .comments {\n    width: 100%;\n    min-width: 35px;\n    min-height: 25px;\n    margin: auto;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border: 1px solid #616161;\n    padding: 0 3px;\n    color: #616161;\n    border-radius: 100px;\n\n    .count {\n      margin-left: 2px;\n    }\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commentsWrapper": "iu3W2DsYkdktjTzJrs7l4g==",
	"comments": "_7qhlPValkVSU4TMua4ZgbA==",
	"count": "Yfvu4N2R-Tsud8BmRhuSGg=="
};
export default ___CSS_LOADER_EXPORT___;
