import { DELIVERY_OPTIONS } from '@retail/order-constants';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CompleteTaskDataByType } from '../../../types';

import { branchTypeOptions } from './branchTypeOptions';
import { deliveryOptions } from './deliveryOptions';
import { getBranchLabel } from './getBranchLabel';
import { useGetBranchById } from './useGetBranchById';
import { useLoadOrder } from './useLoadOrder';

import { RetailOrderBasedTaskAction } from '~apollo/gql-types';
import { TaskEntity, TaskEntityOfType } from '~pages/TaskManagement/types';
import { toLocalizedCountry } from '~utils/i18n';

const isBranchPickup = (row: TaskEntity): boolean =>
  row.__typename === 'OrderShippingDetailsUpdateTaskProjection'
    ? Boolean(row.branchId)
    : false;

// A hacky function to extract streetName and houseNumber from streetAddress
// that comes from BE in next format: `${streetName} ${houseNumber}`
const parseStreetAddress = (streetAddress = '') => {
  const addressParts = streetAddress.split(/\s+/);

  let streetName = streetAddress;
  let houseNumber = '';
  const houseNumberIsPresent = !Number.isNaN(
    parseInt(addressParts[addressParts.length - 1])
  );
  if (houseNumberIsPresent) {
    houseNumber = addressParts[addressParts.length - 1];
    streetName = addressParts.slice(0, -1).join(' ');
  }
  return { streetName, houseNumber };
};

export const useOrderShippingDetailsUpdateTaskConfig =
  (): CompleteTaskDataByType => {
    const { t } = useTranslation();
    const loadOrder = useLoadOrder();
    const getBranchById = useGetBranchById();

    return useMemo<CompleteTaskDataByType>(
      () => ({
        vehicleShippingType: {
          getInitialValue: async (
            row: TaskEntityOfType<'OrderShippingDetailsUpdateTaskProjection'>
          ) => {
            if (row.branchId) {
              return DELIVERY_OPTIONS.NEAREST_BRANCH;
            }
            if (row.orderId) {
              return (await loadOrder(row.orderId))?.deliveryDetails
                ?.retailDeliveryOption;
            }
          },
          type: 'Select',
          disabled: true,
          required: true,
          options: deliveryOptions
        },
        street: {
          disabled: true,
          type: 'Input',
          getInitialValue: (
            row: TaskEntityOfType<'OrderShippingDetailsUpdateTaskProjection'>
          ) => parseStreetAddress(row?.addressUpdateDetails?.street).streetName,
          getIsVisible: (row) => !isBranchPickup(row),
          colSpan: 12
        },
        houseNumber: {
          disabled: true,
          type: 'Input',
          colSpan: 12,
          getInitialValue: (
            row: TaskEntityOfType<'OrderShippingDetailsUpdateTaskProjection'>
          ) =>
            parseStreetAddress(row?.addressUpdateDetails?.street).houseNumber,
          getIsVisible: (row) => !isBranchPickup(row)
        },
        city: {
          disabled: true,
          type: 'Input',
          showLabel: (row) => !isBranchPickup(row),
          colSpan: (row) => (isBranchPickup(row) ? 24 : 12),
          getIsVisible: (row) => Boolean(row),
          getInitialValue: async (
            row: TaskEntityOfType<'OrderShippingDetailsUpdateTaskProjection'>
          ) =>
            isBranchPickup(row)
              ? row.branchId
                ? (await getBranchById(row.branchId)).city
                : null
              : row?.addressUpdateDetails?.city
        },
        type: {
          disabled: true,
          type: 'Select',
          options: branchTypeOptions,
          showLabel: false,
          getIsVisible: (row) => isBranchPickup(row),
          getInitialValue: async (
            row: TaskEntityOfType<'OrderShippingDetailsUpdateTaskProjection'>
          ) =>
            row.branchId
              ? (await getBranchById(row.branchId)).type.toString()
              : ''
        },
        branch: {
          disabled: true,
          type: 'Input',
          showLabel: false,
          getIsVisible: (row) => isBranchPickup(row),
          getInitialValue: async (
            row: TaskEntityOfType<'OrderShippingDetailsUpdateTaskProjection'>
          ) => {
            if (row.branchId) {
              const branch = await getBranchById(row.branchId);
              return getBranchLabel(branch);
            }
          }
        },
        country: {
          disabled: true,
          type: 'Input',
          colSpan: 6,
          getInitialValue: (
            row: TaskEntityOfType<'OrderShippingDetailsUpdateTaskProjection'>
          ) =>
            row?.addressUpdateDetails?.country
              ? toLocalizedCountry(row?.addressUpdateDetails?.country)
              : '',
          getIsVisible: (row) => !isBranchPickup(row)
        },
        zipCode: {
          disabled: true,
          type: 'Input',
          colSpan: 6,
          getInitialValue: (
            row: TaskEntityOfType<'OrderShippingDetailsUpdateTaskProjection'>
          ) => row?.addressUpdateDetails?.zipcode,
          getIsVisible: (row) => !isBranchPickup(row)
        },
        action: {
          type: 'Select',
          required: true,
          getInitialValue: (row) =>
            row.action || RetailOrderBasedTaskAction.Completed,
          options: [
            {
              label: t(
                'bo.taskManagement.completeTaskForm.action.completedClose'
              ),
              value: RetailOrderBasedTaskAction.Completed
            },
            {
              label: t(
                'bo.taskManagement.completeTaskForm.action.canceledClose'
              ),
              value: RetailOrderBasedTaskAction.Canceled
            }
          ]
        },
        comment: {
          type: 'Textarea'
        }
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [t]
    );
  };
