import { useCheckPermissions } from '@retail/backoffice-ui/src/Permission';
import { Dispatch } from 'react';

import { ActionsButton, ActionsButtonProps } from './ActionsButton';
import { CommentsIndicator } from './CommentsIndicator';
import cn from './styles.less';

import { DOMAINS, PERMISSIONS } from '~constants/permissions';
import { TASK_STATUS } from '~pages/TaskManagement/constants';

type Props = ActionsButtonProps & {
  qaSelector: string;
  onCommentsIndicatorClick: Dispatch<string>;
};

export function TaskActions({ onCommentsIndicatorClick, ...props }: Props) {
  const { id, qaSelector, taskStatus, commentsCount } = props;

  const hasPermissions = useCheckPermissions({
    allow: [PERMISSIONS.MUTATION_TASK_MANAGER_COMPLETE_TASK],
    domain: DOMAINS.TASK_MANAGEMENT
  });

  return (
    <div className={cn.taskActionsWrap} data-qa-selector={qaSelector}>
      <CommentsIndicator
        taskId={id}
        qaSelector="tableCommentsQuantity"
        commentsCount={commentsCount}
        onCommentsIndicatorClick={onCommentsIndicatorClick}
      />
      {taskStatus === TASK_STATUS.INCOMPLETE && !hasPermissions.isDenied && (
        <ActionsButton id={id} {...props} />
      )}
    </div>
  );
}
