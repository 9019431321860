import { FileTextTwoTone } from '@ant-design/icons';
import { Button, Table, TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';

import cn from './styles.less';

import { TaskEntityOfType } from '~pages/TaskManagement/types';

type TaskType = TaskEntityOfType<'SepaMandateUpdateInformHMCSTaskProjection'>;

export const SepaMandateUpdateInformHmcsSubRow = ({
  data
}: {
  data: TaskType;
}) => {
  const { t } = useTranslation();

  const columns: TableColumnsType<TaskType> = [
    {
      key: 'iban',
      title: t('bo.taskManagement.sepaMandateUpdateInformHmcsTask.iban'),
      width: '33%',
      render: (_, { sepaMandate }) => (
        <span data-qa-selector="iban">{sepaMandate?.iban}</span>
      )
    },
    {
      key: 'accountHolder',
      title: t(
        'bo.taskManagement.sepaMandateUpdateInformHmcsTask.accountHolder'
      ),
      width: '33%',
      render: (_, { sepaMandate }) => (
        <span data-qa-selector="accountHolder">
          {sepaMandate?.accountHolder}
        </span>
      )
    },
    {
      key: 'documents',
      title: t('bo.taskManagement.sepaMandateUpdateInformHmcsTask.documents'),
      width: '33%',
      render: (_, { sepaMandateDocument: doc }) => (
        <div data-qa-selector="sepaMandateDocument">
          {doc && (
            <Button
              key={doc.id}
              block
              type="link"
              icon={<FileTextTwoTone />}
              href={doc.fullUrl}
              target="_blank"
              style={{ textAlign: 'start', wordBreak: 'break-all' }}
            >
              {t(
                'bo.taskManagement.sepaMandateUpdateInformHmcsTask.sepaDocument'
              )}
            </Button>
          )}
        </div>
      )
    }
  ];

  return (
    <Table
      columns={columns}
      pagination={false}
      rowKey="id"
      dataSource={[data]}
      bordered
      className={cn.table}
      style={{ maxWidth: '1200px' }}
    />
  );
};
