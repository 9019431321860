import { formatCurrency } from '@retail/backoffice-ui/src/utils/formatCurrency';
import { Table, TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';

import cn from './styles.less';

import { FinancingApplicationValidationTaskProjection } from '~apollo/gql-types';
import { formatDate } from '~helpers/date';

interface Props {
  data: FinancingApplicationValidationTaskProjection;
}

export function FinancingApplicationValidationSubRow({ data }: Props) {
  const { t } = useTranslation();
  const dataSource = [data];

  const columns: TableColumnsType<FinancingApplicationValidationTaskProjection> =
    [
      {
        title: t('bo.taskManagement.financingApplicationValidation.cashAmount'),
        dataIndex: 'cashAmount',
        key: 'cashAmount',
        render: (value) => (
          <span data-qa-selector="cashAmount">
            {value ? formatCurrency(value) : '-'}
          </span>
        )
      },
      {
        title: t(
          'bo.taskManagement.financingApplicationValidation.creditAmount'
        ),
        dataIndex: 'creditAmount',
        key: 'creditAmount',
        render: (value) => (
          <span data-qa-selector="creditAmount">
            {value ? formatCurrency(value) : '-'}
          </span>
        )
      },
      {
        title: t(
          'bo.taskManagement.financingApplicationValidation.dateOfApplicationReadyForReview'
        ),
        dataIndex: 'dateOfApplicationValidation',
        key: 'dateOfApplicationValidation',
        render: (value) => (
          <span data-qa-selector="dateOfApplicationValidation">
            {value ? formatDate(value) : '-'}
          </span>
        )
      },
      {
        title: t(
          'bo.taskManagement.financingApplicationValidation.bankSubmittedTo'
        ),
        dataIndex: 'financingBankSubmissions',
        key: 'financingBankSubmissions',
        render: (value) => (
          <span data-qa-selector="financingBankSubmissions">
            {value?.length ? value.join(' - ') : '-'}
          </span>
        )
      },
      {
        title: t('bo.taskManagement.financingApplicationValidation.stockId'),
        dataIndex: 'stockNumber',
        key: 'stockNumber',
        render: (value) => (
          <span data-qa-selector="stockNumber">{value || '-'}</span>
        )
      }
    ];

  return (
    <Table
      columns={columns}
      pagination={false}
      rowKey="financingApplicationValidationSubRow"
      dataSource={dataSource}
      bordered
      className={cn.table}
      style={{ width: '100%', border: '1px solid #616161' }}
    />
  );
}
