import { Table, TableColumnsType, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import cn from './styles.less';

import { EmailReplyPendingTaskProjection } from '~apollo/gql-types';
import { formatDateTime } from '~helpers/date';
import { i18nDetectLng } from '~i18n/utils';
import * as ROUTES from '~routes';

const { Link } = Typography;

interface Props {
  data: EmailReplyPendingTaskProjection;
}

export function EmailReplySubRow({ data }: Props) {
  const { t } = useTranslation();

  const columns: TableColumnsType<EmailReplyPendingTaskProjection> = [
    {
      title: t('bo.taskManagement.emailReplyTask.customerName'),
      dataIndex: 'customerName',
      key: 'customerName',
      render: (value) => (
        <span data-qa-selector="customerName">{value || '-'}</span>
      )
    },
    {
      title: t('bo.taskManagement.emailReplyTask.emailTemplate'),
      dataIndex: 'templateName',
      key: 'templateName',
      render: (value) => (
        <span data-qa-selector="templateName">{value || '-'}</span>
      )
    },
    {
      title: t('bo.taskManagement.emailReplyTask.emailSentOn'),
      dataIndex: 'sentOn',
      key: 'sentOn',
      render: (sentOn, { orderId }) => (
        <span data-qa-selector="sentOn">
          <Link
            href={ROUTES.ORDER.LINK({
              language: i18nDetectLng(),
              orderId
            })}
            data-qa-selector="sentOn"
          >
            {sentOn ? formatDateTime(sentOn) : '-'}
          </Link>
        </span>
      )
    },
    {
      title: t('bo.taskManagement.emailReplyTask.paymentType'),
      dataIndex: 'paymentType',
      key: 'paymentType',
      render: (value) => (
        <span data-qa-selector="paymentType">{value || '-'}</span>
      )
    },
    {
      title: t('bo.taskManagement.emailReplyTask.handoverDate'),
      dataIndex: 'carHandoverOnDate',
      key: 'handoverDate',
      render: (value) => (
        <span data-qa-selector="handoverDate">
          {value ? formatDateTime(value) : '-'}
        </span>
      )
    },
    {
      title: t('bo.taskManagement.emailReplyTask.paymentStatus'),
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      render: (value) => (
        <span data-qa-selector="paymentStatus">{value || '-'}</span>
      )
    }
  ];

  return (
    <Table
      columns={columns}
      pagination={false}
      rowKey="emailReply"
      dataSource={[data]}
      bordered
      className={cn.table}
      style={{ width: '100%', border: '1px solid #616161' }}
    />
  );
}
