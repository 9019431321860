/* eslint-disable no-unused-vars */
import { flow, keys, map } from 'lodash/fp';

export const PAGE_SIZE = 20;

export enum TASK_STATUS {
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE'
}

export enum FLOW_VERSION {
  V1 = 'V1',
  V2 = 'V2',
  V3 = 'V3'
}

export const TASK_STATUS_OPTIONS = flow(
  keys,
  map((value) => ({ value, label: value }))
)(TASK_STATUS);

export const FLOW_VERSION_OPTIONS = Object.entries(FLOW_VERSION).map(
  ([label, value]) => ({ value, label })
);

export const NO_ASSIGNEE = 'NO_ASSIGNEE';
export const NO_TASK_ACTION = 'NO_TASK_ACTION';

export enum TOPIC {
  CATEGORY_DELIVERY_FULFILLMENT = 'TC_DELIVERY_FULFILLMENT',
  CATEGORY_PAYMENTS_FINANCING = 'TC_PAYMENTS_FINANCING',
  CATEGORY_CANCELLATIONS = 'TC_CANCELLATIONS',
  CATEGORY_SALES_INQUIRY_ORDER = 'TC_SALES_INQUIRY_ORDER',
  CATEGORY_CLAIMS = 'TC_CLAIMS',
  CATEGORY_BRANCH = 'TC_BRANCH',
  CATEGORY_AUTOHERO_ISSUES = 'TC_AUTOHERO_ISSUES',
  CATEGORY_OTHER = 'TC_OTHER'
}

export enum TASK_NAME {
  REFUND_PENDING_TASK = 'REFUND_PENDING_TASK',
  CASH_OUT_REFUND_APPROVAL_PENDING_TASK = 'CASH_OUT_REFUND_APPROVAL_PENDING_TASK',
  OVERPAYMENT_REFUND_APPROVAL = 'OVERPAYMENT_REFUND_APPROVAL_TASK',
  OVERPAYMENT_REFUND_PENDING = 'OVERPAYMENT_REFUND_PENDING_TASK',
  ACCOUNTING_DATE_PENDING_TASK = 'ACCOUNTING_DATE_PENDING_TASK',
  CREDIT_NOTE_NUMBER_PENDING_TASK = 'CREDIT_NOTE_NUMBER_PENDING_TASK',
  HAPPINESS_CALL_TASK = 'HAPPINESS_CALL_TASK',
  CREDIT_NOTE_PENDING_TASK = 'CREDIT_NOTE_PENDING_TASK',
  REPEAT_HAPPINESS_CALL_TASK = 'REPEAT_HAPPINESS_CALL_TASK',
  CLAIM_PROOF_SUBMITTED_TASK = 'CLAIM_PROOF_SUBMITTED_TASK',
  CLAIM_PROOF_PENDING_TASK = 'CLAIM_PROOF_PENDING_TASK',
  CARE_CALL_TASK = 'CARE_CALL_TASK',
  CANCELLATION_REQUESTED = 'CANCELLATION_REQUESTED_TASK',
  CAR_RETURNED_TASK = 'CAR_RETURNED_TASK',
  ORDER_READY_FOR_VERIFICATION_TASK = 'ORDER_READY_FOR_VERIFICATION_TASK',
  DOCUMENTS_RETURNED_DEREGISTRATION_TASK = 'DOCUMENTS_RETURNED_DEREGISTRATION_TASK',
  DOCUMENTS_RETURNED_TASK = 'DOCUMENTS_RETURNED_TASK',
  CAR_DEREGISTRATION_TASK = 'CAR_DEREGISTRATION_TASK',
  CAR_RETURN_HANDOVER_APPOINTMENT_TASK = 'CAR_RETURN_HANDOVER_APPOINTMENT_TASK',
  BANK_DETAILS_PENDING_TASK = 'BANK_DETAILS_PENDING_TASK',
  DAMAGE_COMPENSATION_PENDING_TASK = 'DAMAGE_COMPENSATION_PENDING_TASK',
  CLAIM_PARTIAL_REFUND_PENDING_TASK = 'CLAIM_PARTIAL_REFUND_PENDING_TASK',
  EMAIL_REPLY_PENDING_TASK = 'EMAIL_REPLY_PENDING_TASK',
  EMAIL_REPLY_REVIEW_TASK = 'EMAIL_REPLY_REVIEW_TASK',
  FINANCING_INTERNAL_APPROVAL_TASK = 'FINANCING_INTERNAL_APPROVAL_TASK',
  VERIFICATION_CALL_TASK = 'VERIFICATION_CALL_TASK',
  FINANCING_APPLICATION_VALIDATION_TASK = 'FINANCING_APPLICATION_VALIDATION_TASK',
  FINANCING_APPLICATION_PENDING_BANK_DECISION_TASK = 'FINANCING_APPLICATION_PENDING_BANK_DECISION_TASK',
  TAX_BPM_UPDATE_PENDING_TASK = 'BPM_TAX_TASK',
  ORDER_SHIPPING_DETAILS_UPDATE_TASK = 'ORDER_SHIPPING_DETAILS_UPDATE_TASK',
  TRADE_IN_PURCHASE_PENDING_TASK = 'TRADE_IN_PURCHASE_PENDING_TASK',
  LICENSE_PLATE_UPDATE_PENDING_TASK = 'LICENSE_PLATE_TASK',
  FINANCING_SEPA_MANDATE_VERIFICATION_TASK = 'FINANCING_SEPA_MANDATE_VERIFICATION_TASK',
  FINANCING_BILLING_ADDRESS_VERIFICATION_TASK = 'FINANCING_BILLING_ADDRESS_VERIFICATION_TASK',
  FINANCING_MONTHLY_PAYMENT_DAY_UPDATE_TASK = 'FINANCING_MONTHLY_PAYMENT_DAY_UPDATE_TASK',
  RETAIL_BANK_FINANCE_AMOUNT_VERIFICATION_TASK = 'RETAIL_BANK_FINANCE_AMOUNT_VERIFICATION_TASK',
  SEPA_MANDATE_UPDATE_INFORM_HMCS_TASK = 'SEPA_MANDATE_UPDATE_INFORM_HMCS_TASK',
  EXTERNAL_FINANCING_BANK_DETAILS_PENDING = 'EXTERNAL_FINANCING_BANK_DETAILS_PENDING_TASK'
}

export enum ReturnDeliveryType {
  CUSTOMER_DROP_OF = 'CUSTOMER_DROP_OF',
  AH_PICK_UP = 'AH_PICK_UP'
}
