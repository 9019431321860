// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".htbtjANb\\+IhNo7v1wsjr0w\\=\\= .antd-4-21-7-form-item {\n  display: inline-block;\n  margin-bottom: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/TaskManagement/SearchForm/styles.less"],"names":[],"mappings":"AAKM;EACE,qBAAA;EACA,gBAAA;AAJR","sourcesContent":["@import '../../../styles/constants.less';\n\n.form {\n  :global {\n    .@{antd-version} {\n      &-form-item {\n        display: inline-block;\n        margin-bottom: 0;\n      }\n    }\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "htbtjANb+IhNo7v1wsjr0w=="
};
export default ___CSS_LOADER_EXPORT___;
