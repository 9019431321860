import { DELIVERY_OPTIONS } from '@retail/order-constants';

export const deliveryOptions = [
  {
    value: DELIVERY_OPTIONS.AH_TRUCK,
    label: 'HD_AH_truck'
  },
  {
    value: DELIVERY_OPTIONS.AH_JOCKEY,
    label: 'HD_AH_Jockey'
  },
  {
    value: DELIVERY_OPTIONS.THIRD_PARTY_TRUCK,
    label: 'HD_ThirdParty_truck'
  },
  {
    value: DELIVERY_OPTIONS.THIRD_PARTY_JOCKEY,
    label: 'HD_ThirdParty_Jockey'
  },
  {
    value: DELIVERY_OPTIONS.NEAREST_BRANCH,
    label: 'Branch pickup'
  },
  {
    value: DELIVERY_OPTIONS.MIN_ETA,
    label: 'Current Car Location'
  }
];
