import { UserAddOutlined } from '@ant-design/icons';
import { OptionModel } from '@retail/backoffice-ui';
import { useUserContext } from '@retail/backoffice-ui/src/UserContext';
import { Button, Select, Tooltip } from 'antd';
import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAssign } from './hooks/useAssign';
import { useSelfAssign } from './hooks/useSelfAssign';
import { useUnassign } from './hooks/useUnassign';
import cn from './styles.less';

import { UserProjection } from '~apollo/gql-types';
import { AUX_USER_ACCESS_PARAM_VALUE } from '~constants/auxUser/accessParams';
import { IUserContext } from '~providers/UserProvider';
import { Assignee } from '~types/Assignee';
import { getFullName } from '~utils/user';

const { Option } = Select;

export interface WithAssignPermissionsProps {
  isLoading: boolean;
  assignees: Assignee[];
  taskId: string;
  taskType: string;
  retailCountry: string;
  assignee: UserProjection;
  qaSelector: string;
  allowedToAssignTasks: string[];
  allowedToSelfAssignTasks: string[];
  allowedToUnAssignTasks: string[];
}

export function withAssignPermissions<Props>(
  As: FunctionComponent<Props & WithAssignPermissionsProps>
) {
  return (props: Props & WithAssignPermissionsProps) => {
    const {
      isLoading,
      assignees,
      assignee,
      taskId,
      taskType,
      retailCountry,
      qaSelector,
      allowedToAssignTasks,
      allowedToSelfAssignTasks,
      allowedToUnAssignTasks
    } = props;

    const { t } = useTranslation();
    const user = useUserContext<IUserContext>();
    const [onAssign, isLoadingAssign] = useAssign({ taskId });
    const [onUnassign, isLoadingUnAssign] = useUnassign({ taskId });
    const [onSelfAssign, isLoadingSelfAssign] = useSelfAssign({ taskId });

    const canAssign = allowedToAssignTasks?.includes(taskType);
    const canSelfAssign = allowedToSelfAssignTasks?.includes(taskType);
    const canUnassign = allowedToUnAssignTasks?.includes(taskType);

    const options = useMemo(() => {
      const result: OptionModel[] = assignees.filter(
        ({ taskNames, value, countryAccess }) =>
          (countryAccess.includes(AUX_USER_ACCESS_PARAM_VALUE.ALL) ||
            countryAccess.includes(retailCountry)) &&
          taskNames.includes(taskType) &&
          /** we have special button to self assign */
          value !== user.id
      );

      if (
        assignee?.id &&
        result.length &&
        !result.find((x) => x.value === assignee?.id)
      ) {
        result.push({
          value: assignee?.id,
          label: getFullName(assignee),
          disabled: user.id === assignee?.id ? !canSelfAssign : false
        });
      }

      return result;
    }, [assignee, assignees, canSelfAssign, retailCountry, taskType, user.id]);

    return canAssign || canSelfAssign || canUnassign ? (
      <div className={cn.section}>
        {user.id !== assignee?.id && canSelfAssign ? (
          <Tooltip title={t('bo.taskManagement.table.assignee.tooltip')}>
            <Button
              type="primary"
              data-qa-selector="selfAssignButton"
              data-qa-selector-value={taskId}
              disabled={isLoadingSelfAssign}
              loading={isLoadingSelfAssign}
              icon={<UserAddOutlined />}
              onClick={onSelfAssign}
            />
          </Tooltip>
        ) : null}
        <Select
          showSearch
          onChange={onAssign}
          disabled={
            isLoading ||
            isLoadingSelfAssign ||
            isLoadingAssign ||
            isLoadingUnAssign ||
            !canAssign
          }
          loading={isLoadingSelfAssign || isLoadingAssign || isLoadingUnAssign}
          style={{ width: '100%' }}
          data-qa-selector={qaSelector}
          allowClear={canUnassign}
          onClear={onUnassign}
          value={assignee?.id}
          placeholder={t('bo.taskManagement.table.assignee.placeholder')}
          filterOption={(input, option) =>
            (option.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          dropdownStyle={{ minWidth: '250px' }}
        >
          {options?.map((item) => (
            <Option
              key={item?.value}
              value={item?.value}
              data-qa-selector="task-assignee-option"
              data-qa-selector-option-value={`task-assignee-option-${item?.value}`}
              data-qa-selector-option-label={`task-assignee-option-${item?.label}`}
            >
              {item?.label}
            </Option>
          ))}
        </Select>
      </div>
    ) : (
      <As {...props} />
    );
  };
}
