import { Table, TableColumnsType } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCustomDateColumn } from './hooks';
import cn from './styles.less';

import { DocumentsReturnedDeregistrationTaskProjection } from '~apollo/gql-types';
import { formatDateTime } from '~helpers/date';

interface Entity {
  canceledOn: string;
  documentsRequestedDate: string;
  finalDocumentHandoverDate: string;
  vin: string;
  stockNumber: string;
}

interface Props {
  data: DocumentsReturnedDeregistrationTaskProjection;
}

export function DocumentsReturnedDeregistrationSubRow({ data }: Props) {
  const {
    order,
    documentsRequestedDate,
    finalDocumentHandoverDate,
    vin,
    stockNumber,
    documentsRegistrationReadyDate,
    registrationInitiatedDate
  } = data;
  const { t } = useTranslation();
  const dataSource = [
    {
      canceledOn: order.canceledOn,
      documentsRequestedDate,
      finalDocumentHandoverDate,
      vin,
      stockNumber
    }
  ];

  const customColumn = useCustomDateColumn({
    documentsRequestedDate,
    documentsRegistrationReadyDate,
    finalDocumentHandoverDate,
    registrationInitiatedDate
  });

  const columns = useMemo(() => {
    const result: TableColumnsType<Entity> = [
      {
        title: t('bo.taskManagement.cancellationsAndReturnsSubRow.canceledOn'),
        dataIndex: 'canceledOn',
        key: 'canceledOn',
        render: (value) => (
          <span data-qa-selector="canceledOn">
            {value ? formatDateTime(value) : '-'}
          </span>
        )
      },
      {
        title: t('bo.taskManagement.cancellationsAndReturnsSubRow.vin'),
        dataIndex: 'vin',
        key: 'vin',
        render: (value) => <span data-qa-selector="vin">{value || '-'}</span>
      },
      {
        title: t('bo.taskManagement.cancellationsAndReturnsSubRow.stockNumber'),
        dataIndex: 'stockNumber',
        key: 'stockNumber',
        render: (value) => (
          <span data-qa-selector="stockNumber">{value || '-'}</span>
        )
      }
    ];

    if (customColumn) {
      result.splice(1, 0, customColumn);
    }

    return result;
  }, [customColumn, t]);

  return (
    <Table
      columns={columns}
      pagination={false}
      rowKey="documentsReturnedDeregistrationSubRow"
      dataSource={dataSource}
      bordered
      className={cn.table}
    />
  );
}
