// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\+O0ecs1b3mXVRIKr6uzQJw\\=\\= {\n  margin: 10px 0;\n}\n.SW2-Z4DC41O0o1VhIfYrzQ\\=\\= {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 10px;\n  padding-left: 10px;\n}\n._8\\+jW11MBBDlKKZrFhuizwA\\=\\= {\n  margin: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/TaskManagement/Comments/styles.less"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;AAAF;AAGA;EACE,SAAA;AADF","sourcesContent":[".comments {\n  margin: 10px 0;\n}\n\n.header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 10px;\n  padding-left: 10px;\n}\n\n.commentsCount {\n  margin: 0;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comments": "+O0ecs1b3mXVRIKr6uzQJw==",
	"header": "SW2-Z4DC41O0o1VhIfYrzQ==",
	"commentsCount": "_8+jW11MBBDlKKZrFhuizwA=="
};
export default ___CSS_LOADER_EXPORT___;
