import { getClmCustomerLeadPath } from '@retail/clm-customer-lead-path';
import { Table, TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import cn from './styles.less';

import { TaskEntityOfType } from '~pages/TaskManagement/types';

const FALLBACK = '-';

interface Props {
  data: TaskEntityOfType<'VerificationCallTaskProjection'>;
}

export function VerificationCallSubRow({ data }: Props) {
  const { t } = useTranslation();
  const { language } = useParams();
  const dataSource = [data];

  const columns: TableColumnsType<
    TaskEntityOfType<'VerificationCallTaskProjection'>
  > = [
    {
      title: t('bo.taskManagement.emailReplyTask.customerName'),
      dataIndex: 'customerName',
      key: 'customerName',
      render: (value) => (
        <span data-qa-selector="customerName">{value || FALLBACK}</span>
      )
    },
    {
      title: t('bo.taskManagement.verificationCallTask.email'),
      dataIndex: 'customerEmail',
      key: 'customerEmail',
      render: (value) => (
        <span data-qa-selector="email">{value || FALLBACK}</span>
      )
    },
    {
      title: t('bo.taskManagement.verificationCallTask.phoneNumber'),
      dataIndex: 'customerPhoneNumber',
      key: 'customerPhoneNumber',
      render: (value) => (
        <span data-qa-selector="phoneNumber">{value || FALLBACK}</span>
      )
    },
    {
      title: t('bo.taskManagement.verificationCallTask.clm'),
      dataIndex: 'retailCountry',
      key: 'retailCountry',
      render: (retailCountry, { customerEmail, customerLeadId }) => (
        <Link
          data-qa-selector="clm-link"
          target="_blank"
          to={getClmCustomerLeadPath({
            customerEmail,
            language,
            retailCountry,
            customerUuid: customerLeadId
          })}
        >
          {t('bo.taskManagement.verificationCallTask.link')}
        </Link>
      )
    }
  ];

  return (
    <Table
      columns={columns}
      pagination={false}
      rowKey="id"
      dataSource={dataSource}
      bordered
      className={cn.table}
      style={{ width: '100%', border: '1px solid #616161' }}
    />
  );
}
