import { isValid, parseISO } from 'date-fns';
import { isString } from 'lodash/fp';

import { isMobileSafari, isSafari } from '../browser';

/**
 * Do not change unnecessarily, those functions affects performance
 */
const defaultParseDate = (date: Date | string): number | Date =>
  isString(date) ? Date.parse(date) : date;

const safariParseDate = (rawDate: string) => {
  if (!isString(rawDate)) {
    return rawDate;
  }
  const date = parseISO(rawDate);
  return isValid(date) ? +date : NaN;
};

export const createParseDate = () =>
  isSafari || isMobileSafari ? safariParseDate : defaultParseDate;
