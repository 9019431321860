import { useEffect, useState } from 'react';

import { CompleteTaskDataByType } from '../types';

import { TaskEntityModal } from '~pages/TaskManagement/types';

export function useInitialValues(
  formConfig: CompleteTaskDataByType,
  taskData: TaskEntityModal
) {
  const [state, setState] = useState({
    values: null,
    isLoading: false
  });

  useEffect(() => {
    (async () => {
      setState({ values: null, isLoading: true });

      const requests = Object.entries(formConfig).map(
        async ([fieldName, data]) => ({
          [fieldName]: await data.getInitialValue?.(taskData)
        })
      );

      const values = (await Promise.all(requests)).reduce(
        (acc, response) => Object.assign(acc, response),
        {}
      );

      setState({
        values,
        isLoading: false
      });
    })();
  }, [taskData, formConfig]);

  return state;
}
