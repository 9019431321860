import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';

import {
  LoadExternalFinancingDocument,
  LoadExternalFinancingQuery,
  LoadExternalFinancingQueryVariables
} from '~apollo/gql-types';
import { DOMAINS } from '~constants/permissions';

export const useLoadExternalFinancingReference = () => {
  const apolloClient = useApolloClient();

  return useCallback(
    async (orderId: string) => {
      const response = await apolloClient.query<
        LoadExternalFinancingQuery,
        LoadExternalFinancingQueryVariables
      >({
        query: LoadExternalFinancingDocument,
        fetchPolicy: 'network-only',
        variables: {
          orderId
        },
        context: {
          headers: { 'access-domain': DOMAINS.ORDER_MANAGEMENT }
        }
      });

      return response.data.externalFinancingOptions?.externalFinancingRefNumber;
    },
    [apolloClient]
  );
};
