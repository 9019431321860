import { useCallback } from 'react';

import { useSelfAssignTaskManagerTaskMutation } from '~apollo/gql-types';
import { useQueryBaseOptions } from '~hooks/useQueryBaseOptions';

interface Props {
  taskId: string;
}

export function useSelfAssign({ taskId }: Props) {
  const queryBaseOptions = useQueryBaseOptions();

  const [selfAssign, { loading }] = useSelfAssignTaskManagerTaskMutation({
    ...queryBaseOptions,
    refetchQueries: ['SearchTaskManagerTasks']
  });

  return [
    useCallback(
      () =>
        selfAssign({
          variables: {
            taskId
          }
        }),
      [selfAssign, taskId]
    ),
    loading
  ] as const;
}
