import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { unitsFromMinorToMajor } from '@retail/currency';

import { getDescriptionFieldConfig } from '../utils';

import { LinkedOrdersWarningInput } from './LinkedOrdersWarningInput';

import { RetailOrderBasedTaskAction } from '~apollo/gql-types';
import { TaskEntityOfType } from '~pages/TaskManagement/types';
import { HiddenControl } from '~pages/TaskManagement/TaskModal/Inputs/HiddenControl';
import { CompleteTaskDataByType } from '~pages/TaskManagement/TaskModal/types';

type TaskEntity = TaskEntityOfType<'OverpaymentRefundApprovalTaskProjection'>;

export const useOverpaidRefundApprovalTaskConfig = () => {
  const { t } = useTranslation();

  return useMemo<CompleteTaskDataByType>(
    () => ({
      linkedOrdersWarning: {
        type: 'Custom',
        component: LinkedOrdersWarningInput
      },
      paymentType: {
        getInitialValue: (data: TaskEntity) => data.paymentType,
        type: 'Select',
        disabled: true,
        required: true,
        useOptions: (data: TaskEntity) => [
          [
            {
              label: t(`bo.taskManagement.paymentTypes.${data.paymentType}`),
              value: data.paymentType
            }
          ],
          false
        ]
      },
      refundApprovedAmount: {
        type: 'Input',
        getInitialValue: (data: TaskEntity) =>
          data.refundApprovedAmount ?? Math.abs(data.overpaymentAmount),
        format: unitsFromMinorToMajor,
        disabled: true,
        required: true
      },
      action: {
        type: 'Select',
        required: true,
        getInitialValue: (modalData) => modalData?.action,
        options: [
          {
            label: t(
              'bo.taskManagement.overpaymentRefundApprovalTask.action.APPROVED'
            ),
            value: RetailOrderBasedTaskAction.Approved
          },
          {
            label: t(
              'bo.taskManagement.overpaymentRefundApprovalTask.action.REJECTED'
            ),
            value: RetailOrderBasedTaskAction.Rejected
          }
        ],
        getAdditionalFields: (
          action: RetailOrderBasedTaskAction,
          data: TaskEntity
        ) => {
          const { refundToField } = data;

          return {
            [refundToField.variableName]: (() => {
              if (
                action == null ||
                action === RetailOrderBasedTaskAction.Rejected ||
                refundToField.possibleValues.length === 0
              ) {
                return {
                  showLabel: false,
                  type: 'Custom',
                  getInitialValue: () => null,
                  component: HiddenControl
                };
              }

              if (refundToField.possibleValues.length === 1) {
                return {
                  showLabel: false,
                  type: 'Custom',
                  getInitialValue: () => refundToField.possibleValues[0],
                  component: HiddenControl
                };
              }

              return {
                type: 'RadioGroup',
                required: refundToField.isMandatory,
                getInitialValue: () => refundToField.preselectedValue,
                options: refundToField.possibleValues.map((it) => ({
                  value: it,
                  label: t(
                    `bo.taskManagement.overpaymentRefundApprovalTask.refundTo.${it}`
                  )
                }))
              };
            })(),
            ...getDescriptionFieldConfig({
              required: action === RetailOrderBasedTaskAction.Rejected
            })
          };
        }
      }
    }),
    [t]
  );
};
