import React, { memo, ReactNode } from 'react';
import { Checkbox, Form, SelectProps, Typography } from 'antd';
import {
  FieldValues,
  useController,
  UseControllerProps
} from 'react-hook-form';

const { Text } = Typography;

interface AntCheckboxGroupProps<T extends FieldValues>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extends SelectProps<any> {
  controllerProps: UseControllerProps<T>;
  labelCol?: { span: number };
  label?: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: Array<any>;
  required?: boolean;
}

export const CheckboxGroup = memo<AntCheckboxGroupProps<FieldValues>>(
  ({ label, options, labelCol, controllerProps, required }) => {
    const { field, fieldState } = useController(controllerProps);
    const error = fieldState.error?.message;
    return (
      <Form.Item
        label={label ? <Text>{`${label} ${required ? '*' : ''}`}</Text> : null}
        labelCol={labelCol}
        help={error || null}
        validateStatus={error ? 'error' : null}
      >
        <Checkbox.Group
          {...field}
          onChange={field.onChange}
          style={{ display: 'grid' }}
          options={options}
        />
      </Form.Item>
    );
  }
);
