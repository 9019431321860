import { TableCell } from '@retail/backoffice-ui';
import { Table, TableColumnsType } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCustomDateColumn } from './hooks';
import cn from './styles.less';

import { CancellationRequestedTaskProjection } from '~apollo/gql-types';
import { formatDateTime } from '~helpers/date';

interface Props {
  data: CancellationRequestedTaskProjection;
}

export function CancellationRequestedSubRow({ data }: Props) {
  const { t } = useTranslation();
  const {
    documentsRequestedDate,
    documentsRegistrationReadyDate,
    finalDocumentHandoverDate,
    registrationInitiatedDate
  } = data;
  const dataSource = [data];

  const customColumn = useCustomDateColumn({
    documentsRequestedDate,
    documentsRegistrationReadyDate,
    finalDocumentHandoverDate,
    registrationInitiatedDate
  });

  const columns = useMemo(() => {
    const result: TableColumnsType<CancellationRequestedTaskProjection> = [
      {
        title: t(
          'bo.taskManagement.cancellationsAndReturnsSubRow.cancelReason'
        ),
        dataIndex: 'cancelReason',
        key: 'cancelReason',
        render: (value) => (
          <TableCell data-qa-selector="cancelReason">
            {value
              ? t(`bo.ordersPage.cancelOrder.modal.orderCancelTypes.${value}`)
              : null}
          </TableCell>
        )
      },
      {
        title: t('bo.taskManagement.happinessTable.carHandoverOnDate'),
        dataIndex: 'carHandoverOnDate',
        key: 'carHandoverOnDate',
        render: (value) => (
          <TableCell data-qa-selector="carHandoverOnDate">
            {value ? formatDateTime(value) : null}
          </TableCell>
        )
      },
      {
        title: t('bo.taskManagement.cancellationsAndReturnsSubRow.paymentType'),
        dataIndex: 'paymentType',
        key: 'paymentType',
        render: (value) => (
          <TableCell data-qa-selector="paymentType">{value}</TableCell>
        )
      },
      {
        title: t('bo.taskManagement.cancellationsAndReturnsSubRow.tradeIn'),
        dataIndex: 'hasTradeInItem',
        key: 'hasTradeInItem',
        render: (value) => (
          <TableCell data-qa-selector="hasTradeInItem">
            {value ? 'Yes' : 'No'}
          </TableCell>
        )
      }
    ];

    if (customColumn) {
      result.splice(1, 0, customColumn);
    }

    return result;
  }, [customColumn, t]);

  return (
    <Table
      columns={columns}
      pagination={false}
      rowKey="id"
      dataSource={dataSource}
      bordered
      className={cn.table}
    />
  );
}
