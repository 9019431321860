import { Typography } from 'antd';

const { Text } = Typography;

interface Props {
  flowVersion: string;
  orderNumber: string;
}

export function OrderNumberLabel({ flowVersion, orderNumber }: Props) {
  return (
    <Text copyable={{ text: orderNumber }} style={{ color: 'inherit' }}>
      {orderNumber} <strong>({flowVersion})</strong>
    </Text>
  );
}
