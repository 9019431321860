import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { BankDetailsCancellationRefundSubRow } from './BankDetailsCancellationRefundSubRow';
import { BankDetailsDefaultSubRow } from './BankDetailsDefaultSubRow';
import { BankDetailsOverpaymentSubRow } from './BankDetailsOverpaymentSubRow';

import { TaskEntityOfType } from '~pages/TaskManagement/types';

const COMPONENTS: Record<string, FunctionComponent<Props>> = {
  OVERPAYMENT: BankDetailsOverpaymentSubRow,
  CANCELLATION_REFUND: BankDetailsCancellationRefundSubRow,
  DEFAULT: BankDetailsDefaultSubRow
};

interface Props {
  data: TaskEntityOfType<'BankDetailsPendingTaskProjection'>;
}

export function BankDetailsSubRow({ data }: Props) {
  const { t } = useTranslation();
  const { reason } = data;

  const As = COMPONENTS[data.reason] ?? COMPONENTS.DEFAULT;

  return (
    <As
      data={{
        ...data,
        reason: t(`bo.taskManagement.damageCompensationTable.reason.${reason}`)
      }}
    />
  );
}
