import { Table, TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';

import cn from '../styles.less';

import { TaskEntityOfType } from '~pages/TaskManagement/types';
import { useFormatCurrency } from '~utils/i18n';

interface Entity extends TaskEntityOfType<'BankDetailsPendingTaskProjection'> {
  requestedAmount: string;
}

interface Props {
  data: TaskEntityOfType<'BankDetailsPendingTaskProjection'>;
}

export function BankDetailsOverpaymentSubRow({ data }: Props) {
  const { t } = useTranslation();
  const mapData = {
    ...data,
    requestedAmount: useFormatCurrency({
      amountMinorUnits: data.requestedAmount
    })
  };

  const dataSource = [mapData];

  const columns: TableColumnsType<Entity> = [
    {
      title: t('bo.taskManagement.bankDetailsPendingTask.reason'),
      dataIndex: 'reason',
      key: 'reason',
      render: (value) => <span data-qa-selector="reason">{value || '-'}</span>
    },
    {
      title: t('bo.taskManagement.bankDetailsPendingTask.type'),
      dataIndex: 'type',
      key: 'type',
      render: (value) => (
        <span data-qa-selector="type">
          {t(`bo.taskManagement.bankDetailsPendingTask.type.${value}`) || '-'}
        </span>
      )
    },
    {
      title: t('bo.taskManagement.bankDetailsPendingTask.requestedAmount'),
      dataIndex: 'requestedAmount',
      key: 'requestedAmount',
      render: (value) => (
        <span data-qa-selector="requestedAmount">{value || '-'}</span>
      )
    }
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      pagination={false}
      dataSource={dataSource}
      bordered
      className={cn.table}
      style={{ width: '100%', border: '1px solid #616161' }}
    />
  );
}
