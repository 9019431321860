import { keys } from 'lodash';

import languages from './languages';

export const getLocale = (lng: string) =>
  languages[lng as keyof typeof languages] || languages.en;

export const i18nDetectLng = () => {
  let lng = localStorage.getItem(process.env.LANGUAGE_KEY);

  if (!keys(languages).includes(lng)) {
    lng = process.env.LANGUAGE_KEY;
  }

  return lng.toLowerCase();
};
