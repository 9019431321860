import { FormItem } from '@retail/backoffice-ui';
import {
  Checkbox,
  Input,
  InputNumber,
  InputNumberProps,
  InputProps
} from 'antd';
import { valueType } from 'antd/lib/statistic/utils';
import { ReactNode, memo } from 'react';
import {
  FieldValues,
  UseControllerProps,
  useController
} from 'react-hook-form';

import cn from './styles.less';

type InputControlledProps<T extends FieldValues> = {
  controllerProps: UseControllerProps<T>;
  label?: ReactNode;
  checkboxLabel?: ReactNode;
  labelCol?: { span: number };
  parse?: (
    value: valueType | React.ChangeEvent<HTMLInputElement>
  ) => string | number;
  format?: (value: valueType) => string | number;
} & (InputProps & InputNumberProps);

export const InputControlled = memo<InputControlledProps<FieldValues>>(
  ({
    label,
    controllerProps,
    required,
    format = (e) => e,
    parse = (e) => e,
    type,
    labelCol,
    addonBefore,
    checkboxLabel,
    disabled,
    ...restInputProps
  }) => {
    const { field } = useController(controllerProps);

    let input;

    switch (type) {
      case 'checkbox':
        input = (
          <Checkbox
            {...field}
            value={format(field.value)}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onChange={(e) => field.onChange(parse(e.target.checked))}
            disabled={disabled}
            {...restInputProps}
          >
            {checkboxLabel}
          </Checkbox>
        );
        break;

      case 'number':
        input = (
          <InputNumber
            {...field}
            required={required}
            value={format(field.value)}
            onChange={(value) => field.onChange(parse(value))}
            disabled={disabled}
            {...restInputProps}
            decimalSeparator=","
          />
        );
        break;

      default:
        input = (
          <Input
            {...field}
            required={required}
            value={format(field.value)}
            onChange={(value) => field.onChange(parse(value))}
            disabled={disabled}
            {...restInputProps}
          />
        );
        break;
    }

    return (
      <FormItem
        label={label}
        labelCol={labelCol}
        required={required}
        disabled={disabled}
        controllerProps={controllerProps as unknown as UseControllerProps}
      >
        <div className={cn.inputWrapper}>
          {addonBefore && <div className={cn.addonBefore}>{addonBefore}</div>}
          {input}
        </div>
      </FormItem>
    );
  }
);
