import { useCallback } from 'react';

import {
  useLoadLicensePlateByRetailAdIdLazyQuery,
  useLoadOrderDetailsLazyQuery
} from '~apollo/gql-types';
import { DOMAINS } from '~constants/permissions';

export const useLoadLicensePlateByOrderId = () => {
  const [loadOrderDetailsQuery, orderDetailsResponse] =
    useLoadOrderDetailsLazyQuery({
      context: { headers: { 'access-domain': DOMAINS.ORDER_MANAGEMENT } }
    });
  const [loadLicensePlateQuery, licensePlateResponse] =
    useLoadLicensePlateByRetailAdIdLazyQuery({
      context: { headers: { 'access-domain': DOMAINS.ORDER_MANAGEMENT } }
    });

  const loadLicensePlateByOrderId = useCallback(
    async (orderId) => {
      if (orderId) {
        const res = await loadOrderDetailsQuery({ variables: { orderId } });
        const adId = res.data?.order?.adItems?.[0]?.ad?.id;
        const licensePlateResponse = await loadLicensePlateQuery({
          variables: { adId }
        });
        return licensePlateResponse.data?.licensePlateByRetailAdId
          ?.licensePlate;
      }
    },
    [loadLicensePlateQuery, loadOrderDetailsQuery]
  );

  return [
    loadLicensePlateByOrderId,
    orderDetailsResponse.loading || licensePlateResponse.loading
  ] as const;
};
