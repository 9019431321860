import { TextareaControlled } from '@retail/backoffice-ui';
import { Button } from 'antd';
import { Dispatch } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import cn from './styles.less';

import {
  CamundaSearchProjection2Input,
  Exact,
  useCommentTaskManagerTaskMutation
} from '~apollo/gql-types';
import { TaskEntity } from '~pages/TaskManagement/types';

interface FormModel {
  comment: string;
}

interface Props {
  data: TaskEntity;
  taskManagerTasksRefetch: Dispatch<Partial<
    Exact<{
      search: CamundaSearchProjection2Input;
    }>
  > | void>;
  closeCommentForm: VoidFunction;
}

export const CommentForm = ({
  data,
  taskManagerTasksRefetch,
  closeCommentForm
}: Props) => {
  const { t } = useTranslation();
  const { handleSubmit, control, reset } = useForm<FormModel>({
    mode: 'onSubmit'
  });
  const [commentTaskManagerTaskMutation, { loading }] =
    useCommentTaskManagerTaskMutation({
      update(cache, { data: { commentTaskManagerTask } }) {
        cache.modify({
          fields: {
            searchTaskManagerTaskComments(existingData = {}) {
              const { entities } = existingData;

              if (Array.isArray(entities)) {
                return {
                  ...existingData,
                  entities: [...entities, commentTaskManagerTask]
                };
              }

              return existingData;
            }
          }
        });
      }
    });

  const onSubmit = async ({ comment }: FormModel) => {
    await commentTaskManagerTaskMutation({
      variables: {
        arguments: {
          taskId: data.id,
          comment
        }
      }
    });
    await reset();

    await taskManagerTasksRefetch();
  };

  const onCancelClick = () => {
    reset();
    closeCommentForm();
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={cn.form}
      data-qa-selector={`comment-form-${data?.id}`}
    >
      <TextareaControlled
        data-qa-selector="commentTextArea"
        autoFocus
        rows={3}
        maxLength={300}
        placeholder="Type here..."
        required
        className={cn.textarea}
        disabled={loading}
        controllerProps={{
          name: 'comment',
          control,
          rules: {
            required: {
              value: true,
              message: t('bo.taskManagement.form.validation.canNotBeEmpty')
            }
          }
        }}
      />
      <Button
        data-qa-selector="cancel"
        disabled={loading}
        htmlType="button"
        type="default"
        onClick={onCancelClick}
        className={cn.cancel}
      >
        {t('bo.taskManagement.comments.cancel')}
      </Button>
      <Button
        data-qa-selector="addComment"
        disabled={loading}
        htmlType="submit"
        type="primary"
        className={cn.save}
        loading={loading}
      >
        {t('bo.taskManagement.comments.save')}
      </Button>
    </form>
  );
};
