import { useCheckPermissions } from '@retail/backoffice-ui/src/Permission';
import { Typography } from 'antd';

import { TASK_NAME } from '../constants';

import { OrderNumberLabel } from './OrderNumberLabel';

import * as ROUTES from '~routes';
import { DOMAINS, PERMISSIONS } from '~constants/permissions';
import { i18nDetectLng } from '~i18n/utils';

const { Link } = Typography;

function getLink(taskType: TASK_NAME) {
  switch (taskType) {
    case TASK_NAME.REFUND_PENDING_TASK:
    case TASK_NAME.CASH_OUT_REFUND_APPROVAL_PENDING_TASK:
    case TASK_NAME.ACCOUNTING_DATE_PENDING_TASK:
    case TASK_NAME.CREDIT_NOTE_NUMBER_PENDING_TASK:
    case TASK_NAME.CREDIT_NOTE_PENDING_TASK:
    case TASK_NAME.BANK_DETAILS_PENDING_TASK:
    case TASK_NAME.DAMAGE_COMPENSATION_PENDING_TASK:
      return ROUTES.ORDER_INVOICES_PAYMENTS;

    case TASK_NAME.VERIFICATION_CALL_TASK:
    case TASK_NAME.ORDER_READY_FOR_VERIFICATION_TASK:
      return ROUTES.ORDER_VERIFICATION_SUBTAB;

    case TASK_NAME.FINANCING_APPLICATION_VALIDATION_TASK:
    case TASK_NAME.FINANCING_APPLICATION_PENDING_BANK_DECISION_TASK:
      return ROUTES.ORDER_CONSUMER_FINANCING;

    default:
      return ROUTES.ORDER;
  }
}

interface Props {
  orderNumber: string;
  orderId: string;
  taskType: TASK_NAME;
  qaSelector: string;
  flowVersion: string;
}

export const OrderNumber = ({
  orderNumber,
  orderId,
  taskType,
  qaSelector,
  flowVersion
}: Props) => {
  const queryOrderPermission = useCheckPermissions({
    allow: PERMISSIONS.QUERY_ORDER,
    domain: DOMAINS.ORDER_MANAGEMENT
  });

  if (queryOrderPermission?.isAllowed) {
    return (
      <Link
        target="_blank"
        href={getLink(taskType).LINK({ language: i18nDetectLng(), orderId })}
      >
        <span data-qa-selector={qaSelector}>
          <OrderNumberLabel
            flowVersion={flowVersion}
            orderNumber={orderNumber}
          />
        </span>
      </Link>
    );
  }

  return (
    <span data-qa-selector={qaSelector}>
      <OrderNumberLabel flowVersion={flowVersion} orderNumber={orderNumber} />
    </span>
  );
};
