import { Alert, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { STATES, ORDER_LINK_TYPES } from '@retail/order-constants';
import { createFilterPayload, Filter, Filters } from '@retail/gql-utils';

import {
  useLoadLinkedOrdersQuery,
  useLoadOrderLinksQuery
} from '~apollo/gql-types';
import { DOMAINS } from '~constants/permissions';
import { CustomModalComponentProps } from '~pages/TaskManagement/TaskModal/types';

const createSearch = (orderIds: string[]) =>
  createFilterPayload({
    filter: Filters.and(
      Filters.includes('id', orderIds),
      Filters.includes('state', [
        STATES.VERIFIED,
        STATES.DELIVERED,
        STATES.COMPLETED
      ])
    ) as Filter,
    page: 0,
    pageSize: 1
  });

export const LinkedOrdersWarningInput = ({
  modalData
}: CustomModalComponentProps) => {
  const { t } = useTranslation();

  const { data: linksData, loading: areLinksLoading } = useLoadOrderLinksQuery({
    context: { headers: { 'access-domain': DOMAINS.ORDER_MANAGEMENT } },
    variables: {
      orderId: modalData.orderId
    }
  });

  const linkedOrderIds = (linksData?.order?.retailOrderLinks ?? [])
    .filter((it) => it.linkType == ORDER_LINK_TYPES.ORDER)
    .map((it) => it.refId as string);

  const { data, loading: areOrdersLoading } = useLoadLinkedOrdersQuery({
    context: { headers: { 'access-domain': DOMAINS.ORDER_MANAGEMENT } },
    skip: linkedOrderIds.length === 0,
    variables: {
      search: createSearch(linkedOrderIds)
    }
  });

  if (areLinksLoading || (linkedOrderIds.length > 0 && areOrdersLoading)) {
    return (
      <Spin
        spinning
        style={{
          position: 'relative',
          transform: 'translateX(-50%)',
          left: '50%'
        }}
      />
    );
  }

  const alertStyle = { marginBottom: '12px' };

  if (data?.orders?.entities?.length > 0) {
    return (
      <Alert
        message={t(
          'bo.taskManagement.overpaymentRefundTask.linkedOrdersWarning'
        )}
        type="warning"
        style={alertStyle}
      />
    );
  }

  return (
    <Alert
      type="info"
      message={t('bo.taskManagement.overpaymentRefundTask.noLinkedOrdersInfo')}
      style={alertStyle}
    />
  );
};
