import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CREATE_NEW_ACCOUNT_DETAILS, PaymentTypeOption } from './contants';
import {
  useGetAccountDetails,
  useGetAccountDetailsOptions
} from './useGetAccountDetailsOptions';
import { usePaymentTypeOptions } from './usePaymentTypeOptions';

import { validateBic, validateIban } from '~helpers/validation';
import { useLoadExternalFinancingReference } from '~pages/TaskManagement/TaskModal/hooks/useCompleteTaskDataByType/useExternalFinancingBankDetailsPendingTaskConfig/useLoadExternalFinancingReference';
import {
  CompleteTaskDataByType,
  Field
} from '~pages/TaskManagement/TaskModal/types';
import { TaskEntityModal, TaskEntityOfType } from '~pages/TaskManagement/types';
import { AvailableActionsModel } from '~types/AvailableActions';

type ExtFinBankDetailsTask =
  TaskEntityOfType<'RetailExternalFinancingBankDetailsPendingTaskProjection'>;

const bankingNumberFieldConfig: Field = {
  type: 'Input',
  placeholder: () => '0000-0000',
  parse: (e) => {
    if (typeof e !== 'number' && typeof e !== 'string') {
      return e.target.value.toString().replace(/\D/g, '').slice(0, 8);
    }
  },
  format: (value) => {
    if (!value) return '';
    const formattedValue = value
      .toString()
      .replace(/(\d{4})(\d{1,4})/, '$1-$2');
    return formattedValue;
  }
};

export const useExternalFinancingBankDetailsPendingTaskConfig = (
  availableActions: AvailableActionsModel
) => {
  const { t } = useTranslation();
  const loadExternalFinancingReference = useLoadExternalFinancingReference();
  const paymentTypeOptions = usePaymentTypeOptions();
  const { loadAccountDetails } = useGetAccountDetails(null);
  return useMemo<CompleteTaskDataByType>(() => {
    return {
      externalFinancingRefNumber: {
        type: 'Input',
        getInitialValue: async (row: ExtFinBankDetailsTask) => {
          const orderId = row?.order?.id;
          return orderId ? loadExternalFinancingReference(orderId) : '';
        },
        required: true
      },
      bankDetails: {
        type: 'Select',
        required: true,
        useOptions: (task: ExtFinBankDetailsTask) =>
          useGetAccountDetailsOptions(
            task?.orderId,
            task?.order?.retailCountry
          ),
        getInitialValue: async (task) => {
          const details = await loadAccountDetails(
            task.orderId,
            task.order.retailCountry
          );
          return details.length > 0
            ? details[0].retailAccount?.accountDataId
            : CREATE_NEW_ACCOUNT_DETAILS;
        },
        getAdditionalFields: (value, modalData: TaskEntityModal) => {
          if (value === CREATE_NEW_ACCOUNT_DETAILS) {
            const ibanBicHolderGroup = {
              iban: {
                type: 'Input',
                required: true,
                validate: validateIban(t)
              },
              bic: {
                type: 'Input',
                required: true,
                validate: validateBic(t)
              },
              holder: {
                type: 'Input',
                required: true
              }
            } as const;

            if (modalData.order.retailCountry === 'SE') {
              return {
                paymentType: {
                  type: 'Select',
                  required: true,
                  options: paymentTypeOptions,
                  getInitialValue: () => PaymentTypeOption.SePlusgiro,
                  getAdditionalFields: (value) => {
                    if (value === PaymentTypeOption.SePlusgiro) {
                      return {
                        number: {
                          ...bankingNumberFieldConfig,
                          label: () =>
                            t(
                              'bo.taskManagement.form.label.BANKGIRO_PLUS_GIRO'
                            ),
                          required: true
                        },
                        holder: {
                          type: 'Input',
                          required: true
                        }
                      };
                    }

                    if (value === PaymentTypeOption.SeBankTransfer) {
                      return {
                        bankName: {
                          type: 'Input',
                          required: true
                        },
                        clearingNumber: {
                          ...bankingNumberFieldConfig,
                          required: true
                        },
                        accountNumber: {
                          ...bankingNumberFieldConfig,
                          required: true
                        },
                        holder: {
                          type: 'Input',
                          required: true
                        }
                      };
                    }

                    if (value === PaymentTypeOption.Iban) {
                      return ibanBicHolderGroup;
                    }

                    return {};
                  }
                }
              };
            } else {
              return ibanBicHolderGroup;
            }
          }

          return {};
        }
      },
      action: {
        type: 'Select',
        disabled: true,
        required: true,
        getIsVisible: () => availableActions.options.length > 1,
        getInitialValue: (row) =>
          row.action ?? availableActions?.options[0]?.value ?? null,
        options: availableActions?.options ?? []
      },
      comment: {
        type: 'Textarea'
      }
    };
  }, [
    availableActions?.options,
    loadExternalFinancingReference,
    loadAccountDetails,
    paymentTypeOptions,
    t
  ]);
};
