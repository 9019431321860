import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CompleteTaskDataByType } from '../../types';

import { getDescriptionFieldConfig } from './utils';

import { RetailOrderBasedTaskAction } from '~apollo/gql-types';

export const useDefaultTaskConfig = (): CompleteTaskDataByType => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      taskType: {
        getInitialValue: () => 'task',
        type: 'Select',
        disabled: true,
        required: true,
        options: [
          {
            label: t('bo.taskManagement.completeTaskForm.type.task'),
            value: 'task'
          }
        ]
      },
      action: {
        type: 'Select',
        required: true,
        getInitialValue: (modalData) => modalData?.action,
        options: [
          {
            label: t('bo.taskManagement.completeTaskForm.action.SUCCESS'),
            value: RetailOrderBasedTaskAction.Success
          },
          {
            label: t('bo.taskManagement.completeTaskForm.action.CLOSE'),
            value: RetailOrderBasedTaskAction.Close
          }
        ]
      },
      ...getDescriptionFieldConfig()
    }),
    [t]
  );
};
