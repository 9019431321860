import { SelectControlled } from '@retail/backoffice-ui';
import { Control, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectProps } from 'antd';

import { SelectData } from './types';

interface Props {
  config: SelectData;
  optionName: string;
  control: Control<FieldValues, object>;
  taskData: unknown;
  label?: string;
  mode: SelectProps['mode'];
}

export function SelectField({
  config,
  optionName,
  control,
  taskData,
  label,
  mode
}: Props) {
  const { t } = useTranslation();

  const [options = [], loading] = config.useOptions?.(taskData) ?? [
    config.options,
    false
  ];
  return (
    <SelectControlled
      disabled={config.disabled || !options?.length}
      options={options}
      getOptionValue={(item) => item?.value ?? null}
      label={label}
      mode={mode}
      placeholder={t(`bo.taskManagement.form.placeholder.${optionName}`)}
      required={config.required}
      loading={config.loading || loading}
      controllerProps={{
        control,
        name: optionName,
        rules: {
          required: {
            value: config.required,
            message: t('bo.taskManagement.form.validation.canNotBeEmpty')
          }
        }
      }}
    />
  );
}
