import { formatCurrency } from '@retail/backoffice-ui/src/utils/formatCurrency';
import { useMemo } from 'react';

export interface FormatCurrencyOptions {
  currency?: string;
  currencyCode?: string;
  conversionMajor?: number;
  amountMinorUnits?: number;
}

export const useFormatCurrency = ({
  amountMinorUnits,
  currency,
  currencyCode,
  conversionMajor
}: FormatCurrencyOptions) => {
  return useMemo(
    () =>
      formatCurrency({
        amountMinorUnits,
        currency,
        currencyCode,
        conversionMajor
      }),
    [amountMinorUnits, conversionMajor, currency, currencyCode]
  );
};
