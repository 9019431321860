import { OptionModel } from '@retail/backoffice-ui';
import { CANCEL_PERIODS, STATES } from '@retail/order-constants';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';

import {
  CancellationRequestedTaskProjection,
  useOrderCancelTypesQuery
} from '~apollo/gql-types';

const CANCEL_OPTION_TRANSLATION_PREFIX =
  'bo.ordersPage.cancelOrder.modal.orderCancelTypes';

interface GenCancelOptionProps {
  t: TFunction<'translation', undefined>;
  name: string;
  disabled?: boolean;
}

function genCancelOption({
  t,
  name,
  disabled
}: GenCancelOptionProps): OptionModel {
  return {
    label: t(`${CANCEL_OPTION_TRANSLATION_PREFIX}.${name}`),
    value: name,
    disabled
  };
}
const DEFAULT_OPTIONS: OptionModel[] = [];

interface Props {
  orderId: string;
  state: string;
  vehicleDeliveredOn: string;
}

function useOrderCancelOptionsByField({
  orderId,
  state,
  vehicleDeliveredOn
}: Props) {
  const { t } = useTranslation();
  const { data, loading } = useOrderCancelTypesQuery({
    variables: { orderId }
  });

  const { orderCancellationOptions } = data ?? {};
  const cancelTypes = orderCancellationOptions?.options;
  const { watch } = useFormContext<{
    cancelType: string;
    cancelPeriod: string;
    cancelReason: string;
  }>();
  const cancelType = watch('cancelType');
  const cancelPeriod = watch('cancelPeriod');
  const cancelReason = watch('cancelReason');
  const isBeforeHandoverDisabled =
    vehicleDeliveredOn && state === STATES.DELIVERED;
  const isAfterHandoverDisabled =
    !vehicleDeliveredOn && state !== STATES.DELIVERED;

  return useMemo(() => {
    const { periods: cancelPeriods } =
      cancelTypes?.find(({ name }) => name === cancelType) ?? {};
    const { reasons: cancelReasons } =
      cancelPeriods?.find(({ name }) => name === cancelPeriod) ?? {};
    const { subReasons: subCancelReasons } =
      cancelReasons?.find(({ name }) => name === cancelReason) ?? {};

    return {
      loading,
      cancelTypes:
        cancelTypes?.map(({ name }) => genCancelOption({ t, name })) ??
        DEFAULT_OPTIONS,
      cancelPeriods:
        cancelPeriods?.map(({ name }): OptionModel => {
          switch (name) {
            case CANCEL_PERIODS.BEFORE_HANDOVER:
              return {
                ...genCancelOption({ t, name }),
                disabled: isBeforeHandoverDisabled
              };
            case CANCEL_PERIODS.AFTER_HANDOVER:
              return {
                ...genCancelOption({ t, name }),
                disabled: isAfterHandoverDisabled
              };
            default:
              return genCancelOption({ t, name });
          }
        }) ?? DEFAULT_OPTIONS,
      cancelReasons:
        cancelReasons?.map(({ name }) => genCancelOption({ t, name })) ??
        DEFAULT_OPTIONS,
      subCancelReasons:
        subCancelReasons?.map((name) => genCancelOption({ t, name })) ??
        DEFAULT_OPTIONS
    };
  }, [
    cancelPeriod,
    cancelReason,
    cancelType,
    cancelTypes,
    isAfterHandoverDisabled,
    isBeforeHandoverDisabled,
    loading,
    t
  ]);
}

export const useCancelTypesOptions = (
  task: Pick<CancellationRequestedTaskProjection, 'order'>
) => {
  const order = task?.order;
  const { loading, cancelTypes } = useOrderCancelOptionsByField({
    orderId: order?.id,
    state: order?.state,
    vehicleDeliveredOn: order?.vehicleDeliveredOn
  });

  return [cancelTypes, loading] as const;
};

export const useCancelReasonsOptions = (
  task: Pick<CancellationRequestedTaskProjection, 'order'>
) => {
  const order = task?.order;
  const { loading, cancelReasons } = useOrderCancelOptionsByField({
    orderId: order?.id,
    state: order?.state,
    vehicleDeliveredOn: order?.vehicleDeliveredOn
  });

  return [cancelReasons, loading] as const;
};

export const useSubCancelReasonsOptions = (
  task: Pick<CancellationRequestedTaskProjection, 'order'>
) => {
  const order = task?.order;
  const { loading, subCancelReasons } = useOrderCancelOptionsByField({
    orderId: order?.id,
    state: order?.state,
    vehicleDeliveredOn: order?.vehicleDeliveredOn
  });

  return [subCancelReasons, loading] as const;
};

export const useCancelPeriodsOptions = (
  task: Pick<CancellationRequestedTaskProjection, 'order'>
) => {
  const order = task?.order;
  const { loading, cancelPeriods } = useOrderCancelOptionsByField({
    orderId: order?.id,
    state: order?.state,
    vehicleDeliveredOn: order?.vehicleDeliveredOn
  });

  return [cancelPeriods, loading] as const;
};
