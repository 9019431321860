import { getDefaultLocaleOfCountry } from '@retail/i18n/utils';
import { useMemo } from 'react';

import { useRetailFeaturesStatusV2Query } from '~apollo/gql-types';
import { FEATURE } from '~constants/feature';
import { getEnvironment } from '~utils/env';

/** @description works only for localhost */
const LOCAL_ENV = process.env.ENV.toUpperCase?.();

interface Props {
  names: FEATURE[];
  country: string | null;
  featureUsageDate?: string;
}

export function useFeatures({ names, country, featureUsageDate }: Props) {
  const { data, loading: isLoading } = useRetailFeaturesStatusV2Query({
    fetchPolicy: 'cache-and-network',
    skip: !country,
    variables: {
      names,
      context: {
        environment: getEnvironment() ?? LOCAL_ENV,
        country,
        locale: country ? getDefaultLocaleOfCountry(country) : null,
        featureUsageDate
      }
    }
  });

  const isFeatureEnabled = useMemo(() => {
    const featuresSet = new Map<FEATURE, boolean>();
    data?.list?.forEach(({ name, enabled }) =>
      featuresSet.set(name as FEATURE, enabled)
    );

    return (feature: FEATURE) => featuresSet.get(feature);
  }, [data]);

  return {
    isLoading,
    isFeatureEnabled
  };
}
