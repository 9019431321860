import { Table, TableColumnsType } from 'antd';
import { differenceInDays } from 'date-fns';
import { flow, get, getOr, head, isEmpty } from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import cn from './styles.less';

import {
  HappinessCallTaskManagementProjection,
  LoadTaskOrderDetailsQuery,
  RetailCustomerProjection,
  RetailSurveyScoreProjection,
  VehicleProjection
} from '~apollo/gql-types';
import { formatDateTime } from '~helpers/date';
import { getFullName } from '~utils/user';

interface Entity {
  carHandoverOnDate: string;
  daysSinceHandover: number;
  orderContractSignedOn: string;
  carName: string;
  customerName: string;
  npsScore: unknown;
}

interface Props {
  data: HappinessCallTaskManagementProjection;
  detailsData: LoadTaskOrderDetailsQuery;
}

export function HappinessCallSubRow({ data, detailsData }: Props) {
  const { t } = useTranslation();

  const dataSource = [
    {
      carHandoverOnDate: formatDateTime(data?.carHandoverOnDate),
      daysSinceHandover: differenceInDays(
        new Date(),
        new Date(data?.createdOn)
      ),
      orderContractSignedOn: formatDateTime(data?.orderContractSignedOn),
      carName: flow<
        [LoadTaskOrderDetailsQuery],
        LoadTaskOrderDetailsQuery['order']['adItems'],
        LoadTaskOrderDetailsQuery['order']['adItems'][number],
        VehicleProjection,
        string
      >(
        get(['order', 'adItems']),
        head,
        getOr({}, ['ad', 'vehicle']),
        ({ manufacturer, model }) => {
          const name = [];
          if (manufacturer) {
            name.push(manufacturer);
          }
          if (model) {
            name.push(model);
          }
          return isEmpty(name) ? '-' : name.join(' ');
        }
      )(detailsData),
      customerName: flow<
        [LoadTaskOrderDetailsQuery],
        RetailCustomerProjection,
        string
      >(
        getOr({}, ['order', 'customer']),
        ({ firstName, lastName }) => getFullName({ firstName, lastName }) || '-'
      )(detailsData),
      npsScore: flow<
        [LoadTaskOrderDetailsQuery],
        RetailSurveyScoreProjection[],
        RetailSurveyScoreProjection,
        number
      >(
        get(['surveyScores']),
        head,
        get(['score'])
      )(detailsData)
    }
  ];

  const columns: TableColumnsType<Entity> = [
    {
      title: t('bo.taskManagement.happinessTable.carHandoverOnDate'),
      dataIndex: 'carHandoverOnDate',
      key: 'carHandoverOnDate',
      render: (value) => (
        <span data-qa-selector="carHandoverOnDate">{value || '-'}</span>
      )
    },
    {
      title: t('bo.taskManagement.happinessTable.daysSinceHandover'),
      dataIndex: 'daysSinceHandover',
      key: 'daysSinceHandover',
      render: (value) => (
        <span data-qa-selector="daysSinceHandover">{`${value || 0} days`}</span>
      )
    },
    {
      title: t('bo.taskManagement.happinessTable.orderContractSignedOn'),
      dataIndex: 'orderContractSignedOn',
      key: 'orderContractSignedOn',
      render: (value) => (
        <span data-qa-selector="orderContractSignedOn">{value || '-'}</span>
      )
    },
    {
      title: t('bo.taskManagement.happinessTable.npsScore'),
      dataIndex: 'npsScore',
      key: 'npsScore',
      render: (value) => <span data-qa-selector="npsScore">{value || '-'}</span>
    },
    {
      title: t('bo.taskManagement.happinessTable.customerName'),
      dataIndex: 'customerName',
      key: 'customerName',
      render: (value) => (
        <span data-qa-selector="customerName">{value || '-'}</span>
      )
    },
    {
      title: t('bo.taskManagement.happinessTable.carName'),
      dataIndex: 'carName',
      key: 'carName',
      render: (value) => <span data-qa-selector="carName">{value || '-'}</span>
    }
  ];

  return (
    <Table
      rowKey="carName"
      columns={columns}
      pagination={false}
      dataSource={dataSource}
      bordered
      className={cn.table}
      style={{ width: '100%', border: '1px solid #616161' }}
    />
  );
}
