import { formatCurrency } from '@retail/backoffice-ui/src/utils/formatCurrency';
import { Table, TableColumnsType, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import cn from './styles.less';
import { useGetOrderItemName } from './useGetOrderItemName';

import {
  OrderProjection,
  SearchTaskManagerTasksQuery
} from '~apollo/gql-types';
import { formatDateTime } from '~helpers/date';
import { i18nDetectLng } from '~i18n/utils';
import * as ROUTES from '~routes';
import { getFullName } from '~utils/user';

const { Link } = Typography;

type Entity =
  SearchTaskManagerTasksQuery['searchTaskManagerTasks']['entities'][number];

interface Props {
  order: OrderProjection;
  data: Entity;
}

export function DamageCompensationSubRow({ data, order }: Props) {
  const { t } = useTranslation();
  const getOrderItemName = useGetOrderItemName(order);

  const columns: TableColumnsType<Entity> = [
    {
      title: t('bo.taskManagement.damageCompensationTable.reason'),
      dataIndex: 'reason',
      key: 'reason',
      render: (value) => (
        <span data-qa-selector="reason">
          {t(`bo.taskManagement.damageCompensationTable.reason.${value}`)}
        </span>
      )
    },
    {
      title: t('bo.taskManagement.damageCompensationTable.claimNumber'),
      dataIndex: 'claim',
      key: 'number',
      render: (value, entity) => {
        return (
          <span data-qa-selector="claimNumber">
            <Link
              href={
                'orderId' in entity
                  ? ROUTES.ORDER_CLAIMS_DETAILS.LINK({
                      language: i18nDetectLng(),
                      orderId: entity.orderId,
                      claimId: value?.id
                    })
                  : null
              }
              data-qa-selector="claimNumber"
            >
              {value?.number || '-'}
            </Link>
          </span>
        );
      }
    },
    {
      title: t('bo.taskManagement.damageCompensationTable.claimCostNumber'),
      dataIndex: 'cost',
      key: 'costNumber',
      render: (value) => (
        <span data-qa-selector="claimCostNumber">{value?.number || '-'}</span>
      )
    },
    {
      title: t('bo.taskManagement.damageCompensationTable.claimCostAmount'),
      dataIndex: 'cost',
      key: 'costAmount',
      render: (value) => (
        <span data-qa-selector="costAmount">
          {formatCurrency({
            amountMinorUnits: value?.costMinorUnits,
            currencyCode: value?.costCurrencyCode
          }) || '-'}
        </span>
      )
    },
    {
      title: t('bo.taskManagement.damageCompensationTable.ahApproval'),
      dataIndex: 'cost',
      key: 'ahApproval',
      render: (value) => (
        <span data-qa-selector="ahApproval">
          {getFullName(value?.approvalLevelSetBy)}
          {' -'} {formatDateTime(value?.approvalLevelSetOn)}
        </span>
      )
    },
    {
      title: t('bo.taskManagement.damageCompensationTable.customerDecision'),
      dataIndex: 'cost',
      key: 'customerDecision',
      render: (value) => (
        <span data-qa-selector="customerDecision">
          {t(
            `bo.taskManagement.damageCompensationTable.customerDecision.${value?.customerDecision}`
          )}{' '}
          - {formatDateTime(value?.customerDecisionSetOn)}
        </span>
      )
    },
    {
      title: t('bo.taskManagement.damageCompensationTable.comment'),
      dataIndex: 'damageCompensationDescription',
      key: 'damageCompensationDescription',
      render: (value, entity) => {
        const selectedOrderItem =
          entity.__typename === 'ClaimPartialRefundPendingTaskProjection' &&
          entity?.cost?.orderItemId
            ? `${t(
                'bo.taskManagement.damageCompensationTable.selectedOrderItem',
                'Selected order item:'
              )} ${getOrderItemName(entity.cost.orderItemId) ?? '-'}`
            : '';
        return (
          <span data-qa-selector="comment">
            {value}
            {value && selectedOrderItem ? <br /> : ''}
            {selectedOrderItem}
          </span>
        );
      }
    }
  ];

  return (
    <Table
      columns={columns}
      pagination={false}
      rowKey="damageCompensationPending"
      dataSource={[data]}
      bordered
      className={cn.table}
      style={{ width: '100%', border: '1px solid #616161' }}
    />
  );
}
