import { makeVar, useReactiveVar } from '@apollo/client';
import { unitsFromMajorToMinor } from '@retail/currency';
import { Form, Modal, notification, Spin } from 'antd';
import { memo, useCallback, useEffect } from 'react';
import { FieldValues, FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TASK_NAME, TOPIC } from '../constants';
import { TaskEntityModal } from '../types';

import { TaskForm } from './FormFields';
import { useCompleteTaskDataByType } from './hooks/useCompleteTaskDataByType';
import { useCompleteTaskManagerTaskAsync } from './hooks/useCompleteTaskManagerTaskAsync';
import { useInitialValues } from './hooks/useInitialValues';
import { useResetFieldsRequestCancellationTask } from './hooks/useResetFieldsRequestCancellationTask';

import { RetailOrderBasedTaskAction } from '~apollo/gql-types';
import { useQueryBaseOptions } from '~hooks/useQueryBaseOptions';
import { AvailableActionsModel } from '~types/AvailableActions';

interface TaskVarModel {
  visible: boolean;
  data?: TaskEntityModal;
}

export const manageTaskModel = makeVar<TaskVarModel>({
  visible: false,
  data: null
});
export const hideModal = () => manageTaskModel({ visible: false, data: null });
export const showModal = (data: TaskEntityModal) =>
  manageTaskModel({ visible: true, data });

interface Props {
  taskNameToAvailableActions: Record<TASK_NAME, AvailableActionsModel>;
}

export const TaskModal = memo<Props>(({ taskNameToAvailableActions }) => {
  const { visible, data } = useReactiveVar(manageTaskModel);
  const { t } = useTranslation();
  const queryBaseOptions = useQueryBaseOptions();

  const { id: taskId, taskType, assignee } = data ?? {};

  const taskData = useCompleteTaskDataByType({
    taskType,
    taskNameToAvailableActions
  });

  const { values: initialValues, isLoading: isInitialValuesLoading } =
    useInitialValues(taskData, data);

  const form = useForm<FieldValues>({
    defaultValues: initialValues,
    mode: 'all'
  });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { isDirty }
  } = form;

  useEffect(() => {
    if (!visible) {
      reset({});
    }
  }, [visible, reset]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => reset(initialValues), [initialValues, reset, taskId]);

  const allFormValues = useWatch({ control });

  useResetFieldsRequestCancellationTask(allFormValues, setValue, isDirty);

  const { submitTask, loading } = useCompleteTaskManagerTaskAsync({
    taskName: taskType,
    taskData: data,
    availableActions: taskNameToAvailableActions?.[taskType],
    action: allFormValues.action,
    initialValues,
    mutationOptions: {
      ...queryBaseOptions,
      refetchQueries: ['SearchTaskManagerTasks'],
      onCompleted: () => {
        notification.success({
          message: t('bo.taskManagement.form.notification.success.message'),
          description: t(
            'bo.taskManagement.form.notification.success.description'
          )
        });
        hideModal();
        reset(initialValues);
      }
    }
  });

  const onSubmit = useCallback(
    async ({
      description,
      action = 'SUCCESS',
      repeatAt,
      bpmAmount,
      counterofferMeasures
    }) => {
      // eslint-disable-next-line no-undefined
      const variables: Record<string, unknown> = {
        ...getValues(),
        action,
        repeatAt: undefined,
        assignTo: undefined,
        bpmAmount: undefined,
        counterofferMeasures: undefined
      };

      if (counterofferMeasures) {
        variables.counterofferMeasures = counterofferMeasures.join(', ');
      }

      if (repeatAt) {
        variables.repeatAt = repeatAt;
      }

      if (typeof bpmAmount === 'number') {
        variables.bpmAmount = unitsFromMajorToMinor(bpmAmount)?.toString();
      }

      if (action === RetailOrderBasedTaskAction.Reopen && assignee) {
        variables.assignTo = assignee.id;
      }

      await submitTask({
        variables: {
          taskId,
          description,
          variables: {
            ...variables,
            topic: TOPIC.CATEGORY_DELIVERY_FULFILLMENT
          }
        }
      });
    },
    [assignee, getValues, submitTask, taskId]
  );

  return (
    <Modal
      centered
      style={{ top: 70 }}
      width={600}
      visible={visible}
      title={t(`bo.taskManagement.taskNames.${taskType}`)}
      okText={
        <span data-qa-selector="submitButton">
          {t('bo.taskManagement.taskModal.save.cta')}
        </span>
      }
      cancelText={t('bo.taskManagement.taskModal.discard.cta')}
      onOk={handleSubmit(onSubmit)}
      onCancel={() => {
        reset(initialValues);
        hideModal();
      }}
      okButtonProps={{ loading }}
    >
      <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
        <FormProvider {...form}>
          <Spin spinning={isInitialValuesLoading}>
            <TaskForm modalData={data} taskData={taskData} control={control} />
          </Spin>
        </FormProvider>
      </Form>
    </Modal>
  );
});

TaskModal.displayName = 'TaskModal';
