// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iEXpKChgphUsaalvXbQtlA\\=\\= {\n  width: '100%';\n  border: 1px solid #616161;\n  border-right: none !important;\n}\n.iEXpKChgphUsaalvXbQtlA\\=\\= tr th {\n  text-align: center !important;\n  font-weight: bold !important;\n  border-color: #616161 !important;\n  padding: 6px 6px !important;\n}\n.iEXpKChgphUsaalvXbQtlA\\=\\= tr td {\n  text-align: center !important;\n  background-color: #e2e5e8 !important;\n  border-color: #616161 !important;\n  border-bottom: none !important;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/TaskManagement/Table/SubRows/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,yBAAA;EACA,6BAAA;AACF;AAJA;EAMI,6BAAA;EACA,4BAAA;EACA,gCAAA;EACA,2BAAA;AACJ;AAVA;EAaI,6BAAA;EACA,oCAAA;EACA,gCAAA;EACA,8BAAA;AAAJ","sourcesContent":[".table {\n  width: '100%';\n  border: 1px solid #616161;\n  border-right: none !important;\n\n  tr th {\n    text-align: center !important;\n    font-weight: bold !important;\n    border-color: #616161 !important;\n    padding: 6px 6px !important;\n  }\n\n  tr td {\n    text-align: center !important;\n    background-color: #e2e5e8 !important;\n    border-color: #616161 !important;\n    border-bottom: none !important;\n  }\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "iEXpKChgphUsaalvXbQtlA=="
};
export default ___CSS_LOADER_EXPORT___;
