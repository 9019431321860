import { Table, TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';

import cn from './styles.less';

import { CarReturnHandoverAppointmentTaskProjection } from '~apollo/gql-types';
import { formatDateTime } from '~helpers/date';

interface Entity {
  canceledOn: string;
  handoverDate: string;
  carReturnDate: string;
  phoneNumber: string;
  createdOn: string;
  returnDeliveryType: string;
}

interface Props {
  data: CarReturnHandoverAppointmentTaskProjection;
}

export function CarReturnHandoverAppointmentSubRow({ data }: Props) {
  const {
    order,
    handoverDate,
    carReturnDate,
    phoneNumber,
    createdOn,
    returnDeliveryType
  } = data;
  const { t } = useTranslation();
  const dataSource = [
    {
      canceledOn: order.canceledOn,
      handoverDate,
      carReturnDate,
      phoneNumber,
      createdOn,
      returnDeliveryType
    }
  ];

  const columns: TableColumnsType<Entity> = [
    {
      title: t('bo.taskManagement.cancellationsAndReturnsSubRow.canceledOn'),
      dataIndex: 'canceledOn',
      key: 'canceledOn',
      render: (value) => (
        <span data-qa-selector="canceledOn">
          {value ? formatDateTime(value) : '-'}
        </span>
      )
    },
    {
      title: t('bo.taskManagement.cancellationsAndReturnsSubRow.handoverDate'),
      dataIndex: 'handoverDate',
      key: 'handoverDate',
      render: (value) => (
        <span data-qa-selector="handoverDate">
          {value ? formatDateTime(value) : '-'}
        </span>
      )
    },
    {
      title: t('bo.taskManagement.happinessTable.carReturnDate'),
      dataIndex: 'carReturnDate',
      key: 'carReturnDate',
      render: (value) => (
        <span data-qa-selector="carReturnDate">
          {value ? formatDateTime(value) : '-'}
        </span>
      )
    },
    {
      title: t('bo.taskManagement.cancellationsAndReturnsSubRow.phoneNumber'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (value) => (
        <span data-qa-selector="phoneNumber">{value || '-'}</span>
      )
    },
    {
      title: t(
        'bo.taskManagement.cancellationsAndReturnsSubRow.returnDeliveryType'
      ),
      dataIndex: 'returnDeliveryType',
      key: 'returnDeliveryType',
      render: (value) => (
        <span data-qa-selector="returnDeliveryType">{value || '-'}</span>
      )
    }
  ];

  return (
    <Table
      columns={columns}
      pagination={false}
      rowKey="carReturnHandoverAppointment"
      dataSource={dataSource}
      bordered
      className={cn.table}
    />
  );
}
