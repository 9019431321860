import { formatCurrency } from '@retail/backoffice-ui/src/utils/formatCurrency';
import { Table, TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';

import cn from '../styles.less';

import { formatDateTime } from '~helpers/date';
import { TaskEntityOfType } from '~pages/TaskManagement/types';

const FALLBACK = '-';

interface Props {
  data: TaskEntityOfType<'BankDetailsPendingTaskProjection'>;
}

export function BankDetailsCancellationRefundSubRow({ data }: Props) {
  const { t } = useTranslation();

  const dataSource = [data];

  const columns: TableColumnsType<
    TaskEntityOfType<'BankDetailsPendingTaskProjection'>
  > = [
    {
      title: t('bo.taskManagement.bankDetailsPendingTask.reason'),
      dataIndex: 'reason',
      key: 'reason',
      render: (value) => (
        <span data-qa-selector="reason">{value || FALLBACK}</span>
      )
    },
    {
      title: t('bo.taskManagement.bankDetailsPendingTask.orderCanceledOn'),
      dataIndex: 'orderCanceledOn',
      key: 'orderCanceledOn',
      render: (value) => (
        <span data-qa-selector="orderCanceledOn">
          {value ? formatDateTime(value) : FALLBACK}
        </span>
      )
    },
    {
      title: t('bo.taskManagement.bankDetailsPendingTask.refundAmount'),
      dataIndex: 'refundAmount',
      key: 'refundAmount',
      render: (value) => (
        <span data-qa-selector="refundAmount">
          {value ? formatCurrency(value) : FALLBACK}
        </span>
      )
    }
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      pagination={false}
      dataSource={dataSource}
      bordered
      className={cn.table}
      style={{ width: '100%', border: '1px solid #616161' }}
    />
  );
}
