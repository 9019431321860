import { Table, TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';

import cn from './styles.less';

import { CareCallTaskProjection } from '~apollo/gql-types';
import { formatDateTime } from '~helpers/date';

interface Props {
  data: CareCallTaskProjection;
}

export function CareCallSubRow({ data }: Props) {
  const { t } = useTranslation();
  const dataSource = [data];

  const columns: TableColumnsType<CareCallTaskProjection> = [
    {
      title: t('bo.taskManagement.careCallTable.customerName'),
      dataIndex: 'customerName',
      key: 'customerName',
      render: (value) => (
        <span data-qa-selector="customerName">{value || '-'}</span>
      )
    },
    {
      title: t('bo.taskManagement.careCallTable.phoneNumber'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      render: (value) => (
        <span data-qa-selector="phoneNumber">{value || '-'}</span>
      )
    },
    {
      title: t('bo.taskManagement.careCallTable.carReservedOnDate'),
      dataIndex: 'carReservedOnDate',
      key: 'carReservedOnDate',
      render: (value) => (
        <span data-qa-selector="carReservedOnDate">
          {value ? formatDateTime(value) : '-'}
        </span>
      )
    },
    {
      title: t('bo.taskManagement.careCallTable.paymentType'),
      dataIndex: 'paymentType',
      key: 'paymentType',
      render: (value) => (
        <span data-qa-selector="paymentType">{value || '-'}</span>
      )
    },
    {
      title: t('bo.taskManagement.careCallTable.handoverDate'),
      dataIndex: 'carHandoverOnDate',
      key: 'carHandoverOnDate',
      render: (value) => (
        <span data-qa-selector="carHandoverOnDate">
          {value ? formatDateTime(value) : '-'}
        </span>
      )
    },
    {
      title: t('bo.taskManagement.careCallTable.paymentStatus'),
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      render: (value) => (
        <span data-qa-selector="paymentStatus">{value || '-'}</span>
      )
    }
  ];

  return (
    <Table
      columns={columns}
      pagination={false}
      rowKey="id"
      dataSource={dataSource}
      bordered
      className={cn.table}
      style={{ width: '100%', border: '1px solid #616161' }}
    />
  );
}
