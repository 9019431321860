import { Table, TableColumnsType, TableColumnType } from 'antd';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '@retail/backoffice-ui/src/utils/formatCurrency';

import cn from './styles.less';

import { TaskEntityOfType } from '~pages/TaskManagement/types';

type TaskEntity = TaskEntityOfType<'OverpaymentRefundPendingTaskProjection'>;

export const OverpaymentRefundPendingSubRow = ({
  data
}: {
  data: TaskEntity;
}) => {
  const { t } = useTranslation();

  const columns: TableColumnsType<TaskEntity> = [
    {
      title: t('bo.taskManagement.overpaymentRefundSubRow.paymentType'),
      dataIndex: 'paymentType',
      render: (paymentType) =>
        t(`bo.taskManagement.paymentTypes.${paymentType}`)
    },
    {
      title: t('bo.taskManagement.overpaymentRefundSubRow.paymentStatus'),
      dataIndex: 'paymentStatus',
      render: (paymentStatus) =>
        t(`bo.taskManagement.paymentStatuses.${paymentStatus}`)
    },
    ...(data.refundApprovedAmount
      ? [
          {
            title: t(
              'bo.taskManagement.overpaymentRefundSubRow.refundApprovedAmount'
            ),
            dataIndex: 'refundApprovedAmount',
            render: (refundApprovedAmount, data) =>
              formatCurrency({
                amountMinorUnits: refundApprovedAmount,
                currencyCode: data.order.totalPriceGross.currencyCode
              })
          } as TableColumnType<TaskEntity>
        ]
      : []),
    {
      title: t('bo.taskManagement.overpaymentRefundSubRow.overpaymentAmount'),
      dataIndex: 'overpaymentAmount',
      render: (overpaymentAmount, data) =>
        formatCurrency({
          amountMinorUnits: Math.abs(overpaymentAmount),
          currencyCode: data.order.totalPriceGross.currencyCode
        })
    },
    {
      title: t('bo.taskManagement.overpaymentRefundSubRow.comments'),
      dataIndex: 'comment',
      render: (_, data) => (
        <>
          <p>
            {t(
              `bo.taskManagement.overpaymentRefundApprovalTask.refundTo.${data.refundTo}`
            )}
          </p>
          {data.description && <p>{data.description}</p>}
        </>
      )
    }
  ];

  return (
    <Table
      dataSource={[data]}
      columns={columns}
      bordered
      className={cn.table}
      pagination={false}
      style={{ width: '100%', border: '1px solid #616161' }}
    />
  );
};
