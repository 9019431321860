// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2N4R0PQEtZF\\+z0ytHER9hw\\=\\= {\n  display: flex;\n  flex-wrap: nowrap;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/TaskManagement/Table/AssignedTo/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;AACF","sourcesContent":[".section {\n  display: flex;\n  flex-wrap: nowrap;\n}\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "_2N4R0PQEtZF+z0ytHER9hw=="
};
export default ___CSS_LOADER_EXPORT___;
