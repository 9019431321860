export const CREATE_NEW_ACCOUNT_DETAILS = 'CREATE_NEW_ACCOUNT_DETAILS';

export enum ACCOUNT_DETAILS_UPLOAD_SOURCE {
  BO_TASK_MGMT = 'BO_TASK_MGMT'
}

export enum PaymentTypeOption {
  SePlusgiro = 'SE_PLUSGIRO',
  SeBankTransfer = 'SE_BANK_TRANSFER',
  Iban = 'IBAN'
}
