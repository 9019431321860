import { useCallback } from 'react';

import { useSearchBranchesByIdLazyQuery } from '~apollo/gql-types';
import { DOMAINS } from '~constants/permissions';

export const useGetBranchById = () => {
  const [searchBranchesById] = useSearchBranchesByIdLazyQuery({
    context: { headers: { 'access-domain': DOMAINS.REFURBISHMENT_MANAGEMENT } }
  });

  const getBranchById = useCallback(
    async (branchId: string | number) =>
      (
        await searchBranchesById({
          variables: { id: branchId != null ? String(branchId) : null }
        })
      )?.data?.searchBranches?.entities?.[0],
    [searchBranchesById]
  );

  return getBranchById;
};
