import {
  FilterType,
  Sorts,
  createFilter,
  createFilterPayload
} from '@retail/gql-utils';
import { notification } from 'antd';
import { Dispatch, useEffect } from 'react';

import { TaskEntity } from '../types';
import { Comments } from '../Comments';
import { TASK_NAME } from '../constants';
import { updateTotalCountByTaskId } from '../hooks/useCommentsTotalCount';

import { SubRow } from './SubRows';

import {
  CamundaSearchProjection2Input,
  Exact,
  useLoadTaskOrderDetailsQuery,
  useSearchTaskManagerTaskCommentsQuery
} from '~apollo/gql-types';
import { DOMAINS } from '~constants/permissions';

const tasksWithOrderDetailsInfo = [
  TASK_NAME.HAPPINESS_CALL_TASK,
  TASK_NAME.REPEAT_HAPPINESS_CALL_TASK,
  TASK_NAME.CLAIM_PARTIAL_REFUND_PENDING_TASK
];

interface Props {
  data: TaskEntity;
  isCommentAllowedByTaskTypeMap: Record<string, boolean>;
  taskManagerTasksLoading: boolean;
  taskManagerTasksRefetch: Dispatch<
    Partial<
      Exact<{
        search: CamundaSearchProjection2Input;
      }>
    >
  >;
}

export const ExpandedRow = ({
  data,
  isCommentAllowedByTaskTypeMap,
  taskManagerTasksLoading,
  taskManagerTasksRefetch
}: Props) => {
  const filterByTaskId = createFilter('taskId', FilterType.EQUAL, data.id);
  const { data: detailsData } = useLoadTaskOrderDetailsQuery({
    skip: !tasksWithOrderDetailsInfo.includes(data?.taskType),
    context: { headers: { 'access-domain': DOMAINS.ORDER_MANAGEMENT } },
    variables: {
      orderId: data?.orderId,
      surveyTypes: ['ORDER_DELIVERED_NPS']
    },
    errorPolicy: 'all',
    onError: (error) => {
      notification.error({
        message: 'Error',
        description: error?.graphQLErrors?.[0]?.message
      });
    }
  });

  const {
    data: commentHistoryData,
    loading: commentHistoryLoading,
    refetch: refetchComments
  } = useSearchTaskManagerTaskCommentsQuery({
    context: { headers: { 'access-domain': DOMAINS.TASK_MANAGEMENT } },
    variables: {
      commentsSearch: createFilterPayload({
        filter: filterByTaskId,
        sorts: [Sorts.desk('createdOn')]
      })
    }
  });

  const { searchTaskManagerTaskComments } = commentHistoryData ?? {};

  useEffect(() => {
    if (searchTaskManagerTaskComments?.totalEntityCount) {
      updateTotalCountByTaskId(
        data.id,
        searchTaskManagerTaskComments.totalEntityCount
      );
    }
  }, [data.id, searchTaskManagerTaskComments]);

  return (
    <>
      <SubRow
        data={data}
        qaSelector={`subRow-${data?.id}`}
        detailsData={detailsData}
      />
      <Comments
        data={data}
        comments={searchTaskManagerTaskComments}
        commentHistoryLoading={commentHistoryLoading}
        isCommentAllowedByTaskTypeMap={isCommentAllowedByTaskTypeMap}
        refetchComments={refetchComments}
        taskManagerTasksLoading={taskManagerTasksLoading}
        taskManagerTasksRefetch={taskManagerTasksRefetch}
      />
    </>
  );
};
