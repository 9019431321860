import { TASK_NAME } from './constants';

import { SearchTaskManagerTasksQuery } from '~apollo/gql-types';

type TasksUnionWithUndeclaredProjections = Required<
  NonNullable<
    NonNullable<
      SearchTaskManagerTasksQuery['searchTaskManagerTasks']
    >['entities'][number]
  >
>;

// When BE returns task for which we don't have inline fragment
// in query, then this task will be just empty object. We need to
// exclude these empty objects from types and result.
type ExcludeUndeclaredProjections<Type> = Type extends { id?: string }
  ? Type
  : never;

export type TaskEntity =
  ExcludeUndeclaredProjections<TasksUnionWithUndeclaredProjections> &
    Record<'taskType', TASK_NAME>;

export type TaskEntityModal = TaskEntity & {
  action?: string;
};

export type TypeByTypeName<
  Union,
  TypeName extends TaskEntity['__typename']
> = Union extends {
  __typename: TypeName;
}
  ? Union
  : never;

export type TaskEntityOfType<TypeName extends TaskEntity['__typename']> =
  TypeByTypeName<TaskEntity, TypeName>;

const TASK_NAME_TO_PROJECTION_MAPPING = {
  [TASK_NAME.BANK_DETAILS_PENDING_TASK]: 'BankDetailsPendingTaskProjection',
  [TASK_NAME.CANCELLATION_REQUESTED]: 'CancellationRequestedTaskProjection',
  [TASK_NAME.CARE_CALL_TASK]: 'CareCallTaskProjection',
  [TASK_NAME.CAR_RETURN_HANDOVER_APPOINTMENT_TASK]:
    'CarReturnHandoverAppointmentTaskProjection',
  [TASK_NAME.CAR_RETURNED_TASK]: 'CarReturnedTaskProjection',
  [TASK_NAME.DOCUMENTS_RETURNED_DEREGISTRATION_TASK]:
    'DocumentsReturnedDeregistrationTaskProjection',
  [TASK_NAME.FINANCING_APPLICATION_PENDING_BANK_DECISION_TASK]:
    'FinancingApplicationPendingBankDecisionTaskProjection',
  [TASK_NAME.FINANCING_APPLICATION_VALIDATION_TASK]:
    'FinancingApplicationValidationTaskProjection',
  [TASK_NAME.FINANCING_BILLING_ADDRESS_VERIFICATION_TASK]:
    'FinancingBillingAddressVerificationTaskProjection',
  [TASK_NAME.FINANCING_INTERNAL_APPROVAL_TASK]:
    'FinancingInternalApprovalTaskProjection',
  [TASK_NAME.FINANCING_MONTHLY_PAYMENT_DAY_UPDATE_TASK]:
    'FinancingMonthlyPaymentDayUpdateTaskProjection',
  [TASK_NAME.FINANCING_SEPA_MANDATE_VERIFICATION_TASK]:
    'FinancingSepaMandateVerificationTaskProjection',
  [TASK_NAME.ORDER_READY_FOR_VERIFICATION_TASK]:
    'OrderReadyForVerificationTaskProjection',
  [TASK_NAME.OVERPAYMENT_REFUND_APPROVAL]:
    'OverpaymentRefundApprovalTaskProjection',
  [TASK_NAME.OVERPAYMENT_REFUND_PENDING]:
    'OverpaymentRefundPendingTaskProjection',
  [TASK_NAME.SEPA_MANDATE_UPDATE_INFORM_HMCS_TASK]:
    'SepaMandateUpdateInformHMCSTaskProjection',
  [TASK_NAME.VERIFICATION_CALL_TASK]: 'VerificationCallTaskProjection'
} as const;

export function isTaskOfType<
  T extends keyof typeof TASK_NAME_TO_PROJECTION_MAPPING
>(
  data: TaskEntity,
  taskName: T
): data is TaskEntityOfType<typeof TASK_NAME_TO_PROJECTION_MAPPING[T]> {
  return data.taskType === taskName;
}
