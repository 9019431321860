import { isFinancingRejectionReasonEnabled } from '@retail/backoffice-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectWithRejectFinancingStatusesMessage } from '../../Inputs/SelectWithRejectFinancingStatusesMessage';
import { CompleteTaskDataByType } from '../../types';
import {
  useCancelPeriodsOptions,
  useCancelReasonsOptions,
  useCancelTypesOptions,
  useSubCancelReasonsOptions
} from '../useOrderCancelTypesOptions';

import { getDescriptionFieldConfig } from './utils';

import { CancellationRequestedTaskProjection } from '~apollo/gql-types';
import { TaskEntityOfType } from '~pages/TaskManagement/types';
import { ReturnDeliveryType } from '~pages/TaskManagement/constants';
import { AvailableActionsModel } from '~types/AvailableActions';

const MAX_LENGTH = 1000;
export const COUNTEROFFER_MEASURE_KEY_OTHER =
  'bo.taskManagement.form.counterofferMeasures.other';
export const COUNTEROFFER_MEASURES_OPTIONS = [
  'bo.taskManagement.form.counterofferMeasures.reasoning',
  'bo.taskManagement.form.counterofferMeasures.carRepair',
  'bo.taskManagement.form.counterofferMeasures.trialPeriodExtension',
  'bo.taskManagement.form.counterofferMeasures.premiumWarranty12',
  'bo.taskManagement.form.counterofferMeasures.premiumWarranty24',
  'bo.taskManagement.form.counterofferMeasures.premiumWarranty36',
  'bo.taskManagement.form.counterofferMeasures.cashCompensation',
  COUNTEROFFER_MEASURE_KEY_OTHER
];
const MIN_LENGTH = 10;

export const useCancellationRequestedTaskConfig = (
  availableActions: AvailableActionsModel
) => {
  const { t } = useTranslation();

  return useMemo<CompleteTaskDataByType>(
    () => ({
      action: {
        type: 'Select',
        required: true,
        getInitialValue: (modalData) => modalData?.action,
        options: availableActions?.options ?? [],
        getAdditionalFields(action) {
          if (action !== 'CANCELLATION_REQUEST_REVOKED') {
            return {
              returnDeliveryType: {
                type: 'Select',
                getIsVisible: (
                  data: TaskEntityOfType<'CancellationRequestedTaskProjection'>
                ) => data.action === '',
                options: [
                  {
                    label: t(
                      'bo.taskManagement.completeTaskForm.cancellation.customerDropOf'
                    ),
                    value: ReturnDeliveryType.CUSTOMER_DROP_OF
                  },
                  {
                    label: t(
                      'bo.taskManagement.completeTaskForm.cancellation.ahPickUp'
                    ),
                    value: ReturnDeliveryType.AH_PICK_UP
                  }
                ]
              }
            };
          }
          return {};
        }
      },
      counterofferMeasures: {
        type: 'Select',
        mode: 'multiple',
        options: COUNTEROFFER_MEASURES_OPTIONS.map((measure) => ({
          value: t(measure),
          label: t(measure)
        })),
        getAdditionalFields(selectedMeasures: string[]) {
          if (selectedMeasures?.includes(t(COUNTEROFFER_MEASURE_KEY_OTHER))) {
            return {
              counterofferComment: {
                type: 'Textarea',
                minLength: MIN_LENGTH,
                required: true
              }
            };
          }

          return {};
        }
      },
      cancelType: {
        getInitialValue: (
          data: TaskEntityOfType<'CancellationRequestedTaskProjection'>
        ) => data.cancelType,
        type: 'Custom',
        required: true,
        useOptions: useCancelTypesOptions,
        component: SelectWithRejectFinancingStatusesMessage
      },
      cancelPeriod: {
        getInitialValue: (
          data: TaskEntityOfType<'CancellationRequestedTaskProjection'>
        ) => data.cancelPeriod,
        type: 'Select',
        required: true,
        useOptions: useCancelPeriodsOptions
      },
      cancelReason: {
        getInitialValue: (
          data: TaskEntityOfType<'CancellationRequestedTaskProjection'>
        ) => data.cancelReason,
        type: 'Select',
        required: true,
        useOptions: useCancelReasonsOptions
      },
      subCancelReason: {
        getInitialValue: (
          data: TaskEntityOfType<'CancellationRequestedTaskProjection'>
        ) => data.subCancelReason,
        type: 'Select',
        required: true,
        useOptions: useSubCancelReasonsOptions,
        getAdditionalFields(
          subCancelReason,
          {
            orderFinanceRejectReasons,
            financingStatus
          }: Pick<
            CancellationRequestedTaskProjection,
            'orderFinanceRejectReasons' | 'financingStatus'
          >
        ) {
          if (
            isFinancingRejectionReasonEnabled({
              financingStatus,
              canceledSubReason: subCancelReason as string
            })
          ) {
            return {
              financingRejectReason: {
                required: true,
                type: 'Select',
                options: orderFinanceRejectReasons.map(
                  ({ key, description }) => ({
                    label: description,
                    value: key
                  })
                )
              }
            };
          }
        }
      },
      ...getDescriptionFieldConfig({
        maxLength: MAX_LENGTH
      })
    }),
    [availableActions?.options, t]
  );
};
