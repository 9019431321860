import { Button, Table } from 'antd';
import { Dispatch, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TaskEntity } from '../types';

import { CommentForm } from './CommentForm';
import { useCommentsTableColumns } from './hooks/useCommentsTableColumns';
import cn from './styles.less';

import {
  CamundaSearchProjection2Input,
  Exact,
  SearchProjection2Input,
  SearchTaskManagerTaskCommentsQuery
} from '~apollo/gql-types';

interface Props {
  data: TaskEntity;
  comments: SearchTaskManagerTaskCommentsQuery['searchTaskManagerTaskComments'];
  commentHistoryLoading: boolean;
  isCommentAllowedByTaskTypeMap: Record<string, boolean>;
  refetchComments: Dispatch<Partial<
    Exact<{
      commentsSearch: SearchProjection2Input;
    }>
  > | void>;
  taskManagerTasksLoading: boolean;
  taskManagerTasksRefetch: Dispatch<
    Partial<
      Exact<{
        search: CamundaSearchProjection2Input;
      }>
    >
  >;
}

export const Comments = ({
  data,
  comments,
  commentHistoryLoading,
  isCommentAllowedByTaskTypeMap,
  refetchComments,
  taskManagerTasksLoading,
  taskManagerTasksRefetch
}: Props) => {
  const { t } = useTranslation();
  const hasNoComments = data?.commentsCount === 0;
  const [isCommentFormOpen, setCommentFormOpen] = useState(hasNoComments);
  const openCommentForm = () => setCommentFormOpen(true);
  const closeCommentForm = () => setCommentFormOpen(false);

  const columns = useCommentsTableColumns();

  useEffect(() => {
    const isCountersLoaded =
      data &&
      comments &&
      !isNaN(data.commentsCount) &&
      !isNaN(comments.totalEntityCount);
    if (
      isCountersLoaded &&
      data?.commentsCount > comments?.totalEntityCount &&
      !commentHistoryLoading &&
      !taskManagerTasksLoading
    ) {
      refetchComments();
    }
  }, [
    data,
    comments,
    refetchComments,
    commentHistoryLoading,
    taskManagerTasksLoading
  ]);

  const canLeaveCommentForTask =
    isCommentAllowedByTaskTypeMap?.[data.taskType] &&
    data.taskStatus !== 'COMPLETE';

  return (
    <div className={cn.comments}>
      <div
        data-qa-selector={`header-comments-${data.id}`}
        className={cn.header}
      >
        <p
          data-qa-selector="commentsQuantity"
          className={cn.commentsCount}
        >{`${t('bo.taskManagement.comments.labelQuantity')} (${
          comments?.totalEntityCount || data?.commentsCount
        })`}</p>
        {canLeaveCommentForTask && !isCommentFormOpen && (
          <Button
            type="primary"
            data-qa-selector="createComment"
            onClick={openCommentForm}
          >
            {t('bo.taskManagement.comments.addComment')}
          </Button>
        )}
      </div>

      {isCommentFormOpen && canLeaveCommentForTask && (
        <CommentForm
          data={data}
          taskManagerTasksRefetch={taskManagerTasksRefetch}
          closeCommentForm={closeCommentForm}
        />
      )}

      <Table<
        SearchTaskManagerTaskCommentsQuery['searchTaskManagerTaskComments']['entities'][number]
      >
        bordered
        rowKey="id"
        columns={columns}
        pagination={false}
        loading={commentHistoryLoading}
        rowClassName="task-comments-row"
        data-qa-selector="task-comments-table"
        dataSource={comments?.entities}
      />
    </div>
  );
};
