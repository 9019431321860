import { TableCell } from '@retail/backoffice-ui';
import { useTranslation } from 'react-i18next';

import { TASK_NAME } from '../constants';
import { isTaskOfType, TaskEntity } from '../types';

interface TaskTypeProps {
  task: TaskEntity;
}

export const TaskType = ({ task }: TaskTypeProps) => {
  const { t } = useTranslation();

  const { taskType } = task;

  const taskTypeTitle = t(`bo.taskManagement.taskNames.${taskType}`);

  const getRefundTitle = (refundTo: string) =>
    t(`bo.taskManagement.overpaymentRefundApprovalTask.refundTo.${refundTo}`);

  return (
    <TableCell data-qa-selector="taskType">
      {isTaskOfType(task, TASK_NAME.OVERPAYMENT_REFUND_PENDING) && task.refundTo
        ? `${taskTypeTitle} (${getRefundTitle(task.refundTo)})`
        : taskTypeTitle}
    </TableCell>
  );
};
