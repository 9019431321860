import { MutationHookOptions } from '@apollo/client';

import {
  ACCOUNT_DETAILS_UPLOAD_SOURCE,
  CREATE_NEW_ACCOUNT_DETAILS,
  PaymentTypeOption,
  ExternalFinancingBankDetailsPendingTaskValues as FormValues,
  useGetAccountDetails
} from './useCompleteTaskDataByType/useExternalFinancingBankDetailsPendingTaskConfig';

import { useCompleteExternalFinancingBankDetailsTaskMutation } from '~apollo/gql-types';
import { TaskEntityOfType } from '~pages/TaskManagement/types';

type ExternalFinancingBankDetailsPendingTask =
  TaskEntityOfType<'RetailExternalFinancingBankDetailsPendingTaskProjection'>;

type BankDetails = ReturnType<
  typeof useGetAccountDetails
>['accountDetails'][number];

const pickSeBankTransferFields = ({
  clearingNumber,
  accountNumber,
  bankName,
  holder
}: FormValues) => ({ clearingNumber, accountNumber, bankName, holder });

const pickSePlusGiroFields = ({ number, holder }: FormValues) => ({
  number,
  holder
});

const pickInternationalTransferFields = ({
  iban,
  bic,
  holder
}: FormValues) => ({ iban, bic, holder });

const mapValues = ({
  initialValues,
  values,
  task,
  bankDetails
}: {
  initialValues: unknown;
  values: FormValues;
  task: ExternalFinancingBankDetailsPendingTask;
  bankDetails: BankDetails[];
}) => {
  const initialRef = (initialValues as { externalFinancingRefNumber: string })
    ?.externalFinancingRefNumber;

  const commonPayload = {
    orderId: task.orderId,
    action: values.action,
    financingId: task?.financingApplication?.id
  };

  if (values.externalFinancingRefNumber !== initialRef) {
    Object.assign(commonPayload, {
      externalFinancingRefNumber: values.externalFinancingRefNumber
    });
  }

  const accountType =
    task.order.retailCountry === 'SE'
      ? values.paymentType
      : PaymentTypeOption.Iban;

  const valuesMapper =
    {
      [PaymentTypeOption.SeBankTransfer]: pickSeBankTransferFields,
      [PaymentTypeOption.SePlusgiro]: pickSePlusGiroFields,
      [PaymentTypeOption.Iban]: pickInternationalTransferFields
    }[accountType] ?? pickInternationalTransferFields;

  if (values.bankDetails !== CREATE_NEW_ACCOUNT_DETAILS) {
    const selectedAccountDetails = bankDetails.find(
      (it) => it.retailAccount.accountDataId === values.bankDetails
    );

    return {
      ...commonPayload,
      ...(selectedAccountDetails?.uploadSource ===
      ACCOUNT_DETAILS_UPLOAD_SOURCE.BO_TASK_MGMT
        ? {}
        : {
            accountDetailsPayload: {
              type: accountType,
              ...selectedAccountDetails?.retailAccount
            }
          })
    };
  }

  return {
    ...commonPayload,
    accountDetailsPayload: {
      type: accountType,
      ...valuesMapper(values)
    }
  };
};

interface Params {
  mutationOptions: Pick<
    MutationHookOptions,
    'refetchQueries' | 'onError' | 'onCompleted'
  >;
  taskData: ExternalFinancingBankDetailsPendingTask | null;
  initialValues: unknown;
}

export const useCompleteExternalFinancingBankDetailsTask = ({
  mutationOptions,
  taskData,
  initialValues
}: Params) => {
  const [mutate, { loading }] =
    useCompleteExternalFinancingBankDetailsTaskMutation({
      ...mutationOptions
    });

  const { accountDetails } = useGetAccountDetails(
    taskData?.orderId && taskData?.order?.retailCountry
      ? {
          orderId: taskData.orderId,
          country: taskData.order.retailCountry
        }
      : null
  );

  const submit = ({
    variables: { taskId, description, variables }
  }: {
    variables: {
      taskId: string;
      description: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      variables: any;
    };
  }) =>
    mutate({
      variables: {
        taskId,
        description,
        variables: mapValues({
          initialValues,
          values: variables,
          task: taskData,
          bankDetails: accountDetails
        })
      }
    });

  return [submit, loading] as const;
};
