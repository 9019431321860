import { TableCell } from '@retail/backoffice-ui';
import { FunctionComponent } from 'react';

import { UserProjection } from '~apollo/gql-types';
import { TASK_STATUS } from '~pages/TaskManagement/constants';
import { getFullName } from '~utils/user';

export interface WithTaskStatusCompleteProps {
  taskStatus: string;
  assignee: UserProjection;
  qaSelector: string;
}

export function withTaskStatusComplete<Props>(
  As: FunctionComponent<Props & WithTaskStatusCompleteProps>
) {
  return (props: Props & WithTaskStatusCompleteProps) => {
    const { taskStatus, assignee, qaSelector } = props;

    return taskStatus === TASK_STATUS.COMPLETE ? (
      <TableCell data-qa-selector={qaSelector}>
        {getFullName(assignee)}
      </TableCell>
    ) : (
      <As {...props} />
    );
  };
}
