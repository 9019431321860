import { get } from 'lodash/fp';
import UAParser from 'ua-parser-js';

const parser = new UAParser();

const test = (name: string) => get(['name'], parser.getBrowser()) === name;

export const isChrome = test('Chrome');
export const isSafari = test('Safari');
export const isMobileSafari = test('Mobile Safari');
