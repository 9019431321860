import { OptionModel } from '@retail/backoffice-ui';
import {
  Radio,
  RadioGroupProps as AntdRadioGroupProps,
  Form,
  Typography,
  Space
} from 'antd';
import React, { ReactNode } from 'react';
import {
  FieldValues,
  useController,
  UseControllerProps
} from 'react-hook-form';

const { Text } = Typography;

interface RadioGroupProps extends AntdRadioGroupProps {
  controllerProps: UseControllerProps<FieldValues>;
  labelCol?: { span: number };
  label?: ReactNode;
  required?: boolean;
  options: Array<OptionModel>;
}

export const RadioGroup = ({
  label,
  options,
  labelCol,
  controllerProps,
  required
}: RadioGroupProps) => {
  const { field, fieldState } = useController(controllerProps);
  const error = fieldState.error?.message;
  return (
    <Form.Item
      label={label ? <Text>{`${label} ${required ? '*' : ''}`}</Text> : null}
      labelCol={labelCol}
      help={error || null}
      validateStatus={error ? 'error' : null}
    >
      <Radio.Group
        {...field}
        onChange={field.onChange}
        style={{ display: 'grid' }}
      >
        <Space direction="vertical">
          {options.map((it) => (
            <Radio key={it.value} value={it.value}>
              {it.label}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </Form.Item>
  );
};
